import { FC } from "react";

import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import InputPassword, {
  SPECIAL_CHAR_REGEXP,
} from "common/UIKit/InputPassword/InputPassword";
import { useChangePassword } from "./useChangePassword";
import styles from "./ChangePasswordForm.module.scss";

export const changePasswordPath = "/change-password";

interface Props {
  onClose?: () => void;
}

export const ChangePasswordForm: FC<Props> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    errors,
    isSubmitting,
    dirtyFields,
    getValues,
  } = useChangePassword(onClose);

  return (
    <form onSubmit={handleSubmit} className={styles.changePasswordForm}>
      <Input
        type="password"
        label="Old password"
        placeholder=""
        className={styles.input}
        {...register("oldPassword", {
          required: "This field is required.",
        })}
        error={errors.oldPassword?.message}
      />
      <InputPassword
        label="Create new password"
        placeholder="Password"
        className={styles.input}
        validateStrength={dirtyFields.newPassword}
        {...register("newPassword", {
          required: "This field is required.",
          minLength: { value: 8, message: "Min length is 8" },
          validate: {
            hasAlpha: (value) =>
              !!value.match(/\w/g) || "Need at least one letter",
            hasNumeric: (value) =>
              !!value.match(/\d/g) || "Need at least one number",
            hasSymbols: (value) =>
              !!value.match(SPECIAL_CHAR_REGEXP) || "Need special sign",
          },
        })}
        error={dirtyFields.newPassword ? "" : errors.newPassword?.message}
      />
      <InputPassword
        label="Confirm new password"
        placeholder="Password"
        className={styles.input}
        {...register("repeatNewPassword", {
          deps: "password",
          required: "This field is required.",
          validate: {
            sameAsPassword: (value) =>
              value === getValues("newPassword") || "Passwords do not match",
          },
        })}
        error={errors.repeatNewPassword?.message}
      />
      <Button type="submit" className="w-100" text="Save" isLoading={isSubmitting} size="lg" />
    </form>
  );
};

import {
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  FC,
  useCallback,
} from "react";
import cn from "classnames";

import styles from "./Modal.module.scss";

export interface Props {
  visible: boolean;
  className?: string;
  transition?: boolean;
  duration?: number;
  backdrop?: boolean | "static";
  onShow?: () => void;
  onClose?: () => void;
  closeOnClickOutside?: boolean;
  width?: number;
}

export const Modal: FC<Props> = ({
  children,
  visible,
  className,
  onClose,
  closeOnClickOutside = true,
  width = 860
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [_visible, setVisible] = useState(visible);

  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  const handleClickOutside = useCallback(
    (event: Event) => {
      if (event.target === modalRef.current && closeOnClickOutside) {
        onClose && onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    const currentRef = modalRef.current;
    currentRef && currentRef.addEventListener("click", handleClickOutside);

    return () => {
      currentRef && currentRef.removeEventListener("click", handleClickOutside);
    };
  }, [_visible, handleClickOutside]);

  useLayoutEffect(() => {
    const body = document.body;
    const scrollWidth = window.innerWidth - body.clientWidth;

    if (_visible) {
      body.style.overflow = "hidden";
      body.style.paddingRight = `${scrollWidth}px`;
      setTimeout(() => {
        modalRef.current?.focus();
      });
    } else {
      body.style.overflow = "auto";
      body.style.paddingRight = "0px";
    }
    return () => {
      body.style.overflow = "0px";
    };
  }, [_visible]);

  return _visible ? (
    <div
      className={cn(styles.modal, className)}
      tabIndex={-1}
      role="dialog"
      ref={modalRef}
    >
      <div className={styles.modal__dialog} style={{ maxWidth: `${width}px`}}>
        <div className={styles.modal__content}>
          {children}
        </div>
      </div>
    </div>
  ) : null;
};
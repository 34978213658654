import { useEffect } from "react";
import { AxiosResponse } from "axios";
import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";

import { Layout } from "common/components/Layout/Layout";
import { TherapistCard } from "common/components/TherapistCard";
import { Typography } from "common/components/Typography/Typography";
import Button from "common/UIKit/Button/Button";
import { MessagePopup } from "common/components/MessagePopup";
import { recommendationsService } from "services/recommendataionsService";
import { therapistsListingPath } from "../TherapistsListing";
import { AccessController } from "common/components/Layout/AccessController";
import { Spinner } from "common/components/Spinner";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./PatientsHome.module.scss";

export function PatientsHome() {
  const navigate = useNavigate();
  const { data } = useSnapshot(recommendationsService);
  useDocumentTitle("TheraPass - Recommended");

  useEffect(() => {
    recommendationsService.list().catch((error: AxiosResponse) => {
      if (error.status === 401) {
        navigate("/");
      } else {
        throw error;
      }
    });
  }, []);

  const viewMoreTherapists = () => navigate(therapistsListingPath);

  return (
    <AccessController patient="allowed">
      <Layout>
        <div className={styles.root}>
          {data.length === 0 ? (
            <div className={styles.loadingMessage}>
              <Spinner className={styles.spinner} />{" "}
              <p>Searching for the best therapist profiles...</p>
            </div>
          ) : (
            <>
              <MessagePopup />
              <Typography
                variant="h2"
                textAlign="center"
                className={styles.title}
              >
                {`We recommend ${data.length} therapist profiles`}
              </Typography>

              <div className={styles.therapists}>
                {data.map((therapist) => (
                  <TherapistCard
                    className={styles.card}
                    key={therapist.id}
                    therapist={therapist}
                  />
                ))}
              </div>

              <div className={styles.load_more_row}>
                <Button onClick={viewMoreTherapists} size="lg">
                  View more therapists
                </Button>
              </div>
            </>
          )}
        </div>
      </Layout>
    </AccessController>
  );
}

export const patientsHomePath = "/";

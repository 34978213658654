import { AuthLayout } from "pages/Auth/components/AuthLayout";
import { ReactComponent as CheckIcon } from "assets/images/check.svg";
import { CongratulationsListItem } from "./components";
import Button from "common/UIKit/Button/Button";
import { therapistsPath } from "../therapistsPath";
import { useSnapshot } from "valtio";
import { authService } from "services/authService";
import { filesService } from "services/filesService";
import { credentialService } from "services/credentialService";
import { TherapistFields } from "../TherapistSteps/util";
import {
  step1FullPath,
  coveredFields as step1Covered,
  step1Caption,
} from "../TherapistSteps/TherapistStep1";
import {
  step2FullPath,
  coveredFields as step2Covered,
  step2Caption,
} from "../TherapistSteps/TherapistStep2";
import {
  step3_1FullPath,
  coveredFields as step3_1Covered,
} from "../TherapistSteps/TherapistStep3_1";
import { coveredFields as step3_2Covered } from "../TherapistSteps/TherapistStep3_2";
import {
  step4_1FullPath,
  coveredFields as step4_1Covered,
} from "../TherapistSteps/TherapistStep4_1";
import { coveredFields as step4_2Covered } from "../TherapistSteps/TherapistStep4_2";
import {
  step5FullPath,
  coveredFields as step5Covered,
  step5Caption,
} from "../TherapistSteps/TherapistStep5";
import {
  step6FullPath,
  coveredFields as step6Covered,
  step6Caption,
} from "../TherapistSteps/TherapistStep6";
import {
  step7FullPath,
  coveredFields as step7Covered,
  step7Caption,
} from "../TherapistSteps/TherapistStep7";
import {
  step8FullPath,
  coveredFields as step8Covered,
  step8Caption,
} from "../TherapistSteps/TherapistStep8";
import { step9_1FullPath } from "../TherapistSteps/TherapistStep9_1";
import { step10FullPath } from "../TherapistSteps/TherapistStep10";
import { coveredFields as step11Covered } from "../TherapistSteps/TherapistStep11";
import { get } from "lodash";
import { useEffect } from "react";
import { Preloader } from "common/UIKit/Preloader";
import { stepsPath } from "../TherapistSteps";
import { FileType, Therapist } from "types/therapist.types";
import { AccessController } from "common/components/Layout/AccessController";
import styles from "./Congratulations.module.scss";

export const congratulationsPath = `${stepsPath}/account_created`;
export const congratulationsFullPath = `${therapistsPath}${congratulationsPath}`;

type Section = {
  link: string;
  coveredFields?: TherapistFields;
  coveredFiles?: FileType;
  externalData?: object[];
  caption: string;
};

function Congratulations() {
  const therapist = useSnapshot(authService).user as Therapist;
  const { data: files, isProcessing: filesIsFetched } =
    useSnapshot(filesService);
  const { data: credentials, isProcessing: credentialsIsFetched } =
    useSnapshot(credentialService);

  useEffect(() => {
    if (therapist?.id) {
      filesService.list(therapist.id, "credential");
      filesService.list(therapist.id, "photo_and_video_intro");
      credentialService.list(therapist.id);
    }
  }, [therapist?.id]);

  const sections: Section[] = [
    { link: step1FullPath, coveredFields: step1Covered, caption: step1Caption },
    { link: step2FullPath, coveredFields: step2Covered, caption: step2Caption },
    {
      link: step3_1FullPath,
      coveredFields: [...step3_1Covered, ...step3_2Covered],
      coveredFiles: "credential",
      externalData: credentials as object[],
      caption: "Credentials",
    },
    {
      link: step4_1FullPath,
      coveredFields: [...step4_1Covered, ...step4_2Covered],
      caption: "Specialties & Treatment Preferences",
    },
    {
      link: step5FullPath,
      coveredFields: step5Covered,
      caption: step5Caption,
    },
    {
      link: step6FullPath,
      coveredFields: step6Covered,
      caption: step6Caption,
    },
    {
      link: step7FullPath,
      coveredFields: step7Covered,
      caption: step7Caption,
    },
    {
      link: step8FullPath,
      coveredFields: step8Covered,
      caption: step8Caption,
    },
    {
      link: step9_1FullPath,
      coveredFiles: "photo_and_video_intro",
      caption: "Photo & Video Intro",
    },
    //{
    //  link: step10FullPath,
    //  externalData: groups as object[],
    //  caption: "Groups",
    //},
    {
      link: step10FullPath,
      coveredFields: step11Covered,
      caption: "Availability",
    },
    //{
    //  link: step11FullPath,
    //  externalData: areas as object[],
    //  caption: "Target your listing",
    //},
  ];

  if (filesIsFetched || credentialsIsFetched) return <Preloader />;

  return (
    <AccessController therapist="registration">
      <AuthLayout
        isTherapist
        withoutCircle
        className={styles.root}
        signRightTitle="Expand your professional footprint"
        withoutLogo
      >
        <div className={styles.circle}>
          <CheckIcon className={styles.circleIcon} />
        </div>
        <h2 className={styles.title}>Success!</h2>
        <sub className={styles.subtitle}>Your account has been created</sub>
        <p className={styles.descr}>Click button to start or choose category</p>
        <div className={styles.wrapper}>
          <ul className={styles.list}>
            <div className={styles.listInner}>
              {sections.map(
                (
                  { caption, coveredFields, coveredFiles, link, externalData },
                  id
                ) => {
                  let max = 0;
                  let covered = 0;
                  if (coveredFields) {
                    max += coveredFields.length;
                    covered += coveredFields.filter((f) => {
                      const v = get(therapist, f);
                      return typeof v === "boolean" || !!v;
                    }).length;
                  }
                  if (coveredFiles) {
                    max += 1;
                    covered += +files.some((f) => f.type === coveredFiles);
                  }
                  if (externalData) {
                    max += 1;
                    covered += +!!externalData.length;
                  }

                  return (
                    <CongratulationsListItem
                      key={id}
                      title={caption}
                      time={`${max} minutes`}
                      value={(covered / max) * 100}
                      to={link}
                    />
                  );
                }
              )}
            </div>
          </ul>
          <div className={styles.info}>
            <Button isLink className={styles.button} to={step1FullPath} size="lg">
              Complete your profile
            </Button>
            <sub className={styles.sub}>or choose category below</sub>
          </div>
        </div>
        <button
          type="button"
          className={styles.link}
          onClick={() =>
            authService.patch({ diff: { user: { status: "completed" } } })
          }
        >
          Skip
        </button>
      </AuthLayout>
    </AccessController>
  );
}

export { Congratulations };

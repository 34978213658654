import { useState } from "react";
import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";
import styles from "./DeviceSelectionScreen.module.scss";
import useVideoContext from "hooks/useVideoContext";
import ToggleAudioButton from "common/components/Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "common/components/Buttons/ToggleVideoButton/ToggleVideoButton";
import { useSearchParams } from "react-router-dom";
import { ErrorPage } from "pages/ErrorPage";
import axios from "axios";
import { esaApiEndpoint } from "utils/env";
import { Preloader } from "common/UIKit/Preloader";
import phoneIcon from "./content/phone.svg";
import { videoChatParticipantStatusService } from "services/videoCallParticipantStatusService";
import { ParticipantType, ConnectionStatuses, get_participant_status_result, UpdateSessionStatusArgs} from "types/VideoChatParticipant.types";

interface DeviceSelectionScreenProps {
  name: string;
  patientName: string;
  therapistName: string;
}

export default function DeviceSelectionScreen({
  name,
  patientName,
  therapistName
}: DeviceSelectionScreenProps) {
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
  } = useVideoContext();
  const [isFetching, setIsFetching] = useState(false);
  const disableButtons = isAcquiringLocalTracks || isConnecting;
  const [params] = useSearchParams();
  const roomName = `${patientName} - ${therapistName}`;
  const isTherapist = params.get("isTherapist");
  let title = isTherapist ?
  `Your patient ${patientName} will be here shortly`
  :
  `Your therapist ${therapistName} will be here shortly`;

  const sessionId = params.get("SessionID");
  let participantType: ParticipantType = isTherapist ? ParticipantType.Therapist : ParticipantType.Patient;
  let args:UpdateSessionStatusArgs = { SessionID: sessionId!, Participant: participantType, Status: ConnectionStatuses.Started };

  if (!sessionId) {
    return <ErrorPage />;
  }
  
  const handleJoin = () => {
    setIsFetching(true);
    axios
      .post(`${esaApiEndpoint}/videoSession/GetToken/get_token/${sessionId}`)
      .then((data) => {
        let chatToken = "";
        if (params.get("isTherapist")) {
          chatToken = data.data.TherapistToken;
        } else {
          chatToken = data.data.ClientToken;
        }
        videoChatParticipantStatusService.setSessionStatus(args);
        setIsFetching(false);
        videoConnect(chatToken, roomName);
      })
      .catch((error) => console.log(error));
  };

  if (isFetching || isConnecting) {
    return (
      <Preloader text="Joining Meeting" className="bg-black text-white" />
    );
  }
  
  return (
  <>
    <section className={styles.main}>
      <button id={styles.joinCall} className={styles.mainButton} onClick={handleJoin} disabled={disableButtons}>
        <img src={phoneIcon} alt="phone-icon" />
        JOIN
      </button>
      <div className={styles.mainVideo}>
      <LocalVideoPreview identity={name} />
      </div>

      <div className={styles.joinMessage}>
        <p className={styles.joinMessageText}>Please join your session.</p>
        <p className={styles.joinMessageText}>{title}</p>
      </div>

    </section>

    <div className={styles.bottomBar}>
      <ToggleAudioButton disabled={disableButtons} />
      <ToggleVideoButton disabled={disableButtons} />
    </div>
  </>
  );
}

import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Edit } from "assets/images/icons/edit.svg";

import Button from "common/UIKit/Button";
import { UserTestimonial } from "common/components/UserTestimonial";

import { useResponsive } from "hooks/useResponsive";
import { useAuth } from "hooks/useAuth";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";

import { personalInformationPath } from "../PersonalInformation";
import { MyAccountLayout } from "../MyAccountLayout";
import { ProfileCard } from "../PersonalInformation/PersonalInformationDesktop/ProfileCard";

import styles from "./OverviewMobile.module.scss";

export const overviewMobilePath = "/";

interface Props {
  onPersonalInformation: () => void;
  onChangePassword: () => void;
  onPatientInformation: () => void;
}

export const OverviewMobile: FC<Props> = ({
  onPersonalInformation,
  onChangePassword,
  onPatientInformation,
}) => {
  useDashboardNavigation("My Account", {
    isMainTab: true,
  });
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { baseUser } = useAuth();

  useEffect(() => {
    if (!isMobile) {
      navigate(`.${personalInformationPath}`);
    }
  }, [isMobile]);

  return (
    <MyAccountLayout>
      <div className={styles.personalInformation}>
        <div className={styles.profileWrapper}>
          <ProfileCard />
          <div className={styles.wrapper}>
            <div className={styles.info}>
              <h3>Personal information</h3>
            </div>
            <Button onClick={onPersonalInformation} size="lg">
              <Edit /> Edit
            </Button>
          </div>
        </div>

        <div className={styles.backdrop}>
          <div>
            <div className={styles.infoField}>
              <h3>Patient's information</h3>
            </div>
          </div>
          <Button onClick={onPatientInformation} size="lg">
            <Edit /> Edit
          </Button>
        </div>

        {!baseUser?.is_google_user && (
          <div className={styles.backdrop}>
            <div>
              <div className={styles.infoField}>
                <h3>Change password</h3>
              </div>
            </div>
            <Button onClick={onChangePassword} size="lg">
              <Edit /> Edit
            </Button>
          </div>
        )}
      </div>

      <UserTestimonial />
    </MyAccountLayout>
  );
};

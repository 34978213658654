import Layout from "common/components/Layout/Layout";
import { ReactComponent as CloseIcon } from "assets/images/icons/check.svg";
import Button from "common/UIKit/Button";
import { patientsDashboardFullPath } from "../Patients/PatientsDashboard/PatientsDashboard";
import styles from "./SuccessfulPage.module.scss";

interface Props {
  title?: string;
  text?: string;
}

export const SuccessfulPage = ({
  title = "Payment successful",
  text = "Thank you for your billing!",
}: Props) => {
  return (
    <Layout withoutHeader mainClass={styles.root}>
      <section className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.icon}>
            <CloseIcon />
          </div>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.text}>{text}</p>
          <Button
            className={styles.button}
            to={patientsDashboardFullPath}
            isLink
            size="lg"
          >
            Thank you!
          </Button>
        </div>
      </section>
    </Layout>
  );
};

import { DashboardLayoutDesktopHeader } from "common/components/DashboardLayout/DesktopHeader";
import { format } from "date-fns";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { useWindowResize } from "hooks/useWindowResize";
import { patientProfileService } from "services/patientProfileService";
import { formatPhone, therapistName } from "common/utils";
import { dictionaryService, DictionaryItem } from "services/dictionaryService";
import { filterDictionaryItems } from "utils/utils";
import {
  AGE_OPTIONS,
  APPOINTMENT_DATE_OPTIONS,
  THERAPY_TYPE_OPTIONS,
} from "constants/options";
import { testAttemptService } from "services/testAttemptService";
import { loginPath } from "../../commonLinks";
import { Avatar } from "common/UIKit/Avatar/Avatar";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./TherapistPatient.module.scss";
import Button from "common/UIKit/Button";
import { clientVideoPath } from "pages/ClientVideo";
import { esaApiEndpoint } from "utils/env";
import { LoadingHOC } from "common/components/LoadingHOC";
import { DateTimePickerPopup } from "pages/Therapists/TherapistPatient/DateTimePickerPopup";
import { useModal } from "hooks/useModal";
import { therapistsPath } from "../therapistsPath";
import { DashboardLayout } from "common/components/DashboardLayout";
import { AccessController } from "common/components/Layout/AccessController";
import { tabs } from "../TherapistsDashboard/tabs";
import { SessionMeta } from "types/clientVideo.types";

export const therapistPatientPath = "/patient";

interface Props {
  showJoinVideoButton?: boolean;
  chatSessionId?: string;
  therapistKey?: string;
  patientId?: string;
  clientId?: string;
}

export const TherapistPatient: FC<Props> = ({
  showJoinVideoButton,
  chatSessionId,
  therapistKey,
  patientId,
  clientId,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { width } = useWindowResize();
  const isSmallWindow = width < 1200;
  const { currentProfile } = useSnapshot(patientProfileService);
  const { data: tests } = useSnapshot(testAttemptService);
  const [issues, setIssues] = useState<DictionaryItem[]>([]);
  const [languages, setLanguages] = useState<DictionaryItem[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [numParticipants, setNumParticipants] = useState(0);
  const { open: showRescheduleModal } = useModal("DATE_TIME_PICKER");
  const [chatSessionData, setChatSessionData] = useState<SessionMeta>();

  // showJoinVideoButton = true;
  // chatSessionId = "E1E3415E-622A-409E-B827-B00300D00CF4";
  // therapistKey = "christian-starpoli";
  // patientId = "VqYKXvL";

  useDocumentTitle(
    `${therapistName({
      first_name: currentProfile?.user.first_name,
      last_name: currentProfile?.user.last_name,
    })} Patient Details`
  );

  const ptId = id || patientId;

  useEffect(() => {
    (async () => {
      if (!ptId) return;
      try {
        await patientProfileService.getById(ptId);
        await testAttemptService.list(false, {
          patient_id: ptId,
          page_size: 100,
          status: "finished",
        });
      } catch (error) {
        const { status } = error as AxiosResponse;
        if ([403, 404].includes(status)) {
          navigate("/error");
          return;
        }
        if (status === 401) {
          localStorage.setItem("redirect_to", window.location.pathname);
          navigate({
            pathname: `/therapist${loginPath}`,
            search: `?redirect_to=${window.location.pathname}`,
          });
          return;
        }

        throw error;
      }
    })();
    // eslint-disable-next-line
  }, [ptId]);

  useEffect(() => {
    (async () => {
      const [issues, languages] = await Promise.all([
        dictionaryService.getIssues(),
        dictionaryService.getLanguages(),
      ]);
      setIssues(filterDictionaryItems(issues, currentProfile?.issues));
      setLanguages(
        filterDictionaryItems(languages, currentProfile?.preferred_languages)
      );
    })();
  }, [currentProfile]);

  useEffect(() => {
    axios
      .get(
        `${esaApiEndpoint}/videoSession/GetParticpants/GetParticpants/${chatSessionId}`
      )
      .then((data) => {
        if (data.data.Participants) {
          setNumParticipants(data.data.Participants.length);
        }
      });
  }, [chatSessionId]);

  useEffect(() => {
    if (chatSessionId) {
      axios
        .get(`${esaApiEndpoint}/videoSession/GetSession/${chatSessionId}`)
        .then((response) => {
          const data = response.data;
          const address = data.Client.Addresses[0];
          const formatAddr = `${address.Address1}${
            address.Address2 ? ` ${address.Address2}` : ""
          }, ${address.City}, ${address.State}, ${address.Zip}`;
          const meta = {
            therapistName: data.Therapist.Name,
            clientName: data.Client.FullName,
            clientEmail: data.Client.Email,
            clientPhoneNumber: data.Client.PhoneNumber,
            clientAddress: formatAddr,
            animals: data.Client.Animals.map((animal: any) => ({
              id: animal.PersonAnimalID,
              name: animal.AnimalName,
              animalType: animal.AnimalType.TypeName,
              breedName: animal.BreedName,
              gender: animal.Gender,
            })),
          };
          setChatSessionData(meta);
        });
    }
  }, [chatSessionId]);

  const onJoin = () => {
    navigate(
      {
        pathname: clientVideoPath,
        search: `?SessionID=${chatSessionId}&patientId=${patientId}&therapistKey=${therapistKey}&clientId=${clientId}&isTherapist=${true}`,
      },
      {
        replace: true,
      }
    );
  };

  const onApprove = () => {
    const payload = JSON.stringify({
      CallCompleteAction: 0,
      SessionID: chatSessionId,
      Notes: "",
    });
    const meta = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setIsUpdating(true);
    axios
      .post(`${esaApiEndpoint}/therapist/callcomplete`, payload, meta)
      .then((response) => {
        setIsUpdating(false);
      })
      .catch((error) => {
        setIsUpdating(false);
        console.log(error);
      });
  };

  const onDeny = () => {
    const payload = JSON.stringify({
      CallCompleteAction: 1,
      SessionID: chatSessionId,
      Notes: "",
    });
    const meta = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setIsUpdating(true);
    axios
      .post(`${esaApiEndpoint}/therapist/callcomplete`, payload, meta)
      .then((response) => {
        setIsUpdating(false);
      })
      .catch((error) => {
        setIsUpdating(false);
        console.log(error);
      });
  };

  const onReschedule = (newTime: string) => {
    const payload = JSON.stringify({
      CallCompleteAction: 2,
      SessionID: chatSessionId,
      Notes: "",
      RescheduleTime: newTime,
    });
    const meta = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setIsUpdating(true);
    axios
      .post(`${esaApiEndpoint}/therapist/callcomplete`, payload, meta)
      .then((response) => {
        setIsUpdating(false);
      })
      .catch((error) => {
        setIsUpdating(false);
      });
  };

  return (
    <AccessController therapist="allowed">
      <DashboardLayout tabs={tabs}>
        <LoadingHOC isLoading={isUpdating}>
          <section className="default-typography">
            <h1 className="h3 mb-25 mb-sm-40 text-center text-sm-start">
              Patient Details
            </h1>

            <div className={styles.patientDetailsBlock}>
              <div className={styles.patientDetailsBlock__group}>
                <div className="row align-items-center">
                  <div className="col-xl-auto d-flex">
                    <Avatar
                      className={styles.patientDetailsBlock__avatar}
                      file={
                        currentProfile?.file ? currentProfile.file : undefined
                      }
                      alt={therapistName({
                        first_name: currentProfile?.user.first_name,
                        last_name: currentProfile?.user.last_name,
                      })}
                    />
                  </div>
                  <div className="col">
                    <div className="ps-xl-10">
                      {showJoinVideoButton && chatSessionId && (
                        <>
                          <div className="row gutter-12">
                            <div className="col-sm-auto py-4">
                              <Button
                                className="w-100 w-xs-auto with-left-side-icon"
                                onClick={onJoin}
                              >
                                <span className="icomoon-call btn-icon"></span>
                                Join Call
                              </Button>
                            </div>
                            <div className="col-6 col-sm-auto py-4">
                              <Button
                                className="w-100 w-xs-auto with-left-side-icon"
                                onClick={onApprove}
                                color="outline-primary"
                              >
                                <span className="icomoon-approve btn-icon text-teal-dark"></span>
                                Approve
                              </Button>
                            </div>
                            <div className="col-6 col-sm-auto py-4 d-sm-inline-flex align-items-center">
                              <Button
                                className="w-100 w-xs-auto with-left-side-icon"
                                onClick={onDeny}
                                color="outline-red-muted"
                              >
                                <span className="icomoon-decline btn-icon text-red-muted"></span>
                                Decline
                              </Button>
                              <div
                                className={cn(
                                  styles.patientDetailsBlock__btnDivider,
                                  "d-none d-sm-block"
                                )}
                              ></div>
                            </div>
                            <div className="col-12 py-4 d-block d-sm-none">
                              <div
                                className={
                                  styles.patientDetailsBlock__btnDivider
                                }
                              ></div>
                            </div>
                            <div className="col-sm-auto py-4">
                              <Button
                                className="w-100 w-xs-auto with-left-side-icon"
                                onClick={showRescheduleModal}
                                color="outline-gray"
                              >
                                <span className="icomoon-calendar btn-icon text-secondary"></span>
                                Reschedule
                              </Button>

                              <DateTimePickerPopup
                                onReschedule={onReschedule}
                              />
                            </div>
                          </div>
                          <h6 className="text-gray mb-0 pt-10 text-center text-sm-start">
                            {numParticipants ? numParticipants : 0}{" "}
                            {numParticipants < 1
                              ? "participant"
                              : "participants"}{" "}
                            in the room
                          </h6>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.patientDetailsBlock__group}>
                <h4 className="mb-5">Client Information</h4>

                <div className="row">
                  <div className="col-xs-6 col-lg-3 mt-20">
                    <h6 className="text-gray">Email</h6>
                    {currentProfile?.user.email ? (
                      <a
                        href={`mailto:${currentProfile?.user.email}`}
                        className="h6 mb-0 link-secondary"
                      >
                        {currentProfile?.user.email}
                      </a>
                    ) : (
                      <h6 className="mb-0">Not provided</h6>
                    )}
                  </div>
                  <div className="col-xs-6 col-lg-3 mt-20">
                    <h6 className="text-gray">Phone Number</h6>
                    <a
                      href={`tel:${formatPhone(
                        currentProfile?.user.mobile_phone
                      )}`}
                      className="h6 mb-0 link-secondary"
                    >
                      {formatPhone(currentProfile?.user.mobile_phone)}
                    </a>
                  </div>
                  <div className="col-xs-6 mt-20">
                    <h6 className="text-gray">Address</h6>
                    <h6 className="mb-0">{chatSessionData?.clientAddress}</h6>
                  </div>
                  <div className="col-xs-6 col-lg-3 mt-20">
                    <h6 className="text-gray">
                      When would you like to speak to a therapist?
                    </h6>
                    <h6 className="mb-0">
                      {currentProfile?.appointment_date
                        ? APPOINTMENT_DATE_OPTIONS.find(
                            (date) =>
                              date.value === currentProfile.appointment_date
                          )?.label
                        : "Not provided"}
                    </h6>
                  </div>
                  <div className="col-xs-6 col-lg-3 mt-20">
                    <h6 className="text-gray">Language</h6>
                    <h6 className="mb-0">
                      {languages.length
                        ? languages.map((language) => language.name).join(", ")
                        : "Not provided"}
                    </h6>
                  </div>
                  <div className="col-xs-6 mt-20">
                    <h6 className="text-gray">I’d like to do therapy via</h6>
                    <h6 className="mb-0">
                      {THERAPY_TYPE_OPTIONS.filter((option) =>
                        currentProfile?.therapy_types.includes(option.value)
                      ).length
                        ? THERAPY_TYPE_OPTIONS.filter((option) =>
                            currentProfile?.therapy_types.includes(option.value)
                          )
                            .map((item) => item.label)
                            .join(", ")
                        : "Not provided"}
                    </h6>
                  </div>
                  <div className="col-xs-6 mt-20">
                    <h6 className="text-gray">
                      I would prefer a therapist with experience in
                    </h6>
                    <h6 className="mb-0">
                      {issues.length
                        ? issues.map((issue) => issue.name).join(", ")
                        : "Not provided"}
                    </h6>
                  </div>
                </div>
              </div>

              <div className={styles.patientDetailsBlock__group}>
                <h4 className="mb-5">Animal Information</h4>
                {chatSessionData?.animals?.map((animal) => (
                  <div className="row" key={animal.id}>
                    <div className="col-xs-6 col-lg-3 mt-20">
                      <h6 className="text-gray">Pet Name</h6>
                      <h6 className="mb-0">{animal.name}</h6>
                    </div>
                    <div className="col-xs-6 col-lg-3 mt-20">
                      <h6 className="text-gray">Type</h6>
                      <h6 className="mb-0">{animal.animalType}</h6>
                    </div>
                    <div className="col-xs-6 col-lg-3 mt-20">
                      <h6 className="text-gray">Breed</h6>
                      <h6 className="mb-0">{animal.breedName}</h6>
                    </div>
                    <div className="col-xs-6 col-lg-3 mt-20">
                      <h6 className="text-gray">Gender</h6>
                      <h6 className="mb-0">{animal.gender} </h6>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.testsTakenBlock}>
              <h4 className="mb-15 mb-sm-5">Tests Taken</h4>

              {tests.length ? (
                <div className="row gutter-20">
                  {tests.map((test) => (
                    <div className="col-sm-6 col-xl-4 d-flex" key={test.id}>
                      <div
                        className={styles.testsTakenItem}
                        onClick={() =>
                          navigate(
                            `${therapistsPath}${therapistPatientPath}/${ptId}/test/${test.id}`
                          )
                        }
                      >
                        <h6 className="text-lg mb-0 mb-md-5">
                          {test.test.name}
                        </h6>
                        <div className="text-block text-sm text-gray">
                          <p>Result {test.result}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-block text-gray mt-25">
                  <p>This patient has no test results on file.</p>
                </div>
              )}
            </div>
          </section>
        </LoadingHOC>
      </DashboardLayout>
    </AccessController>
  );
};

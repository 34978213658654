import { FC } from "react";

import { useAuth } from "hooks/useAuth";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";

import Button from "common/UIKit/Button";
import { FormatProfileField } from "common/components/FormatProfileField";
import { UserTestimonial } from "common/components/UserTestimonial";

import { ProfileCard } from "./ProfileCard";

import styles from "./PersonalInformationDesktop.module.scss";

import { ReactComponent as Edit } from "assets/images/icons/edit.svg";
import { GENDER_OPTIONS, PREFIX_OPTIONS } from "constants/options";

interface Props {
  onEdit: () => void;
  onEditBilling: () => void;
  onEditShipping: () => void;
  onChangePassword: () => void;
}

export const PersonalInformationDesktop: FC<Props> = ({
  onEdit,
  onEditBilling,
  onEditShipping,
  onChangePassword,
}) => {
  useDashboardNavigation("Personal Information", {
    goBack: "pop_path",
  });
  const { user, baseUser } = useAuth();
  const prefix = PREFIX_OPTIONS.find((o) => o.value === user?.user.prefix)?.label || "";
  const fullName = `${prefix} ${user?.user.first_name} ${user?.user.last_name}`.trim()

  return (
    <div className={styles.personalInformation}>
      <div className="remove-last-child-margin">
        <div className={styles.profileWrapper}>
          <ProfileCard />
          <div className={styles.wrapper}>
            <div className="row align-items-center">
              <div className="col">
                <div className="row remove-xxl-last-2-child-margin">
                  <div className="col-6">
                    <div className="mb-40">
                      <h3>Name</h3>
                      <small>
                        <FormatProfileField maxLength={40}>
                          {fullName}
                        </FormatProfileField>
                      </small>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-40">
                      <h3>Mobile Phone</h3>
                      <small>
                        <FormatProfileField shouldLimitLength={false}>
                          {user?.user.mobile_phone}
                        </FormatProfileField>
                      </small>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-40">
                      <h3>Email</h3>
                      <small>
                        <FormatProfileField shouldLimitLength={false}>
                          {user?.user.email}
                        </FormatProfileField>
                      </small>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-40">
                      <h3>Mental Health Role</h3>
                      <small>Patient</small>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-40">
                      <h3>Gender</h3>
                      <small>
                        <FormatProfileField shouldLimitLength={false}>
                          {GENDER_OPTIONS.find((o) => o.value === user?.user.gender)?.label}
                        </FormatProfileField>
                      </small>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-40">
                      <h3>Date of Birth</h3>
                      <small>
                        <FormatProfileField shouldLimitLength={false}>
                          {user?.user.dob}
                        </FormatProfileField>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-auto">
                <Button className={styles.iconButton} onClick={onEdit} size="lg">
                  <Edit /> Edit information
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.fieldsBlock}>
          <div className="row align-items-center">
            <div className="col">
              <h3 className="mb-40">Billing Address</h3>

              <div className="row remove-xxl-last-2-child-margin">
                <div className="col-6">
                  <div className="mb-40">
                    <h3>Street Address 1</h3>
                    <small>
                      <FormatProfileField maxLength={40}>
                        {user?.user.billing_address_1}
                      </FormatProfileField>
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-40">
                    <h3>Street Address 2</h3>
                    <small>
                      <FormatProfileField maxLength={40}>
                        {user?.user.billing_address_2}
                      </FormatProfileField>
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-40">
                    <h3>City</h3>
                    <small>
                      <FormatProfileField maxLength={40}>
                        {user?.user.billing_city}
                      </FormatProfileField>
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-40">
                    <h3>State</h3>
                    <small>
                      <FormatProfileField maxLength={40}>
                        {user?.user.billing_state}
                      </FormatProfileField>
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-40">
                    <h3>Zip</h3>
                    <small>
                      <FormatProfileField maxLength={40}>
                        {user?.user.billing_zip}
                      </FormatProfileField>
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-auto">
              <Button className={styles.iconButton} onClick={onEditBilling} size="lg">
                <Edit /> Edit Billing Address
              </Button>
            </div>
          </div>
        </div>

        <div className={styles.fieldsBlock}>
          <div className="row align-items-center">
            <div className="col">
              <h3 className="mb-40">Shipping Address</h3>

              <div className="row remove-xxl-last-2-child-margin">
                <div className="col-6">
                  <div className="mb-40">
                    <h3>Street Address 1</h3>
                    <small>
                      <FormatProfileField maxLength={40}>
                        {user?.user.shipping_address_1}
                      </FormatProfileField>
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-40">
                    <h3>Street Address 2</h3>
                    <small>
                      <FormatProfileField maxLength={40}>
                        {user?.user.shipping_address_2}
                      </FormatProfileField>
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-40">
                    <h3>City</h3>
                    <small>
                      <FormatProfileField maxLength={40}>
                        {user?.user.shipping_city}
                      </FormatProfileField>
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-40">
                    <h3>State</h3>
                    <small>
                      <FormatProfileField maxLength={40}>
                        {user?.user.shipping_state}
                      </FormatProfileField>
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-40">
                    <h3>Zip</h3>
                    <small>
                      <FormatProfileField maxLength={40}>
                        {user?.user.shipping_zip}
                      </FormatProfileField>
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-40">
                    <h3>The same as Billing Address</h3>
                    <small>
                      <FormatProfileField maxLength={40}>
                        {user?.user.is_billing_same_as_shipping ? "Yes" : "No"}
                      </FormatProfileField>
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-auto">
              <Button className={styles.iconButton} onClick={onEditShipping} size="lg">
                <Edit /> Edit Shipping Address
              </Button>
            </div>
          </div>
        </div>

        {!baseUser?.is_google_user && (
          <div className={styles.fieldsBlock}>
            <div className="row align-items-center">
              <div className="col">
                <h3 className="mb-40 mb-xxl-0">Change password</h3>
              </div>

              <div className="col-xxl-auto">
                <Button className={styles.iconButton} onClick={onChangePassword} size="lg">
                  <Edit /> Change password
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <UserTestimonial />
    </div>
  );
};

import cn from "classnames";

import Button from "../Button";
import styles from "./PaymentRadio.module.scss";

interface Props<T> {
  options?: { label: string; value: T }[];
  value?: T;
  onChange?: (newValue: T) => void;
  className?: string;
}

export const PaymentRadio = <T,>({
  options = [],
  value,
  onChange,
  className,
}: Props<T>) => {
  return (
    <div className={cn(className, styles.paymentRadio)}>
      {options.map((option) => (
        <Button
          key={option.label}
          text={option.label}
          onClick={() => onChange && onChange(option.value)}
          className={cn(option.value === value && styles.selected)}
          size="lg"
        />
      ))}
    </div>
  );
};

import { useCallback, useEffect, useState } from "react";
import useLocalTracks from "providers/VideoProvider/useLocalTracks";
import useVideoContext from "./useVideoContext";
import axios from "axios";
import { esaApiEndpoint } from "utils/env";
import { useNavigate } from "react-router-dom";

type RoomStateType = "disconnected" | "connected" | "reconnecting";

export default function useRoomState(onError: any, sessionId: string | null = null, isTherapist: boolean | null = null) {
  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      console.log(`ERROR: ${error.message}`, error);
    },
    [onError]
  );
  const { localTracks } = useLocalTracks();
  const { room } = useVideoContext();
  const [state, setState] = useState<RoomStateType>("disconnected");
  const navigate = useNavigate();

  const GetRoomStatus = () => {
    if (sessionId) {
		// This needs to be swapped over to use the service.
      axios		
        .get(`${esaApiEndpoint}/videoSession/GetParticipantStatus/${sessionId}`)
        .then((response) => { 
          if (response.data) {
            const providerStatus = response.data.ProviderStatus;
            const clientStatus = response.data.ClientStatus; 
            if (!isTherapist && clientStatus) {
              if (providerStatus === 3) {
				let urlRedirect = '/patients/dashboard/my-account/personal-information';
                navigate( urlRedirect);
              }
           
            }
            if (isTherapist && providerStatus) {
              if (providerStatus === 3) {
                SetRoomStatus(2, 3);
                return;
              }
              // if (roomState === "disconnected") {
              //   SetRoomStatus(1, 4);
              // }
              // else if (roomState === "connected" && clientStatus !== 3) {
              //   SetRoomStatus(1, 1);
              // }
            }
          }
        })
      .catch((error) => console.log(error));
    }
  }
  const SetRoomStatus = (participant: number, status: number) => {
    axios
      .post(`${esaApiEndpoint}/videoSession/SetParticipantStatus`, 
      { sessionId: sessionId, 
        Participant: participant,
        Status: status
      })
      .catch((error) => console.log(error));
  }
  if (state)
    GetRoomStatus();

  useEffect(() => {
    if (room) {
      const setRoomState = () => setState(room.state as RoomStateType);
      setRoomState();
      room
        .on("disconnected", setRoomState)
        .on("reconnected", setRoomState)
        .on("reconnecting", setRoomState);
      return () => {
        room
          .off("disconnected", setRoomState)
          .off("reconnected", setRoomState)
          .off("reconnecting", setRoomState);
      };
    }
  }, [room]);

  return state;
}
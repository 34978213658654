import { useResponsive } from "hooks/useResponsive";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { MessagePopup } from "common/components/MessagePopup";
import { useDocumentTitle } from "hooks/useDocumentTitle";

import Button from "common/UIKit/Button";
import { ReactComponent as EditIcon } from "assets/images/icons/edit-white.svg";

import styles from "./AnimalsInformation.module.scss";

import { FC, useEffect, useState } from "react";
import { esaProfileService } from "services/esaProfileService";
import { Animal } from "types/profile.types";
import { animalTypes, dogBreeds, catBreeds } from "constants/options";
import { useSnapshot } from "valtio";
import avatar from "assets/images/avatar-animal.svg";

interface Props {
  onAddAnimal: () => void;
  onEditAnimal: (clientAnimalId: number) => void;
}
export const AnimalsInformation: FC<Props> = ({
  onAddAnimal,
  onEditAnimal,
}) => {
  useDashboardNavigation("My animals", { goBack: "pop_path" });
  const { isMobile } = useResponsive();
  useDocumentTitle("TheraPass - My animals");

  useEffect(() => {
    esaProfileService.getProfile();
  });

  const { animals } = useSnapshot(esaProfileService);

  return (
    <div>
      <MessagePopup />

      <div className="default-typography">
        {!isMobile && <h1 className="h3">My animals</h1>}

        <div className="row">
          <div className="col-md-6 col-xxl-4">
            {animals.map((animal) => (
              <div key={animal.ClientAnimalID} className={styles.cardAnimal}>
                <div className={styles.cardAnimal__header}>
                  <h6 className={styles.cardAnimal__name}>
                    {animal.AnimalName}
                  </h6>
                  <h6 className={styles.cardAnimal__type}>
                    Type:{" "}
                    {
                      animalTypes.filter(
                        (v) => animal.AnimalTypeId === v.value
                      )[0]?.label
                    }
                  </h6>
                </div>

                <div className={styles.cardAnimal__photoBlock}>
                  <div className={styles.cardAnimal__photo}>
                    {animal.Photo ? (
                      <img src={animal.Photo} alt="Dog" className="of-cover" />
                    ) : (
                      <img
                        src={avatar}
                        alt="Dog"
                        className={styles.animalAvatar__img}
                      />
                    )}
                  </div>
                </div>

                <div className={styles.cardAnimal__body}>
                  <button
                    className={styles.cardAnimal__btnEdit}
                    onClick={() => onEditAnimal(animal.ClientAnimalID)}
                  >
                    <EditIcon />
                  </button>
                  <div className="remove-last-child-margin">
                    <div className="mb-35">
                      <h6 className="mb-5">Breed</h6>
                      <p className="mb-0 text-sm text-gray">
                        {animal.AnimalTypeId === 1
                          ? dogBreeds.filter(
                              (v) => animal.AnimalBreedId === v.value
                            )[0]?.label
                          : catBreeds.filter(
                              (v) => animal.AnimalBreedId === v.value
                            )[0]?.label}
                      </p>
                    </div>
                    <div className="mb-35">
                      <h6 className="mb-5">Weight</h6>
                      <p className="mb-0 text-sm text-gray">
                        {animal.AnimalWeight} lbs
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Button onClick={onAddAnimal} size="lg">
          Add Support Animal
        </Button>
      </div>
    </div>
  );
};

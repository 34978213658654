import Button from "common/UIKit/Button";
import girlPath from "assets/images/peoples/therapist-landing.png";
import styles from "./ProvideSection.module.scss";

export const ProvideSection = () => {
  return (
    <section className={styles.root}>
      <div className={styles.info}>
        <h1 className={styles.title}>Provide care anywhere, anytime</h1>
        <p className={styles.text}>Build your practice with Therapass</p>
        <Button className={styles.button} isLink to="/therapist/signup" size="lg">
          Apply Now
        </Button>
      </div>
      <img className={styles.image} src={girlPath} alt="Girl with phone" />
    </section>
  );
};

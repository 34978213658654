import { parseISO, format } from "date-fns";
import { MouseEvent as SyntheticMouseEvent, SetStateAction } from "react";
import { FieldValues, Path, UseFormSetError } from "react-hook-form";
import { capitalize, isFunction, isObject } from "lodash";
import { ActionMeta } from "react-select";
import { AxiosResponse } from "axios";

import { DictionaryItem } from "services/dictionaryService";
import { ListOption } from "constants/options";
import {
  OtherCredentials,
  Gender,
  TherapistFile,
  Title,
} from "types/therapist.types";
import { TherapistFields } from "pages/Therapists/TherapistSteps/util";
import { NUMBER_FIELD, ORGANIZATION_FIELD } from "constants/fields";

export * from "./address";
export * from "./time";
export * from "./userAgent";

// TYPES & INTERFACES
export type DeepPartial<T> = T extends object
  ? { [P in keyof T]?: DeepPartial<T[P]> }
  : T;
export type Nullable<T> = T | null;
export type Awaited<T> = T extends PromiseLike<infer U> ? U : T;
export type AbstractFunc = (...args: any[]) => void;
export type UpdateFunc<T> = (curr: T) => T;

// TYPE PREDICATES
export function isFile(f: any): f is File {
  return f instanceof File;
}

// DOM + LAYOUT
export function isMobile(maxWidth = 900) {
  return window.innerWidth <= maxWidth;
}

export function isElementFullScreen(element: Element | null) {
  return (
    element &&
    document.fullscreenElement &&
    document.fullscreenElement === element
  );
}

export function blockEvent(e: SyntheticMouseEvent<any> | MouseEvent) {
  e.preventDefault();
  e.stopPropagation();
}

// PARSERS & TRANSFORMERS
export function toCodes(
  options: ListOption[],
  actionMeta: ActionMeta<ListOption>,
  noPreference: string = "no-preference"
): string[] {
  if (
    actionMeta.action === "select-option" &&
    actionMeta.option?.value === noPreference
  ) {
    return [noPreference];
  }

  return options
    .map((option) => option.value)
    .filter((option) => option !== noPreference);
}

export function toOptions<V = string>(
  items: DictionaryItem<V>[]
): ListOption<V>[] {
  const sorted = [...items].sort((itemA, itemB) => {
    const orderA = itemA.order || 0;
    const orderB = itemB.order || 0;
    return orderA - orderB;
  });
  return sorted.map((item) => ({
    value: item.code,
    label: item.name,
  }));
}

export function removeLeadingZeroes(n: number) {
  return Number(n).toString();
}

export function formatCardExpirationDate(date: string, shortYear = false) {
  const [year, month] = date.split("-");
  return `${month}/${year.slice(shortYear ? 2 : 0)}`;
}

export function formatArticleDate(dateString: string, withTime?: boolean) {
  const date = parseISO(dateString);

  if (withTime) {
    return format(date, "MMMM d, yyyy h:mm aa");
  }

  return format(date, "MMMM d, yyyy");
}

export function updateOptionsArray(
  isChecked: boolean,
  selected: string,
  currentOptions: string[],
  noPreferenceCode: string
) {
  if (selected === noPreferenceCode) {
    return isChecked ? [noPreferenceCode] : [];
  }

  return isChecked
    ? currentOptions
        .filter((option) => option !== noPreferenceCode)
        .concat(selected)
    : currentOptions.filter((option) => option !== selected);
}

export function containsLettersOrNumbers(value: string) {
  return /\d|[\wа-яА-Я]/.test(value);
}

export function isEmpty(value?: any) {
  return (
    value === undefined ||
    value == null ||
    value?.length <= 0 ||
    (typeof value === "string" && !containsLettersOrNumbers(value))
  );
}

export function cropString(
  string: string,
  config: {
    maxLength?: number;
    endingIfCropped?: string;
    description?: boolean;
  } = {}
) {
  const { maxLength = 0, endingIfCropped = "...", description } = config;
  if (description) {
    const croppedString = string.slice(0, maxLength);
    const lastIndex = croppedString.lastIndexOf(" ");
    return string.length > maxLength
      ? croppedString.slice(0, lastIndex) + endingIfCropped
      : string;
  } else {
    return string.length > maxLength
      ? string.slice(0, maxLength) + endingIfCropped
      : string;
  }
}

export function formatProfileField(
  property?: any,
  config?: {
    fallbackText?: string;
    shouldFallback?: boolean;
    maxLength?: number;
  }
) {
  if (
    config?.shouldFallback ||
    isEmpty(
      property ||
        (typeof property === "string" && !containsLettersOrNumbers(property))
    )
  ) {
    return config?.fallbackText || "-";
  } else {
    if (typeof property === "string") {
      return config?.maxLength
        ? cropString(property, { maxLength: config.maxLength })
        : property;
    }
    return property;
  }
}

export function splitInsuranceCompanies(
  userInsuranceCompanies: readonly (string | undefined)[] | undefined,
  generalInsuranceCompanies?: ListOption[],
  additionalInsuranceCompanies?: ListOption[]
) {
  const generalCompanies: string[] = [],
    additionalCompanies: string[] = [];
  userInsuranceCompanies?.forEach((company) => {
    if (
      !company ||
      !generalInsuranceCompanies?.length ||
      !additionalInsuranceCompanies?.length
    ) {
      return;
    }

    if (generalInsuranceCompanies.find((item) => item.value === company)) {
      generalCompanies.push(company);
    } else if (
      additionalInsuranceCompanies.find((item) => item.value === company)
    ) {
      additionalCompanies.push(company);
    }
  });

  return [generalCompanies, additionalCompanies];
}

// OTHER
export function getErrorMessage({
  error, // usually instance of AxiosResponse
  defaultMessage = "Something went wrong. Please try again later.",
}: {
  error: any;
  defaultMessage?: string;
}): { text: string; messages: string[] } {
  const { data } = error;

  if (typeof data === "string") {
    if (/<html/.test(data)) {
      return {
        text: defaultMessage,
        messages: [defaultMessage],
      };
    }

    return { text: data, messages: [data] };
  }
  const messages: string[] = [];

  try {
    const stack = [data];
    while (stack.length) {
      const stackItem = stack.pop();
      if (typeof stackItem === "string") {
        messages.push(stackItem);
      } else if (Array.isArray(stackItem)) {
        for (let i = stackItem.length - 1; i >= 0; i--) {
          stack.push(stackItem[i]);
        }
      } else {
        stack.push(Object.values(stackItem));
      }
    }
  } catch (e) {}

  const errorMessage = messages.join("\n");

  return {
    text: errorMessage || defaultMessage,
    messages: errorMessage ? messages : [defaultMessage],
  };
}

export function getUpd<T>(upd: SetStateAction<T>, curr: T): T {
  return isFunction(upd) ? upd(curr) : upd;
}

export function formatPhone(phone?: string) {
  if (!phone) {
    return "Not provided";
  }

  if (phone.includes("+")) {
    // +17169706499
    return phone
      .replace(/\s/g, "")
      .replace(/\+(\d{1})(\d{3})(\d{3})(\d*)/, "+$1 ($2) $3-$4");
  } else {
    return phone
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d{3})(\d*)/, "($1) $2-$3");
  }
}

export function formatCurrency(number: number | null | undefined) {
  return number || number === 0
    ? number.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
    : "";
}

export function pluralize(number: number, single: string, plural: string) {
  return number > 1 ? plural : single;
}

export function therapistName({
  title,
  first_name,
  middle_name,
  last_name,
  credentials_choice,
}: {
  title?: Title;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  credentials_choice?: string;
}) {
  return [
    title && `${capitalize(title)}.`,
    first_name,
    middle_name && middle_name,
    last_name,
    credentials_choice && `(${credentials_choice})`,
  ].join(" ");
}

export function therapistPageTitle({
  first_name,
  last_name,
  professional_specialties,
  city,
  state,
  zip
}: {
  first_name?: string;
  last_name?: string;  
  professional_specialties?: string[];
  city?: string;
  state?: string;
  zip?: string;
}) {
  let therapist_profiessional_title = null;
  if (professional_specialties != null && professional_specialties.length >0){
    therapist_profiessional_title = professional_specialties[0];
  }
  return [
    first_name  && `${first_name.trim()}`,
    last_name && ` ${last_name.trim()}`,
    therapist_profiessional_title && `, ${therapist_profiessional_title.trim()}`,
    city && `, ${city.trim()}`,
    state && `, ${state.trim()}`,
    zip && ` ${zip.trim()}`,
  ].join("");
}


export function therapistPageMetaDescription({
  first_name,
  last_name,
  professional_specialties,
  city,
  state,
  zip,
  bio
}: {
  first_name?: string;
  last_name?: string;  
  professional_specialties?: string[];
  city?: string;
  state?: string;
  zip?: string;
  bio?: string;
}) {
  let therapist_profiessional_title = null;
  if (professional_specialties != null && professional_specialties.length >0){
    therapist_profiessional_title = professional_specialties[0];
  }
  let biography = null;  
  if (bio != null && bio.length >0){
    biography = cropString(bio, 
      {
        maxLength:500,
        endingIfCropped:"..",
        description : false
        })
  }
  let parts = [ 
    first_name  && `${first_name.trim()}`,
    last_name && ` ${last_name.trim()}`,
    therapist_profiessional_title && `, ${therapist_profiessional_title.trim()}`,
    city && `, ${city.trim()}`,
    state && `, ${state.trim()}`,
    zip && ` ${zip.trim()}`,,
    biography && ` ${biography.trim()}`,
  ];
  return parts.join("");
}


export function getAvatar(
  files: readonly TherapistFile[]
): TherapistFile | undefined {
  const photos = files.filter(
    (f) => f.mime.match("image") && f.type === "photo_and_video_intro"
  );

  let photoWithMinimalId = photos[0];

  for (let i = 0; i < photos.length; i += 1) {
    if (photos[i].is_main_photo) {
      return photos[i];
    }
    if (photos[i].id < photoWithMinimalId.id) {
      photoWithMinimalId = photos[i];
    }
  }

  return photoWithMinimalId;
}

export function getGenderPronoun(
  gender?: Gender,
  type?: "subject" | "object" | "possessive"
) {
  const table = {
    male: {
      subject: "he",
      object: "him",
      possessive: "his",
    },
    female: {
      subject: "she",
      object: "her",
      possessive: "her",
    },
    non_binary: {
      subject: "they",
      object: "them",
      possessive: "their",
    },
  };

  return table[gender || "non_binary"][type || "subject"];
}

export function goThroughErrors(
  errorsData: object,
  setError: (fieldName: string, errorMessage: string) => void
) {
  const process = (dataBlock: object, key?: string) => {
    Object.entries(dataBlock).forEach(([fieldName, info]) => {
      if (typeof info === "string") {
        setError(fieldName, info);
      } else if (Array.isArray(info)) {
        setError(key ? `${key}.${fieldName}` : fieldName, info.join("\n"));
      } else if (isObject(info)) {
        process(info, fieldName);
      }
    });
  };

  process(errorsData);
}

export function getShallowDiff(obj1: any, obj2: any) {
  return Object.entries(obj1).reduce((prev: any, [key, value]) => {
    if (value !== obj2[key]) {
      prev[key] = value;
    }

    return prev;
  }, {});
}

export const getWebsiteAddress = (website: string | undefined) => {
  return website?.match(/https:\/\/|http:\/\//g)
    ? website
    : `https://${website}`;
};

export const sortItems = <T = { [key: string]: any }>(
  array: T[],
  key: keyof T
) =>
  array.sort((a, b) => {
    if (a[key] && b[key]) {
      if (a[key] > b[key]) {
        return 1;
      }

      if (a[key] < b[key]) {
        return -1;
      }
    }

    return 0;
  });

export function isDateNotInPast(value: string) {
  const today = new Date();
  const [month, year] = value.split("/");
  const expireDate = new Date();
  expireDate.setFullYear(+year + 2000, +month - 1, 1);

  return today.getTime() < expireDate.getTime();
}

export function getDeepDiff(obj1: any, obj2: any) {
  if (!obj1 || !obj2) {
    return undefined;
  }
  return Object.entries(obj1).reduce((prev: any, [key, value]) => {
    if (Array.isArray(value)) {
      prev[key] = value;
    } else if (typeof value === "object" && !(value instanceof Date)) {
      prev[key] = getDeepDiff(value, obj2[key]);
    } else if (value !== obj2[key]) {
      prev[key] = value;
    }

    return prev;
  }, {});
}

export function testResultLevelToColor(level: number, totalLevels: number) {
  const greenHue = 166;
  const redHue = 11;
  const ratio = (level - 1) / (totalLevels - 1);
  const hue = Math.pow(ratio, 0.4) * (redHue - greenHue) + greenHue;

  return `hsl(${hue}, 81%, 60%)`;
}

export function formatPriceRange(
  min: number | null | undefined,
  max: number | null | undefined
) {
  if (!min) {
    return max === null ? null : `Up to ${formatCurrency(max)}`;
  }

  if (!max) {
    return min ? `From ${formatCurrency(min)}` : null;
  }

  return `${formatCurrency(min)} - ${formatCurrency(max)}`;
}

export const clearCredentialFields = (key: string) => {
  const fieldsToBecomeNull: TherapistFields = [
    "license_expiration",
    "supervisor_license_expiration",
    "year_graduated",
    "main_other_credential_for_no_license",
  ];

  return (fieldsToBecomeNull as string[]).includes(key) ? null : "";
};

export const transformNestedCredentialFields = (key: string, value: any) => {
  if (key === "main_other_credential_for_no_license") {
    const credentials = value as OtherCredentials | null;
    if (!credentials?.type) {
      return null;
    }

    return {
      type: credentials.type,
      year: credentials.year || null,
      [ORGANIZATION_FIELD[credentials.type]]:
        credentials[ORGANIZATION_FIELD[credentials.type]] || "",
      [NUMBER_FIELD[credentials.type]]:
        credentials[NUMBER_FIELD[credentials.type]] || "",
    };
  }

  return value;
};

export const catchFormErrors = <T extends FieldValues>(
  error: AxiosResponse,
  setError: UseFormSetError<T>
) => {
  const { data, status } = error;
  if (status === 400) {
    goThroughErrors(data, (fieldName, errorMessage) =>
      setError(fieldName as Path<T>, { message: errorMessage, type: "custom" })
    );
  }
};

export const withAllObjectFields = <K extends object>(
  currentObject: K,
  ignoreFields?: (keyof K)[]
) => {
  const newObject = { ...currentObject };
  if (ignoreFields && ignoreFields.length) {
    ignoreFields.map((field) => delete newObject[field]);
  }

  return Object.values(newObject).every((value) => {
    if (value === null || value === undefined || value === "") {
      return false;
    }
    return true;
  });
};

import girlPath from "assets/images/peoples/therapist-landing.png";
import Button from "common/UIKit/Button";
import styles from "./WhereSection.module.scss";

export const WhereSection = () => {
  return (
    <section className={styles.root}>
      <h3 className={styles.title}>Where therapy meets affordability</h3>
      <p className={styles.lead}>
        We believe that cost should never hold you back from better mental
        health.
      </p>
      <div className={styles.inner}>
        <img className={styles.image} src={girlPath} alt="Girl with phone" />
        <div className={styles.column}>
          <h6 className={styles.columnTitle}>Save Money</h6>
          <p className={styles.columnText}>
            Unlike other platforms, we never require subscriptions to connect to
            a therapist. You set your session budget, or filter by providers who
            accept your insurance.
          </p>
          <h6 className={styles.columnTitle}>Easier Therapy Access</h6>
          <p className={styles.columnText}>
            Ditch the scheduling waiting game and contact therapists directly.
            You’ll even have the option to speak to one immediately.
          </p>
          <h6 className={styles.columnTitle}>Your Choice</h6>
          <p className={styles.columnText}>
            View our recommended therapist matches and choose your therapist.
            Since you pay only for the therapy you use, you can decide how and
            when to meet with your provider.
          </p>
          <Button className={styles.button} isLink to="/patient/login" size="lg">
            Get started
          </Button>
        </div>
      </div>
    </section>
  );
};

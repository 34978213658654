import React from "react";
import {
  LocalVideoTrack,
  Participant,
  RemoteVideoTrack,
} from "twilio-video";

import useIsTrackSwitchedOff from "hooks/useIsTrackSwitchedOff";
import usePublications from "hooks/usePublications";
import useTrack from "hooks/useTrack";
import useVideoContext from "hooks/useVideoContext";
import VideoAvatarIcon from "icons/VideoAvatarIcon";
import styles from "./MainParticipantInfo.module.scss";

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({
  participant,
  children,
}: MainParticipantInfoProps) {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const isLocal = localParticipant === participant;

  const publications = usePublications(participant);
  const videoPublication = publications.find(
    (p) => !p.trackName.includes("screen") && p.kind === "video"
  );
  const screenSharePublication = publications.find((p) =>
    p.trackName.includes("screen")
  );

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  return (
    <div
      className={styles.mainParticipantInfo}
      data-cy-main-participant
      data-cy-participant={participant.identity}
    >
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <VideoAvatarIcon />
      )}
      {children}
    </div>
  );
}

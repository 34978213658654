import {
  ChangeEventHandler,
  FocusEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { mapOptions } from "constants/googleMap";
import { useResponsive } from "hooks/useResponsive";
import { useJsApiLoader } from "@react-google-maps/api";
import { isEmpty } from "lodash";

type Option = {
  id: string;
  text: string;
};

const removeCountryName = (string: string) => string.replace(", USA", "");

export const useInputSmartSearch = (
  propValue?: string,
  onSelectPlace?: (args: {
    search?: string;
    city?: string;
    state?: string;
    zip?: string;
    address?: string;
    latitude?: number;
    longitude?: number;
  }) => void,
  types: string[] = [
    "postal_code",
    "locality",
    "administrative_area_level_1",
    "administrative_area_level_3",
  ]
) => {
  const { isMobile } = useResponsive();
  const [options, setOptions] = useState<Option[]>([]);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [maxOptionsHeight, setMaxOptionsHeight] = useState<number>();
  const [value, setValue] = useState("");
  const [isSelectOption, setIsSelectOption] = useState(false);
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const autocomplete = useRef<google.maps.places.AutocompleteService>();
  const geocoder = useRef<google.maps.Geocoder>();

  const { isLoaded } = useJsApiLoader(mapOptions);

  useEffect(() => {
    setValue(propValue || "");
  }, [propValue]);

  useLayoutEffect(() => {
    if (optionsContainerRef.current) {
      const containerRect = optionsContainerRef.current.getBoundingClientRect();
      const bodyHeight = document.body.offsetHeight;
      const optionsContainerTop = window.scrollY + containerRect.top;

      setMaxOptionsHeight(
        isMobile
          ? undefined
          : document.body.getBoundingClientRect().bottom -
              optionsContainerTop -
              50
      );

      const bottomPadding = 20;
      const newBodyHeight =
        window.scrollY + containerRect.bottom + bottomPadding;
      if (isMobile && bodyHeight < newBodyHeight) {
        document.body.style.height = `${newBodyHeight}px`;
      }

      const newScrollHeight =
        window.scrollY + containerRect.bottom - window.innerHeight;
      if (containerRect.bottom > window.innerHeight) {
        window.scrollTo(0, newScrollHeight);
      }
    }
    //eslint-disable-next-line
  }, [options, isOptionsVisible]);

  useEffect(() => {
    try {
      if (
        window.google &&
        window.google.maps.places.PlacesServiceStatus.OK === "OK"
      ) {
        autocomplete.current =
          new window.google.maps.places.AutocompleteService();
        geocoder.current = new window.google.maps.Geocoder();
      }
    } catch (error) {}
  }, [isLoaded]);

  const fetchOptions = useCallback(async (input: string) => {
    if (autocomplete.current && input.length > 1) {
      const results = await autocomplete.current.getPlacePredictions({
        input,
        types: [...types],
        componentRestrictions: { country: "us" },
      });

      setOptions(
        results.predictions.map((place) => ({
          id: place.place_id,
          text: removeCountryName(place.description),
        }))
      );
    } else {
      setOptions([]);
    }
    //eslint-disable-next-line
  }, []);

  const handleSelect = useCallback(async (placeId: string) => {
    setIsSelectOption(true);
    if (geocoder.current) {
      const response = await geocoder.current.geocode({
        placeId: placeId,
      });

      const result = response.results[0];

      let city: google.maps.GeocoderAddressComponent | undefined;
      let state: google.maps.GeocoderAddressComponent | undefined;
      let zip: google.maps.GeocoderAddressComponent | undefined;
      let foundLatitude: number;
      let foundLongitude: number;
	  let cityFound = false;
	  let stateFound = false;
	  let zipFound = false;
	   
      result.address_components.forEach((component) => {
        if (!cityFound &&(
          component.types.includes("administrative_area_level_3") ||
          component.types.includes("locality"))
        )
		{
          city = component;
		  cityFound =true;
		}
        if (!stateFound && component.types.includes("administrative_area_level_1"))
          {
			state = component;
			stateFound = true;
		}
        if (!zipFound &&  component.types.includes("postal_code")) 
		{

			zip = component;
			zipFound = true;
		}
 
      });

      const addressWithoutCountry = removeCountryName(result.formatted_address);

      const street = result.address_components.find((component) =>
        component.types.includes("route")
      )?.long_name;
      const geometry = result.geometry;
      const streetNumber = result.address_components.find((component) =>
        component.types.includes("street_number")
      )?.long_name;
      const subpremise = result.address_components.find((component) =>
        component.types.includes("subpremise")
      )?.long_name;
      const house = [subpremise, streetNumber].filter(Boolean).join("/");
      setValue(addressWithoutCountry);
      setIsOptionsVisible(false);
	  foundLatitude = geometry?.location?.lat() ? geometry?.location?.lat() : 0;
	  foundLongitude = geometry?.location?.lng() ? geometry?.location?.lng() : 0;
      if (onSelectPlace) {
        onSelectPlace({
          search: addressWithoutCountry,
          city: city?.long_name,
          state: state?.short_name,
          zip: zip?.long_name,
          address: [house, street].filter(Boolean).join(" "),
          latitude: foundLatitude,
          longitude: foundLongitude,
        });
      }
    }
    //eslint-disable-next-line
  }, []);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.currentTarget;
    if (!isEmpty(value)) {
      setIsSelectOption(false);
      setValue(value);
      fetchOptions(value);
    } else if (onSelectPlace) {
      setIsSelectOption(false);
      setValue("");

      onSelectPlace({ search: "", city: "", state: "", zip: "", latitude: 0 , longitude: 0});
    }
  };

  const handleFocus: FocusEventHandler = () => {
    setIsOptionsVisible(true);
  };

  const handleBlur: FocusEventHandler = () => {
    setIsOptionsVisible(false);
    document.body.style.height = "auto";
  };

  return {
    value,
    options,
    isLoaded,
    isOptionsVisible,
    optionsContainerRef,
    maxOptionsHeight,
    handleSelect,
    handleChange,
    handleFocus,
    handleBlur,
    isSelectOption,
  };
};

import { FC } from "react";
import classNames from "classnames";

import Button from "common/UIKit/Button/Button";
import { isChatAccess } from "services/authService";
import { BaseTherapistItem } from "types/therapist.types";

import { FormatProfileField } from "../FormatProfileField";
import { ReactComponent as ChatIcon } from "./images/chat.svg";
import styles from "./MessageButton.module.scss";

import { therapistName } from "common/utils";

interface Props {
  className?: string;
  onClick?: () => void;
  therapist: BaseTherapistItem;
  withStatus: boolean;
}

export const MessageButton: FC<Props> = ({
  className,
  onClick,
  therapist,
  withStatus,
}) => {
  return (
    <Button
      onClick={onClick}
      className={classNames(
        styles.root,
        className,
        isChatAccess(therapist) && styles.premium,
        isChatAccess(therapist) && styles[therapist.chat_status]
      )}
      size="lg"
    >
      <ChatIcon className={styles.icon} />
      <FormatProfileField maxLength={30}>
        {withStatus && !therapist.chat_status.match("offline")
          ? "Chat Connect"
          : `Email ${therapistName({
              title: therapist.title,
              last_name: therapist.last_name,
            })}`}
      </FormatProfileField>
    </Button>
  );
};

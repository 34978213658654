import React, { forwardRef } from "react";
import cn from "classnames";

import "./Checkbox.scss";

type Props = Omit<React.HTMLProps<HTMLInputElement>, "size" | "children"> & {
  size?: "sm" | "lg";
  label?: React.ReactNode;
  children?: React.ReactNode;
  error?: string;
  type?: "checkbox" | "radio";
  styleVersion?: "bordered-block";
  topText?: string;
};

const Checkbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    onChange,
    id,
    size,
    name,
    className,
    disabled,
    checked,
    children,
    error,
    label,
    type = "checkbox",
    styleVersion,
    topText,
  } = props;

  return (
    <label
      className={cn(
        "checkbox",
        { [`${size}`]: size },
        { [`${styleVersion}`]: styleVersion },
        { error: error },
        className
      )}
    >
      <input
        ref={ref}
        className="checkbox__input"
        id={id}
        onChange={onChange}
        type={type}
        name={name}
        disabled={disabled}
        checked={checked}
      />
      <span className="checkbox__block">
        {topText && styleVersion === "bordered-block" && (
          <span className="checkbox__top-text">{topText}</span>
        )}
        <span className="checkbox__checkmark"></span>
        <span className="checkbox__text">{children || label}</span>
      </span>
    </label>
  );
});

export default Checkbox;

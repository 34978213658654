import { ArticlesLayout } from "./ArticlesLayout";
import React, { useEffect, useState } from "react";
import { Slider } from "common/components/Slider/Slider";
import styles from "./Articles.module.scss";
import { ArticlesSliderCard } from "./components/ArticlesSliderCard/ArticlesSliderCard";
import { articlesService } from "../../services/articlesService";
import { useSearchParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ArticleCard } from "./components/ArticleCard/ArticleCard";
import { Pagination } from "common/UIKit/Pagination";
import { LoadingHOC } from "common/components/LoadingHOC";
import { ReactComponent as DownDateSortArrow } from "assets/images/icons/arrow-down-sort.svg";
import { ReactComponent as UpDateSortArrow } from "assets/images/icons/arrow-up-sort.svg";
import { ReactComponent as DownWordSortArrow } from "assets/images/icons/arrow-down-sort-word.svg";
import { ReactComponent as UpWordSortArrow } from "assets/images/icons/arrow-up-sort-word.svg";
import cn from "classnames";
import Button from "common/UIKit/Button";

export const articlesPath = "/articles";

const sorting = [
  {
    name: "Newest",
    icon: <DownDateSortArrow />,
    ordering: "-published_at",
  },
  {
    name: "Oldest",
    icon: <UpDateSortArrow />,
    ordering: "published_at",
  },
  {
    name: "Z-A",
    icon: <UpWordSortArrow />,
    ordering: "-title",
  },
  {
    name: "A-Z",
    icon: <DownWordSortArrow />,
    ordering: "title",
  },
];

const Articles = () => {
  const {
    articles,
    featuredArticles,
    totalCount,
    pageSize,
    currentPage,
    isFetching,
    isInitialized,
    currentCategoryTitle,
  } = useSnapshot(articlesService);
  const sliderNodes = featuredArticles.map((item) => (
    <ArticlesSliderCard {...item} />
  ));
  const [searchParams, setSearchParams] = useSearchParams();
  const pageCount = Math.ceil(Number(totalCount) / pageSize);
  const [sortType, setSortType] = useState<any>(
    searchParams.get("ordering") || "-published_at"
  );
  const [category, setCategory] = useState<any>(
    searchParams.get("category") || ""
  );

  useEffect(() => {
    if (!articles.length) {
      articlesService.list(searchParams);
    }
    articlesService.listFeatured();
  }, []);

  window.onpopstate = () => {
    if (!window.location.search) {
      return (window.location.href = "/");
    }
  };

  useEffect(() => {
    if (!isInitialized) {
      setSearchParams(new URLSearchParams(articlesService.getSearchParams()));
    }
  }, [currentPage, sortType, setSearchParams, isInitialized, category]);

  useEffect(() => {
    articlesService.setOrdering(sortType);
  }, [sortType]);

  useEffect(() => {
    articlesService.setCurrentCategory(category);
  }, [category]);

  return (
    <LoadingHOC
      isLoading={isFetching}
      className={styles.listingCatalog}
      text="Searching for articles..."
    >
      <ArticlesLayout>
        {featuredArticles.length > 0 && (
          <section className={styles.sliderSection}>
            <Slider
              className={styles.slider}
              nodes={sliderNodes}
              slidesPerView={1}
              sliderClassname={styles.swiper}
              isPagination={featuredArticles.length !== 1}
            />
          </section>
        )}
        <Button
          onClick={() => articlesService.clearFilters()}
          className={styles.button}
          size="lg"
        >
          All Articles
        </Button>
        <section className={styles.articlesSection}>
          <h3>
            {currentCategoryTitle
              ? `Last articles in ${currentCategoryTitle}`
              : "Last articles"}
          </h3>
          <div className={styles.sorting}>
            <div className={styles.typeSort}>
              {sorting.map((el) => {
                return (
                  <div
                    className={cn(
                      styles.sortingButton,
                      styles.lowOpacity,
                      sortType === el.ordering && styles.chosen
                    )}
                    onClick={() => setSortType(el.ordering)}
                  >
                    {el.icon}
                    <span>{el.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.cards}>
            {articles.map((item) => {
              return (
                <ArticleCard
                  src={item.files[0]?.file}
                  title={item.title}
                  date={item.published_at}
                  id={item.id}
                  categories={item.categories}
                  author_name={item.author_name}
                  setCategory={setCategory}
                />
              );
            })}
          </div>
          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            goTo={(page) => articlesService.setCurrentPage(page)}
          />
        </section>
      </ArticlesLayout>
    </LoadingHOC>
  );
};

export default Articles;

import Button from "common/UIKit/Button";
import { ReactComponent as LineIcon } from "assets/images/icons/line-through.svg";
import { useState } from "react";
import styles from "./TherapistRateCard.module.scss";

interface Props {
  name?: string;
  description: string;
  oldPrice: string | boolean;
  newPrice: string;
  onClick?: () => void;
  isDisabled?: boolean;
  withoutPayment?: boolean;
  isLoading?: boolean;
}

export const TherapistRateCard = ({
  name,
  description,
  newPrice,
  oldPrice,
  onClick,
  isDisabled,
  withoutPayment,
  isLoading,
}: Props) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <p className={styles.title}>{name}</p>
        <p className={styles.text}>
          {description.length > 99 ? (
            <>
              {showMore ? description : `${description.substring(0, 100)}...`}{" "}
              <span
                className="g-underline"
                onClick={() => setShowMore((prevState) => !prevState)}
              >
                Show {showMore ? "less" : "more"}
              </span>
            </>
          ) : (
            description
          )}
        </p>
      </div>
      <div className={styles.inner}>
        <div className={styles.row}>
          <div className={styles.oldPrice}>
            {oldPrice && (
              <>
                <LineIcon className={styles.line} />
                <span className={styles.symbol}>$</span>
                <span>{Number(oldPrice).toFixed(0)}.</span>
                <span className={styles.cents}>{String(oldPrice).slice(-2)}</span>
              </>
            )}
          </div>
          <div className={styles.newPrice}>
            <span className={styles.symbol}>$</span>
            <span>{Number(newPrice).toFixed(0)}.</span>
            <span className={styles.cents}>{newPrice.slice(-2)}</span>
          </div>
        </div>
        <Button
          className={styles.button}
          onClick={onClick}
          disabled={isDisabled}
          isLoading={isLoading}
          size="lg"
        >
          Buy
        </Button>
        {withoutPayment && (
          <p className={styles.warning}>
            Therapist has not yet set up payment options
          </p>
        )}
      </div>
    </div>
  );
};

import { FC, ReactNode, useCallback, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { tokenService } from "services/tokenService";
import { modalService } from "services/modalService";
import { Preloader } from "common/UIKit/Preloader";
import { useAuth } from "hooks/useAuth";
import { verificationPath } from "pages/VerificationSent";
import { step1FullPath as patientStep1FullPath } from "pages/Patients/PatientSteps/PatientStep1";
import { congratulationsFullPath } from "pages/Therapists/Congratulations";
import { isPatient, isTherapist, User } from "services/authService";
import { UserAuthStatus } from "types/auth.types";
import { patientsDashboardMainPage } from "pages/Patients/PatientsDashboard";
import { patientsLandingMainPage } from "pages/Patients/PatientsDashboard/PatientsLanding";

import { rateCardsService } from "services/rateCardsService";
import { onRequestBuyRateCard } from "../../utils/async";
import { confirmPhonePath } from "pages/ConfirmPhone/ConfirmPhone";
import { esaStep1FullPath } from "pages/Patients/PatientSteps/EsaStep1";

type AccessPermission = "allowed" | "not_allowed";
type UserAccessPermission = AccessPermission | "registration" | "verification" ;

type Props = {
  therapist?: UserAccessPermission;
  patient?: UserAccessPermission;
  unauthorized?: AccessPermission;
};

const REDIRECT_TO_KEY = "redirect_to";

const userAccessControll = ({
  authStatus,
  user,
  permission,
  children,
  homePath,
  stepsPath,
}: {
  authStatus: UserAuthStatus;
  user: User;
  permission?: UserAccessPermission;
  children: ReactNode;
  homePath: string;
  stepsPath: string;
}) => {
  if (authStatus === "loggedIn") {
    if (user.user.status === "completed") {
      const redirectTo = localStorage.getItem(REDIRECT_TO_KEY);
      const linkForTests = localStorage.getItem("redirectForBuyTest");

      if (redirectTo) {
        setTimeout(() => {
          localStorage.removeItem(REDIRECT_TO_KEY);
        });

        return <Navigate to={redirectTo}/>;
      }

      const buyRateCardData = localStorage.getItem("buyRateCardData");
      const parsedRateCardData = buyRateCardData && JSON.parse(buyRateCardData);

      if (buyRateCardData && user.user.patient) {
        onRequestBuyRateCard(
            rateCardsService.buyRate(
                parsedRateCardData?.therapist,
                parsedRateCardData?.id
            )
        ).then((url) => {
          if (typeof url === "string") {
            window.location.href = url;
          }
        })
      }

      if (!buyRateCardData || user.user.therapist) {
        if (permission === "allowed") {
          return <>{children}</>;
        } else {
          if (!linkForTests) {
            return <Navigate to={homePath}/>;
          }
        }
      }
    }

    if (user.user.status === "registration") {
      if (permission === "registration") {
        return <>{children}</>;
      } else if (user.user.is_esa_user) {
        return <Navigate to={esaStep1FullPath}/>
      } else {
        return <Navigate to={stepsPath} />;
      }
    }
  }

  return <></>;
};

export const AccessController: FC<Props> = ({
  therapist,
  patient,
  unauthorized,
  children,
}) => {
  const { authStatus, user, baseUser, isProfileCompleted } = useAuth();
  const location = useLocation();
  const linkForTests = localStorage.getItem("redirectForBuyTest");
  const verify = useCallback(() => {
    if (
      !tokenService.get() &&
      !location.pathname.includes("/login") &&
      !location.pathname.includes("/signup") &&
      unauthorized !== "allowed"
    ) {
      modalService.open("LOGGED_OUT");
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener("focus", verify);

    return () => window.removeEventListener("focus", verify);
  }, [verify]);

  if (authStatus === "unknown" && location.pathname.includes("patient") && !therapist && unauthorized === "allowed") {
      return <Navigate to="/patient/login" />
  }

  if (authStatus === "unknown" || authStatus === "processing") {
    return <Preloader />;
  }

  if (authStatus === "loggedOut" && linkForTests) {
    if (linkForTests) {
      setTimeout(() => {
        localStorage.removeItem("redirectForBuyTest")
      },0)
      localStorage.setItem("redirect_to", "/patients/dashboard/tests?tab_index=0")
      return <Navigate to={`/patient/login?${linkForTests}`} />;
    }
  }

  if (authStatus === "loggedOut" && unauthorized === "allowed") {
    return <>{children}</>;
  }


  if (authStatus === "logoutToLogin" && unauthorized === "allowed") {
    return <>{children}</>;
  }

  if (authStatus === "logoutToLogin" && unauthorized !== "allowed") {
    return <Navigate to="/patient/login" />
  }

  if (authStatus === "loggedOut" && unauthorized !== "allowed") {
    return <Navigate to="/" />;
  }

  if (user && isTherapist(user)) {
    return userAccessControll({
      authStatus,
      user,
      children,
      permission: therapist,
      homePath: isProfileCompleted ? "/therapists/completed" : "/therapists",
      stepsPath: congratulationsFullPath,
    });
  }

  if (user && isPatient(user)) {
    return userAccessControll({
      authStatus,
      user,
      children,
      permission: patient,
      homePath: isProfileCompleted ? "/patients" : patientsLandingMainPage,
      stepsPath: patientStep1FullPath,
    });
  }

  if (authStatus === "phoneVerification") {
    if (patient === "verification" || therapist === "verification") {
      return <>{children}</>;
    } else {
      return (
          <Navigate
              to={{
                pathname: confirmPhonePath,
                search: `?phone=${baseUser?.mobile_phone}`,
              }}
          />
      );
    }
  }

  if (authStatus === "verification") {
    if (patient === "verification" || therapist === "verification") {
      return <>{children}</>;
    } else {
      return (
        <Navigate
          to={{
            pathname: verificationPath,
            search: `?email=${baseUser?.email}`,
          }}
        />
      );
    }
  }

  return (
    <Navigate
      to={`/${
        therapist === "allowed"
          ? isProfileCompleted
            ? "therapist/completed"
            : "therapist"
          : "patient"
      }/login`}
    />
  );
};

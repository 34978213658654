import { FC, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { authService } from "services/authService";
import { Therapist } from "types/therapist.types";
import { ErrorPage } from "pages/ErrorPage";
import styles from "./VideoQueue.module.scss";
import { ScheduledCall } from "types/clientVideo.types";
import axios from "axios";
import { apiEndpoint, esaApiEndpoint } from "utils/env";
import Button from "common/UIKit/Button";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { therapistsHomePath } from "pages/Therapists/TherapistsHome";
import { therapistVideoLandingPath } from "pages/Therapists/TherapistVideoLanding";

export const videoQueuePath = "/video-queue";

export const VideoQueue: FC = () => {
  // useDashboardNavigation("Video Chat Queue", { isMainTab: true });
  const user = useSnapshot(authService).user as Therapist;
  // useDocumentTitle("Video Chat Queue");
  const [chats, setChats] = useState<ScheduledCall[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${esaApiEndpoint}/therapist/scheduledcalls?id=${user.id}`)
      .then((response) => {
        setChats(response.data.results as ScheduledCall[]);
      });
    // axios
    //   .get(`${esaApiEndpoint}/therapist/scheduledcalls?id=G4jbMjL`)
    //   .then((response) => {
    //     setChats(response.data.results as ScheduledCall[]);
    //   });
  }, [user.id]);

  const viewPatient = (chat: ScheduledCall) => {
    // chat.clientid = 123456;

    navigate(
      `${therapistsHomePath}${therapistVideoLandingPath}?therapistKey=${user.id}&sessionId=${chat.id}&clientId=${chat.clientid}`
    );
  };

  if (!user) return <ErrorPage />;

  return (
    <section className="default-typography">
      <h1 className="h3 fw-medium mb-25 mb-sm-40 text-center text-sm-start">
        Video Chat
      </h1>

      {chats.length > 0 && (
        <ul className={styles.listOfCalls}>
          {chats.map((chat) => (
            <li key={chat.id} className={styles.listOfCalls__item}>
              <div className="row align-items-center flex-sm-nowrap">
                <div className="col py-sm-5">
                  <h6 className="text-lg fw-semibold mb-0">{`${chat.ClientFirstName} ${chat.ClientLastName}`}</h6>
                </div>
                <div className="col-xs-6 col-sm-4 py-sm-5 text-xs-end text-sm-center">
                  <h6 className="text-gray mb-0 fw-medium">
                    {new Date(chat.ScheduledStart).toLocaleDateString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    })}
                  </h6>
                </div>
                <div className="col-sm-auto pt-15 py-sm-5">
                  <Button
                    size="sm"
                    color="green-light"
                    onClick={() => viewPatient(chat)}
                  >
                    <span>View Patient</span>
                    <span className="icomoon-arrow-small-right ps-10"></span>
                  </Button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

import { useNavigate } from "react-router-dom";
import { communicationService } from "services/communicationService";
import { BaseTherapistItem, Therapist } from "types/therapist.types";
import { loginPath } from "pages/commonLinks";

export const useCommunication = () => {
  const navigate = useNavigate();
  const openChantConnect = async (therapist: BaseTherapistItem | Therapist) => {
    try {
      await communicationService.openPopup(therapist);
    } catch (error) {
      navigate(`/patient${loginPath}`);
    }
  };

  return { openChantConnect };
};

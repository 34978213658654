import { AxiosResponse } from "axios";
import { FormatProfileField } from "common/components/FormatProfileField";
import { Preloader } from "common/UIKit/Preloader";
import { formatPhone, therapistName } from "common/utils";
import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { ErrorPage } from "pages/ErrorPage";
import { VerifiedCircle } from "pages/Patients/PatientsTherapist/blocks/VerifiedCircle/VerifiedCircle";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { therapistProfileService } from "services/therapistProfileService";
import { useSnapshot } from "valtio";
import { ReactComponent as MarkerIcon } from "assets/images/icons/map-marker-fill.svg";
import { ReactComponent as PhoneIcon } from "assets/images/icons/phone.svg";
import { ReactComponent as ChatIcon } from "assets/images/icons/comment-dots.svg";
import cn from "classnames";
import Button from "common/UIKit/Button";
import { Avatar } from "common/UIKit/Avatar";
import { TherapistRateCard } from "./components/TherapistRateCard/TherapistRateCard";
import { rateCardsService } from "services/rateCardsService";
import { communicationService } from "services/communicationService";
import { useAuth } from "hooks/useAuth";
import { isChatAccess, isTherapist } from "services/authService";
import { getSale } from "common/utils/calculations";
import { MessagePopup } from "common/components/MessagePopup";
import { stripeStatus } from "services/authService";
import { useModal } from "hooks/useModal";
import { useResponsive } from "hooks/useResponsive";
import { onRequestToNewWindow } from "common/utils/async";
import styles from "./TherapistRate.module.scss";
import { openMessageModalAfterLogin } from "utils/utils";

export const therapistRatePath = "/therapist-rate";

export const TherapistRate = () => {
  const { isMobile } = useResponsive();
  const [showMore, setShowMore] = useState(false);
  const [withError, setWithError] = useState(false);
  const { id } = useParams();
  const { currentProfile, isFetching } = useSnapshot(therapistProfileService);
  const { data, buyIsFetched } = useSnapshot(rateCardsService);
  const buyRateCardModal = useModal("LOGGED_OUT_AND_BUY_RATECARD");
  const therapistBuyRateCardModal = useModal(
    "LOGGED_AS_THERAPIST_BUY_RATE_CARD"
  );
  const { user } = useAuth();
  const professionalSpecialtyNames = useDictionaryItemNames(
    "ProfessionalSpecialties",
    currentProfile?.professional_specialties
  );
  useDocumentTitle(
    `${therapistName({
      first_name: currentProfile?.user.first_name,
      last_name: currentProfile?.user.last_name,
    })}`
  );
  const isPhoneShown =
    !currentProfile?.subscription_plan.chat_access ||
    !currentProfile?.user?.hide_mobile_phone_number;
  const phoneToDisplay =
    currentProfile?.user.mobile_phone || currentProfile?.phone;

  const withStatus =
    currentProfile &&
    isChatAccess(currentProfile) &&
    currentProfile?.online_chat_sessions_availability;

  const { open } = useModal("LOGGED_AS_THERAPIST");

  useEffect(() => {
    if (id) {
      therapistProfileService
        .getById(id)
        .then((res) => setWithError(false))
        .catch((err: AxiosResponse) => {
          if (err.status === 401) {
            setWithError(true);
          }

          err.data.detail as string | undefined;

          err.data.detail.match("Not found") && setWithError(true);
        });
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      rateCardsService.list(id, undefined, false);
    }
  }, [id]);

  const openMessage = () =>
    user && isTherapist(user)
      ? open()
      : currentProfile
      ? communicationService.openPopup(currentProfile)
      : null;

  useEffect(() => {
    openMessageModalAfterLogin(currentProfile);
  }, []);

  if (withError) return <ErrorPage />;

  if (isFetching) return <Preloader />;

  return (
    <div className={styles.root}>
      <main className={styles.main}>
        <div className={styles.wrapper}>
          <Avatar
            isVerified={
              isMobile && currentProfile?.primary_credential_is_verified
            }
            className={styles.avatar}
            alt={therapistName({
              first_name: currentProfile?.user.first_name,
              last_name: currentProfile?.user.last_name,
            })}
            file={currentProfile?.avatar}
            withoutLink={true}
          />
          <div className={styles.info}>
            <div className={styles.mobileRow}>
              <h1 className={styles.title}>
                <FormatProfileField maxLength={30}>
                  {therapistName({
                    ...currentProfile?.user,
                    ...currentProfile,
                  })}
                </FormatProfileField>
                {!isMobile &&
                  currentProfile?.primary_credential_is_verified && (
                    <VerifiedCircle className={styles.verified} />
                  )}
              </h1>
              <p className={styles.description}>
                {currentProfile?.personal_statement_short_description}
              </p>
              <button
                type="button"
                onClick={() => setShowMore((prevState) => !prevState)}
                className={styles.showButton}
              >
                Show {showMore ? "less" : "more"}
              </button>
            </div>
            {showMore && (
              <>
                {currentProfile?.source.includes("imported") &&
                  professionalSpecialtyNames.length > 0 && (
                    <FormatProfileField
                      fallbackText="No Educational Specialties specified"
                      shouldLimitLength={false}
                      className={styles.specialties}
                    >
                      {professionalSpecialtyNames.join(", ")}
                    </FormatProfileField>
                  )}
                {!currentProfile?.hide_personal_address && (
                  <div className={styles.row}>
                    {(currentProfile?.city ||
                      currentProfile?.state ||
                      currentProfile?.zip_code) && (
                      <div className={styles.address}>
                        <MarkerIcon className={styles.addressIcon} />
                        <FormatProfileField
                          className={styles.addressText}
                          maxLength={50}
                        >
                          {`${
                            currentProfile.city
                              ? `${currentProfile.city}, `
                              : ""
                          }${currentProfile.state} ${currentProfile.zip_code}`}
                        </FormatProfileField>
                      </div>
                    )}
                  </div>
                )}
                <div className={styles.phoneRow}>
                  {!isPhoneShown && (
                    <a
                      href={phoneToDisplay ? `tel:${phoneToDisplay}` : ""}
                      className={cn(
                        styles.phone,
                        !phoneToDisplay && "disabled"
                      )}
                    >
                      <span className={styles.phoneIcon}>
                        <PhoneIcon />
                      </span>
                      <span className={styles.phoneText}>
                        <span>{formatPhone(phoneToDisplay)}</span>
                      </span>
                    </a>
                  )}
                  {currentProfile && isChatAccess(currentProfile) && (
                    <Button
                      className={cn(styles.button, styles.premium, {
                        [styles[currentProfile.chat_status]]: withStatus,
                      })}
                      onClick={openMessage}
                      size="lg"
                    >
                      <ChatIcon />
                      <FormatProfileField maxLength={30}>
                        {withStatus &&
                        !currentProfile.chat_status.match("offline")
                          ? "Chat Connect"
                          : `Email ${therapistName({
                              title: currentProfile?.title,
                              last_name: currentProfile?.user.last_name,
                            })}`}
                      </FormatProfileField>
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.cardRow}>
          {data.length > 0 &&
            data.map((item) => (
              <TherapistRateCard
                key={item.id}
                name={item?.public_name}
                description={item.description}
                newPrice={Number(Number(item.fee)).toFixed(2)}
                oldPrice={
                  !!currentProfile?.online_individual_session_cost_min &&
                  getSale(
                    item.get_free_count,
                    item.pay_sessions_count,
                    currentProfile.online_individual_session_cost_min
                  )
                }
                onClick={
                  !user
                    ? () => {
                        localStorage.setItem(
                          "buyRateCardData",
                          JSON.stringify({
                            therapist: item.therapist,
                            id: item.id,
                          })
                        );
                        buyRateCardModal.open();
                      }
                    : (user && user.id === id) || (user && isTherapist(user))
                    ? () => {
                        localStorage.setItem(
                          "buyRateCardData",
                          JSON.stringify({
                            therapist: item.therapist,
                            id: item.id,
                          })
                        );
                        therapistBuyRateCardModal.open();
                      }
                    : () =>
                        item.therapist &&
                        onRequestToNewWindow(
                          rateCardsService.buyRate(item.therapist, item.id)
                        )
                }
                isDisabled={
                  !!currentProfile && !stripeStatus(currentProfile, "completed")
                }
                withoutPayment={
                  !!currentProfile && !stripeStatus(currentProfile, "completed")
                }
                isLoading={buyIsFetched}
              />
            ))}
        </div>
      </main>
      <MessagePopup />
    </div>
  );
};

import { ReactNode } from "react";
import Layout from "common/components/Layout/Layout";

export const PatientStepsLayout = ({ children }: { children: ReactNode } ) => (
  <Layout>
    <section className="patient-step">
      <div className="container-normal" style={{ maxWidth: "100%" }}>
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-9 col-lg-8 col-xl-7">
            { children }
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
import { FC } from "react";
import { ReactComponent as ClockIcon } from "assets/images/icons/transparent-clock.svg";
import { ReactComponent as ChevronRightIcon } from "assets/images/icons/chevron-right.svg";
import { Scale } from "common/UIKit/Scale";
import Button from "common/UIKit/Button/Button";
import styles from "./CongratulationsListItem.module.scss";

interface Props {
  value: number;
  title: string;
  time: string;
  to: string;
}

export const CongratulationsListItem: FC<Props> = ({
  value,
  time,
  title,
  to,
}) => {
  return (
    <li className={styles.root}>
      <div className={styles.column}>
        <p className={styles.title}>{title}</p>
        <time className={styles.time}>
          <ClockIcon className={styles.icon} />
          {time}
        </time>
        <Scale className={styles.progress} scaleValue={value} />
      </div>
      <Button className={styles.button} isLink to={to} size="lg">
        <ChevronRightIcon />
      </Button>
    </li>
  );
};

import { Modal } from "../Modal/Modal";
import { useModalDrag } from "hooks/useModalDrag";
import { useModal } from "hooks/useModal";
import cn from "classnames";
import { useSnapshot } from "valtio";
import { testAttemptListService } from "services/testAttemptListService";
import { ReactComponent as IconCross } from "assets/images/icons/green-cross.svg";
import { useLocation } from "react-router-dom";
import { testPsychologyListService } from "services/testPsychologyListService";
import { useEffect } from "react";
import { testProgressListService } from "services/testProgressListService";
import styles from "./FilterPopup.module.scss";

export const FilterTestsPopup = () => {
  const { search } = useLocation();
  const isAvailable = search.match("tab_index=1");
  const isProgress = search.match("tab_index=2");
  const { isOpen, close } = useModal("FILTER_TESTS");
  const { bind, transformStyle } = useModalDrag({
    moveDistanceToClose: 300,
    onClose: close,
  });
  const { sort: attemptSort } = useSnapshot(testAttemptListService);
  const { sort: psychologySort } = useSnapshot(testPsychologyListService);
  const { sort: progressSort } = useSnapshot(testProgressListService);
  const sortName = isAvailable
    ? psychologySort?.name
    : isProgress
    ? progressSort?.name
    : attemptSort?.name;
  const sortDate = isAvailable
    ? psychologySort?.time_to_complete
    : isProgress
    ? progressSort?.started_at
    : attemptSort?.finished_at;

  const onFilter = (filter: string, order: "asc" | "desc") => {
    if (isAvailable) {
      return testPsychologyListService.setOrder(filter, { order }, true);
    }

    if (isProgress) {
      return testProgressListService.setOrder(filter, { order }, true);
    }

    return testAttemptListService.setOrder(filter, { order }, true);
  };

  const onClear = (filter: string) => {
    if (isAvailable) {
      return testPsychologyListService.clearOrder(filter);
    }

    if (isProgress) {
      return testProgressListService.clearOrder(filter);
    }

    return testAttemptListService.clearOrder(filter);
  };

  useEffect(() => {
    testAttemptListService.clearAllOrders();
    testPsychologyListService.clearAllOrders();
    testProgressListService.clearAllOrders();
  }, [search]);

  return (
    <Modal visible={isOpen} onClose={close} >
      <div className={styles.root} style={transformStyle} {...bind()}>
        <div className={styles.mobileLine} />
        <h6 className={styles.title}>Sort by:</h6>
        <fieldset className={styles.row}>
          <label className={styles.label}>Name of test:</label>
          <div
            className={cn(
              styles.field,
              sortName?.order === "asc" && styles.active
            )}
          >
            <IconCross onClick={() => onClear("name")} />
            <button
              className={styles.button}
              type="button"
              onClick={() => onFilter("name", "asc")}
            >
              A-Z
            </button>
          </div>
          <div
            className={cn(
              styles.field,
              sortName?.order === "desc" && styles.active
            )}
          >
            <IconCross onClick={() => onClear("name")} />
            <button
              className={styles.button}
              type="button"
              onClick={() => onFilter("name", "desc")}
            >
              Z-A
            </button>
          </div>
        </fieldset>
        <fieldset className={styles.row}>
          <label className={styles.label}>Date taken:</label>
          <div
            className={cn(
              styles.field,
              sortDate?.order === "desc" && styles.active
            )}
          >
            <IconCross
              onClick={() =>
                onClear(
                  isAvailable
                    ? "time_to_complete"
                    : isProgress
                    ? "started_at"
                    : "finished_at"
                )
              }
            />
            <button
              className={styles.button}
              type="button"
              onClick={() =>
                onFilter(
                  isAvailable
                    ? "time_to_complete"
                    : isProgress
                    ? "started_at"
                    : "finished_at",
                  "desc"
                )
              }
            >
              Recent
            </button>
          </div>
          <div
            className={cn(
              styles.field,
              sortDate?.order === "asc" && styles.active
            )}
          >
            <IconCross
              onClick={() =>
                onClear(
                  isAvailable
                    ? "time_to_complete"
                    : isProgress
                    ? "started_at"
                    : "finished_at"
                )
              }
            />
            <button
              className={styles.button}
              type="button"
              onClick={() =>
                onFilter(
                  isAvailable
                    ? "time_to_complete"
                    : isProgress
                    ? "started_at"
                    : "finished_at",
                  "asc"
                )
              }
            >
              Oldest
            </button>
          </div>
        </fieldset>
      </div>
    </Modal>
  );
};

import { ReactNode, useState } from "react";
import cn from "classnames";

import { cropString, isEmpty } from "common/utils";
import styles from "./FormatProfileField.module.scss";
//TODO: add personal statement to format profile field as param so that it can be used in therapist profile without having to use children property
interface Props {
  children?: ReactNode;
  className?: string;
  fallbackText?: string;
  shouldFallback?: boolean;
  maxLength?: number;
  isExpandable?: boolean;
  shouldLimitLength?: boolean;
  wrapBreaksWord?: boolean;
  objectToCheck?: any;
  description?: boolean;
  biography?: string;
}

export const FormatProfileField = ({
  children,
  className,
  fallbackText = "-",
  shouldFallback = true,
  maxLength = 20,
  isExpandable = false,
  shouldLimitLength = true,
  wrapBreaksWord = false,
  objectToCheck = children,
  description = false,
  biography = "",
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (shouldFallback && isEmpty(objectToCheck)) {
    return (
      <span className={cn(className, styles.text, styles.fallback)}>
        {fallbackText}
      </span>
    );
  }

  if (biography) {
    const containsLongWords = /\w{20,}/.test(biography);
    const willCropString = biography.length > maxLength;
    return (
      <span
        className={cn(
          className,
          styles.text,
          biography.length > maxLength && styles.cropped,
          (wrapBreaksWord || containsLongWords) && styles.breakWord
        )}
        title={
          shouldLimitLength && biography.length > maxLength
            ? biography
            : undefined
        }
      >
        {shouldLimitLength && !isExpanded
          ? cropString(biography, {
              maxLength,
              endingIfCropped: isExpandable ? "" : "...",
              description
            })
          : 
          biography.split("\n").map(str => {
            if (str.length > 0) {
              if (str.includes(":")) {
                return <p>{str}</p>
              } else{
                return <><p>{str}</p><br/></>
              }
            }
            })
          }
        {isExpandable && willCropString && (
          <>
            <span
              className={styles.expandButton}
              onClick={() => {
                setIsExpanded((prev) => !prev)
              }}
            >
              {` (${isExpanded ? "less" : "more"}...)`}
            </span>
          </>
        )}
      </span>
    )
  }

  if (typeof objectToCheck === "string") {
    const containsLongWords = /\w{20,}/.test(objectToCheck);
    const willCropString = objectToCheck.length > maxLength;
    return (
      <span
        className={cn(
          className,
          styles.text,
          objectToCheck.length > maxLength && styles.cropped,
          (wrapBreaksWord || containsLongWords) && styles.breakWord
        )}
        title={
          shouldLimitLength && objectToCheck.length > maxLength
            ? objectToCheck
            : undefined
        }
      >
        {shouldLimitLength && !isExpanded
          ? cropString(objectToCheck, {
              maxLength,
              endingIfCropped: isExpandable ? "" : "...",
              description
            })
          : objectToCheck}
        {" "}
        {isExpandable && willCropString && (
          <span
            className={styles.expandButton}
            onClick={() => {
              setIsExpanded((prev) => !prev)
            }}
          >
            {` (${isExpanded ? "less" : "more"}...)`}
          </span>
        )}
      </span>
    );
  }
  return <>{children}</>;
};

import cn from "classnames";
import Button, { ButtonProps } from "common/UIKit/Button/Button";

import styles from "../TherapistSteps.module.scss";

export const TherapistStepsActions = ({
  buttons,
  onSkip,
}: {
  buttons: ButtonProps[];
  onSkip?: () => void;
}) => {
  return (
    <div className={cn(styles.actions)}>
      <div className={styles.buttons}>
        {buttons.map((buttonProps, index) => {
          const { className, ...other } = buttonProps;
          return (
            <Button
              key={index}
              className={cn("therapist-step__btn w-100", className, styles.button)}
              {...other}
              size="lg"
            />
          );
        })}
      </div>
      {onSkip && (
        <span
          onClick={onSkip}
          className="g-underline"
          style={{ cursor: "pointer" }}
        >
          Save and finish later
        </span>
      )}
    </div>
  );
};

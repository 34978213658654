import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "assets/images/icons/edit.svg";
import { ReactComponent as Clock } from "assets/images/icons/time-circle.svg";
import { ReactComponent as Verified } from "assets/images/icons/verified.svg";
import Button from "common/UIKit/Button";
import { useResponsive } from "hooks/useResponsive";
import { Therapist } from "types/therapist.types";
import { getAvatar, therapistName } from "common/utils";
import avatar from "assets/images/avatar.png";
import { FormatProfileField } from "common/components/FormatProfileField";
import styles from "./ProfileCard.module.scss";
import { toast } from "react-toastify";

interface Props {
    user: Therapist;
}

export const ProfileCard = ({ user }: Props) => {
    const navigate = useNavigate();
    const { isMobile } = useResponsive();
    const referralId = user.therapist_key ? user.therapist_key : user.id;

    // const handleFileDelete = async (fileId?: number) => {
    //   if (fileId) {
    //     await filesService.deleteFile(user.id, fileId);
    //     await authService.getCurrentUser();
    //   }
    // };

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(`therapass.com/td/${user.id}`);
            toast("Copied!", { type: "info" });
        } catch (e) {
            toast("Fail to copy!", { type: "error" });
        }
    };

    return (
        <div className={cn({[styles.card]: !isMobile}, styles.profileInfoCard)}>
            <div
                className={cn(
                    styles.avatar,
                    getAvatar(user.photos)?.id && styles.withRemove
                )}
            >
                <div className={styles.image}>
                    <img
                        src={getAvatar(user.photos)?.file || avatar}
                        alt={therapistName({
                            first_name: user.user.first_name,
                            last_name: user.user.last_name,
                        })}
                    />
                    <div
                        className={styles.editIcon}
                        onClick={() => navigate("profile/photos-and-video")}
                    >
                        <Edit/>
                    </div>
                </div>
                {/*{getAvatar(user.photos)?.id && (*/}
                {/*  <button*/}
                {/*    className={styles.removeAvatar}*/}
                {/*    type="button"*/}
                {/*    onClick={() => handleFileDelete(getAvatar(user.photos)?.id)}*/}
                {/*  >*/}
                {/*    Remove photo*/}
                {/*  </button>*/}
                {/*)}*/}
                <div className={styles.info}>
                    <div className={styles.infoWrap}>
                        <h2>
                            <FormatProfileField maxLength={18}>
                                {therapistName({
                                    title: user.title,
                                    first_name: user.user.first_name,
                                    last_name: user.user.last_name,
                                })}
                            </FormatProfileField>
                        </h2>
                        <div
                            className={cn(
                                styles.credentialsStatus,
                                user.primary_credential_is_verified && styles.credentialsVerified
                            )}
                        >
                            {!user.primary_credential_is_verified ?
                                <>
                                    <Clock className={styles.credentialsStatusIcon}/>
                                </>
                                :
                                <>
                                    <Verified className={styles.credentialsStatusIcon}/>
                                </>}
                        </div>
                    </div>
                    <small>
                        {user?.business_info.company_name && (
                            <FormatProfileField maxLength={30}>
                                {user?.business_info.company_name}
                            </FormatProfileField>
                        )}
                    </small>
                </div>
            </div>
            <div className={styles.buttonBlock}>
                <Button
                    text="View Profile"
                    className={styles.viewProfileButton}
                    isLink
                    to={`/td/${user.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="lg"
                />
                <div className={styles.link}>
                    {`therapass.com/td/${referralId}`}
                </div>
                <Button
                    text="Copy Profile URL"
                    className={styles.viewProfileButton}
                    onClick={handleCopyClick}
                    size="lg"
                />
            </div>
        </div>
    );
};

import MainParticipant from "../MainParticipant/MainParticipant";
import ParticipantList from "../ParticipantList/ParticipantList";
import { ParticipantAudioTracks } from "../ParticipantAudioTracks/ParticipantAudioTracks";
import { useStopwatch } from 'react-timer-hook';
import styles from "./Room.module.scss";
import clockIcon from "./clock.svg";

export default function Room() {
  
  const sec = 1;
  let currentTime = 0;

  const updateCurrentTime = () => {
    currentTime += sec;

  }
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    isRunning,
  } = useStopwatch({ autoStart: true });

  setInterval(updateCurrentTime, 1);
  return (
    <>
      {/*
        This ParticipantAudioTracks component will render the audio track for all participants in the room.
        It is in a separate component so that the audio tracks will always be rendered, and that they will never be
        unnecessarily unmounted/mounted as the user switches between Gallery View and speaker View.
      */}
      <ParticipantAudioTracks />
      <MainParticipant />

      <div className={styles.timeCall}>
        <img className={styles.timeCallIcon} src={clockIcon} alt="" />
        <p className={styles.timeCallTime}>
          <span>{hours < 10 ? `0${hours}` :`${hours}`}</span>:
          <span>{minutes < 10 ? `0${minutes}` : `${minutes}`}</span>:
          <span>{seconds < 10 ? `0${seconds}` : `${seconds}`}</span>
        </p>
      </div>
      <ParticipantList />
    </>
  );
}

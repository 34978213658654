import { FC } from "react";
import { useSnapshot } from "valtio";
import cn from "classnames";
import { Link, useNavigate } from "react-router-dom";
import Button from "common/UIKit/Button";
import { Subscription } from "types/subscription.types";
import { Therapist } from "types/therapist.types";
import { subscriptionPlansPath } from "pages/Therapists/SubscriptionPlans";
import { therapistsPath } from "pages/Therapists/index";
import { subscriptionService } from "services/subscriptionService";
import { ChangePlanSuccessModal } from "pages/Therapists/SubscriptionPlans/ChangePlanSuccessModal";
import { ConfirmCancellationModal } from "pages/Therapists/SubscriptionPlans/ConfirmCancellationModal";
import { useAuth } from "hooks/useAuth";
import { useModal } from "hooks/useModal";
import { ReactComponent as Verified } from "assets/images/icons/verified.svg";
import { ReactComponent as PurpleStar } from "assets/images/premium-decoration-right.svg";
import { ReactComponent as PurplePlane } from "assets/images/frames/purple-plane.svg";
import styles from "./PlanCard.module.scss";

interface Props {
  subscription?: Subscription;
}

export const PlanInfoCard: FC<Props> = ({ subscription }) => {
  const navigate = useNavigate();
  const user = useAuth().user as Therapist;
  const { open } = useModal("CANCEL_SUBSCRIPTION_PLAN");
  const { activeSubscription } = useSnapshot(subscriptionService);

  const isFree =
    !Boolean(subscription?.amount) || Number(subscription?.amount) === 0;

  const handleClick = () => {
    navigate(`${therapistsPath}${subscriptionPlansPath}`);
  };

  return (
    <div
      className={cn(
        styles.planCard,
        styles.selected,
        !isFree && styles.premium
      )}
    >
      <div className={styles.top}>
        <div className={styles.description}>
          <h3>{user.subscription_plan.name}</h3>
          {/*{!isFree && (*/}
          {/*  <div className={styles.stars}>*/}
          {/*    <PurpleStar className={styles.big} />*/}
          {/*    <PurpleStar className={styles.small} />*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className={styles.price}>
            <span className={styles.price}>$ {Number(subscription?.amount || 0).toFixed(0)}.</span>
            <span className={styles.cents}>{String(subscription?.amount || 0).slice(-2)}</span>
            <span>{subscription?.interval === 1 ? " / month" : " / year"}</span>
          </div>
        </div>
      </div>
      <footer>
        {isFree ? (
          <Button text="Upgrade" onClick={handleClick} size="lg" />
        ) : (
          activeSubscription && (
            <Button text="Cancel" color="green-light" onClick={open} size="lg" />
          )
        )}
        <Link
          to={`${therapistsPath}${subscriptionPlansPath}`}
          className={styles.learnMore}
        >
          Learn more
        </Link>
        {/*<PurplePlane className={styles.purplePlane} />*/}
      </footer>
      <Verified className={styles.verified} />
    </div>
  );
};

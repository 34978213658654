import { authService } from "services/authService";
import { useEffect, useState } from "react";

export const redirectServicePath = "/redirect";

export const RedirectService = () => {

  const queryParams = new URLSearchParams(window.location.search);
  const key = queryParams.get("key")!;
  const url = queryParams.get("url")!;
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!redirect) {
    const response = authService.UserKeyRedirect(key, url);
    response.then((value) => {
      setRedirect(true);
      if (value?.success) {
        // window.location.href = 'http://' + url; // for testing localhost only.
        window.location.href = url;
      } else {
        window.location.href = '/';
      }
    })
    setRedirect(false);
    }
  }, [redirect, setRedirect]);
  return <div></div>;
};

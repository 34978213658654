import { useState } from "react";
import { TwilioError } from "twilio-video";
import { VideoProvider } from "providers/VideoProvider";
import { ParticipantProvider } from "providers/ParticipantProvider";
import { VideoWrapper } from "./VideoWrapper";
import { useSearchParams } from "react-router-dom";
import { ErrorPage } from "pages/ErrorPage";
import Layout from "common/components/Layout/Layout";

export const clientVideoPath = "/chat";

export const ClientVideo = () => {
  const [error, setError] = useState<TwilioError | null>(null);
  const [params] = useSearchParams();
  const sessionId = params.get("SessionID");
  const isTherapist = Boolean(params.get("isTherapist"));
  // window.screen.orientation.lock("portrait-primary");
  if (!sessionId) {
    return <ErrorPage />;
  }

  return (
    <Layout headerVideo={true}>
      <VideoProvider
        onError={setError}
        sessionId={sessionId}
        isTherapist={isTherapist}
      >
        <ParticipantProvider>
          <VideoWrapper />
        </ParticipantProvider>
      </VideoProvider>
    </Layout>
  );
};

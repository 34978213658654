import peopleFreeSrc from "assets/images/peoples/people-free.png";
import peopleGetSrc from "assets/images/peoples/people-get.png";
import peopleStartSrc from "assets/images/peoples/people-start.png";
import Button from "common/UIKit/Button";
import styles from "./HowSection.module.scss";

export const HowSection = () => {
  return (
    <section className={styles.root}>
      <h3 className={styles.title}>How it works</h3>
      <p className={styles.lead}>3 Easy Steps</p>
      <div className={styles.row}>
        <img
          src={peopleFreeSrc}
          className={styles.rowImg}
          alt="Girl with phone"
        />
        <div className={styles.rowContent}>
          <h6 className={styles.rowTitle}>Set your preferences</h6>
          <p className={styles.rowText}>
            Tell us about your therapy needs and budget.
          </p>
        </div>
      </div>
      <div className={`${styles.row} ${styles.reverse}`}>
        <div className={styles.rowContent}>
          <h6 className={styles.rowTitle}>Choose your therapist</h6>
          <p className={styles.rowText}>
            We’ll find your best therapist matches, and you can choose one (or
            several) providers to contact.
          </p>
        </div>
        <img
          src={peopleGetSrc}
          className={styles.rowImg}
          alt="Girl with phone"
        />
      </div>
      <div className={styles.row}>
        <img
          src={peopleStartSrc}
          className={styles.rowImg}
          alt="Girl and video call"
        />
        <div className={styles.rowContent}>
          <h6 className={styles.rowTitle}>Connect now</h6>
          <p className={styles.rowText}>
            Start talking to a therapist without scheduling a session.
          </p>
          <Button className={styles.button} isLink to="/patient/login" size="lg">
            Get started
          </Button>
        </div>
      </div>
    </section>
  );
};

import { FC, useState } from "react";
import cn from "classnames";
import { Modal } from "../../../../common/components/Modal";
import styles from "./DateTimePickerPopup.module.scss";
import dateAndTimePickerBlockImg from "assets/images/date-and-time-picker-block-img.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "common/UIKit/Checkbox";
import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import Select from "common/UIKit/Select";
import { useModal } from "hooks/useModal";
import { ListOption } from "constants/options";

interface Props {
  onReschedule?: (newTime: string) => void;
}

export const DateTimePickerPopup: FC<Props> = ({ onReschedule }) => {
  const { isOpen, close } = useModal("DATE_TIME_PICKER");
  const [startDate, setStartDate] = useState(new Date());
  const [startHour, setStartHour] = useState("00");
  const [startMinute, setStartMinute] = useState("00");
  const [ampm, setAmpm] = useState("AM");

  const onSave = () => {
    onReschedule && onReschedule(apptDateTime().toISOString());
    close();
  };

  const apptDateTime = () => {
    let newDateTime = new Date(startDate);
    let hour = parseInt(startHour);
    if (ampm === "PM") {
      hour += 12;
    }
    newDateTime.setHours(hour, parseInt(startMinute), 0);
    return newDateTime;
  };

  return (
    <Modal visible={isOpen} className="modal-reschedule-appointment-time">
      <header className="modal-reschedule-appointment-time__header">
        <h4 className="mb-0">Reschedule appointment time</h4>
        <button
          className="modal-reschedule-appointment-time__close-btn"
          onClick={close}
        >
          <span className="icomoon-remove"></span>
        </button>
      </header>

      <div className="modal-reschedule-appointment-time__body">
        <div className={styles.dateAndTimePickerBlock}>
          <div className="row align-items-center gutter-16 mb-20">
            <div className="col-sm-auto">
              <h6 className="text-gray mb-10 mb-sm-0 py-sm-5">
                Appointment time
              </h6>
            </div>
            <div className="col">
              <Input
                size="sm"
                className={styles.dateAndTimePickerBlock__totalDateAndTimeInput}
                value={apptDateTime().toLocaleString()}
                disabled={true}
              />
            </div>
          </div>
          <div className="row gutter-24">
            <div
              className={cn("col", styles.dateAndTimePickerBlock__calendarCol)}
            >
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                inline
                calendarClassName="datepicker-inline"
              />
            </div>

            <div className="col-md-auto d-flex flex-column justify-content-between">
              <div className={styles.dateAndTimePickerBlock__timepickerBlock}>
                <div className="row align-items-center gutter-16">
                  <div className="col-auto py-8">
                    <Select
                      placeholder=""
                      size="sm"
                      value={{ value: startHour, label: startHour }}
                      onChange={(newValue: unknown) =>
                        setStartHour((newValue as ListOption).value)
                      }
                      className="timepicker-select"
                      options={[
                        {
                          value: "01",
                          label: "01",
                        },
                        {
                          value: "02",
                          label: "02",
                        },
                        {
                          value: "03",
                          label: "03",
                        },
                        {
                          value: "04",
                          label: "04",
                        },
                        {
                          value: "05",
                          label: "05",
                        },
                        {
                          value: "06",
                          label: "06",
                        },
                        {
                          value: "07",
                          label: "07",
                        },
                        {
                          value: "08",
                          label: "08",
                        },
                        {
                          value: "09",
                          label: "09",
                        },
                        {
                          value: "10",
                          label: "10",
                        },
                        {
                          value: "11",
                          label: "11",
                        },
                        {
                          value: "12",
                          label: "12",
                        },
                      ]}
                    />
                  </div>
                  <div className="col-auto p-0">
                    <div className={styles.timepickerDots}></div>
                  </div>
                  <div className="col-auto py-8">
                    <Select
                      placeholder=""
                      size="sm"
                      value={{ value: startMinute, label: startMinute }}
                      onChange={(newValue: unknown) =>
                        setStartMinute((newValue as ListOption).value)
                      }
                      className="timepicker-select"
                      options={[
                        {
                          value: "00",
                          label: "00",
                        },
                        {
                          value: "15",
                          label: "15",
                        },
                        {
                          value: "30",
                          label: "30",
                        },
                        {
                          value: "45",
                          label: "45",
                        },
                      ]}
                    />
                  </div>
                  <div className="col-auto py-8">
                    <Checkbox
                      className="time-format-checkbox"
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setAmpm("PM");
                        } else {
                          setAmpm("AM");
                        }
                      }}
                    >
                      <span>AM</span>
                      <span>PM</span>
                    </Checkbox>
                  </div>
                </div>
              </div>

              <div className="text-end">
                <img
                  src={dateAndTimePickerBlockImg}
                  className={styles.dateAndTimePickerBlock__img}
                  alt="Date and time picker block img"
                />
              </div>
            </div>
          </div>

          <div className="row gutter-16 pt-10 pt-md-20 justify-content-end">
            <div className="col-6 col-sm-auto py-8">
              <Button
                text="Cancel"
                color="outline-primary"
                size="sm"
                className="w-100 w-sm-auto"
                onClick={close}
              />
            </div>
            <div className="col-6 col-sm-auto py-8">
              <Button
                text="Save"
                size="sm"
                className="w-100 w-sm-auto"
                onClick={onSave}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

import { FC, useEffect } from "react";
import { useSnapshot } from "valtio";

import { CreditCardInfo } from "./CreditCardInfo";
import Button from "common/UIKit/Button";
import { useResponsive } from "hooks/useResponsive";
import { creditCardService } from "services/creditCardService";

interface Props {
  onAddCard: () => void;
}

export const Payments: FC<Props> = ({ onAddCard }) => {
  const { data, isFetched } = useSnapshot(creditCardService);
  const { isMobile } = useResponsive();

  useEffect(() => {
    if (!isFetched) {
      creditCardService.list();
    }
  }, [isFetched]);

  return (
    <>
      {data.map((card) => (
        <CreditCardInfo card={card} key={card.id} />
      ))}
      {data.length === 0 && <p>No card</p>}
      {isMobile && (
        <Button text="Add new credit card" className="w-100" onClick={onAddCard} size="lg" />
      )}
    </>
  );
};

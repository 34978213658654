import { useSnapshot } from "valtio";
import Button from "common/UIKit/Button";
import { Modal } from "common/components/Modal";
import { ReactComponent as TickCircleYellow } from "assets/images/tick-circle-yellow.svg";
import tickCircle from "assets/images/tick-circle-green.png";
import { getGenderPronoun, therapistName } from "common/utils";
import { useModal } from "hooks/useModal";
import { useModalDrag } from "hooks/useModalDrag";
import { communicationService } from "services/communicationService";
import styles from "./MessageResultPopup.module.scss";

export const MessageResultPopup = () => {
  const { receiver, error } = useSnapshot(communicationService);
  const { isOpen, close } = useModal("MESSAGE_SUCCESS");
  const { bind, transformStyle } = useModalDrag({
    moveDistanceToClose: 300,
    onClose: close,
  });
  const checkStatus = (status: "available" | "wait"): boolean | null =>
    !!receiver?.chat_status.match(status);

  return receiver ? (
    <Modal visible={isOpen} onClose={close} width={575}>
      <div className={styles.emailSuccess} style={transformStyle} {...bind()}>
        {checkStatus("wait") && <TickCircleYellow />}
        {checkStatus("available") && <img src={tickCircle} alt="success" />}
        {error ? (
          <>
            <h1>Error</h1>
            <small>{error}</small>
          </>
        ) : (
          <>
            <h1 className={styles.title}>{therapistName(receiver)}</h1>
            <small>
              {checkStatus("available") && (
                <>has received your message and will reply shortly.</>
              )}
              {checkStatus("wait") && (
                <>
                  is presently engaged. Your message has been sent and{" "}
                  {getGenderPronoun(receiver.gender, "subject")} will reply
                  shortly.
                </>
              )}
            </small>
          </>
        )}
        <Button text="Close" onClick={close} size="lg" />
      </div>
    </Modal>
  ) : null;
};

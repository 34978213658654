import { DashboardLayout } from "common/components/DashboardLayout";
import { AccessController } from "common/components/Layout/AccessController";
import { myAccountPath } from "./MyAccount";
import { patientsPath } from "../patientsPath";
import { useEffect, useMemo, useState } from "react";
import { tabs } from "./tabs";
import { useAuth } from "hooks/useAuth";
import { Patient } from "types/patient.types";
import { DashboardTab } from "./useDashboardTabs";
import { esaProfileService } from "services/esaProfileService";
import { useSnapshot } from "valtio";
import { esaStep1FullPath } from "../PatientSteps/EsaStep1";
import styles from "./PatientsLanding.module.scss";
import { authService } from "services/authService";
import { visitorIdService } from "services/VisitorIdService";
import { useSearchParams } from "react-router-dom";
export const patientsLandingPath = "/landing";
export const patientsLandingFullPath = patientsPath + patientsLandingPath;
export const patientsLandingMainPage =
patientsLandingFullPath + myAccountPath;

export const PatientsLanding = () => {
  const { client, pendingTests } = useSnapshot(esaProfileService);
  const [searchParams] = useSearchParams();
  const [tracked, setTracked] = useState(false);
  const is_esa_user = useAuth().baseUser?.is_esa_user;
  const user = useAuth().user as Patient;
  let subText;

  const visitorid = useMemo(
    () => searchParams.get("visitorid"),
    [searchParams]
  );
  visitorIdService.setVisitorID(visitorid);

  if (pendingTests.length > 0) {
    authService.patch({ diff: { user: { status: "registration" } } }).then(() => {
      window.location.href = esaStep1FullPath;
    });
  };

  useEffect(() => {
    if(!tracked) {
      visitorIdService.trackEvent(
        "patientLanding",
        "visit",
        client?.clientid.toString() || "",
        "",
        "",
      );
    setTracked(true);
    }
  }, [tracked]);


  let availableTabs: DashboardTab[];
  if (!is_esa_user) { 
    availableTabs = tabs.filter((tab) => !tab.esaOnly);
    subText = (
      <div className="text-center">
            <h3 className="m-40">Feel free to view and edit your information by clicking on My Account.</h3>
      </div>
      );
  } else {
    availableTabs = tabs;

    subText = (
      <div className="text-center">
        <h3 className="m-40">Feel free to access your ESA letters by clicking on Letters.</h3>
            <h3 className="m-40">View your Animals by clicking on Animals.</h3>
            <h3 className="m-40">You can also view and edit your information by clicking on My Account.</h3>
      </div>
      );
  }
  return (
    <AccessController patient="allowed">
      <DashboardLayout tabs={availableTabs}>
        <h1 className={styles.title}> 
          Welcome, {user?.user.first_name} {user?.user.last_name} to Therapass!
        </h1>
        {subText}
      </DashboardLayout>
    </AccessController>
  );
};

import { AxiosResponse } from "axios";
import { useModal } from "hooks/useModal";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { authService } from "services/authService";
import { SignInArgs, UserLoginArgs, UserType } from "types/auth.types";
import { useQuery } from "hooks/useQuery";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";

interface ErrorResponse {
  email?: string[];
  password?: string[];
  non_field_errors?: string[];
}

export const useLogin = (userType: UserType) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignInArgs>();
  const [globalError, setGlobalError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const { open, close } = useModal("WRONG_ROLE");
  const [finished, isFinished] = useState(false);
  const [message, setMessage] = useState("");

  const q = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    close();
  }, [location]);

  const submit: SubmitHandler<SignInArgs> = async (values) => {
      setIsLoading(true);
      const loginType = isEmail(values.contact) ? "email" : "phone";
      try {
        
        const response = await authService.UserKeyFetch(values.contact, loginType);
        const constRes = { ...response };
        if (constRes.success) {
          const loginMessage = constRes.message ? constRes.message : "";
          setIsLoading(false);
          isFinished(true);
          setMessage(loginMessage);
        }

      } catch (e: any) {
        console.log(e);
    }
  }
  return {
    register,
    handleSubmit: handleSubmit(submit),

    errors: { ...errors, global: globalError },
    setGlobalError,
    isLoading,
    finished,
    message
  };
};

import { FC, MouseEvent } from "react";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { Modal } from "common/components/Modal/Modal";
import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";

import styles from "./ShareThisProfilePopup.module.scss";

import { useModal } from "hooks/useModal";

export const ShareThisProfilePopup: FC = () => {
  const { isOpen, close } = useModal("SHARE");

  const onClickCopy = (e: MouseEvent) => {
    const target = e.target as HTMLButtonElement;
    const input = target.previousSibling?.firstChild
      ?.firstChild as HTMLInputElement;
    input.select();
    navigator.clipboard.writeText(input.value);
  };

  return (
    <Modal visible={isOpen} onClose={close} width={825}>
      <div className={styles.popup}>
        <button className={styles.popupCloseButton} onClick={close}>
          <CloseIcon />
        </button>
        <h3 className={styles.popupTitle}>Share this profile</h3>
        <sub className={styles.popupSubtitle}>Link to therapist</sub>
        <fieldset className={styles.popupField}>
          <Input
            className={styles.popupInput}
            readOnly
            value={window.location.href}
          />
          <Button className={styles.popupButton} size="lg" onClick={onClickCopy}>
            Copy link
          </Button>
        </fieldset>
      </div>
    </Modal>
  );
};

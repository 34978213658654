import { proxy } from "valtio";
import {
  Animal,
  Client,
  ClientOrder,
  ClientTest,
  Profile,
  Notification,
} from "types/profile.types";
import { esaTokenService } from "./esaTokenService";
import axios, { AxiosError } from "axios";
import { esaApiEndpoint } from "../utils/env";
import { generalLogicService } from "./generalLogicService";
import { Status } from "types/notifications.types";
import { State } from "constants/options";
import { authService } from "./authService";
import { getDeepDiff } from "common/utils";
import { Patient } from "types/patient.types";

class ESAProfileService {
  animals: Animal[] = [];
  client: Client | null = null;
  esaNotifications: Notification[] = [];
  unreadNotifications: Notification[] = [];
  orders: ClientOrder[] = [];
  pendingTests: ClientTest[] = [];
  profile: Profile | null = null;
  isLoaded: boolean = false;
  isFetchingEsa: boolean = false;

  handleError = (error: AxiosError) => {
    if (error.response) {
      const { status, data } = error.response;
      const errorMessage = error.message;

      const serverError = status >= 500;
      if (serverError) {
        generalLogicService.setError({
          status,
          message: errorMessage,
          type: "critical",
        });
      }
    }
  };

  async getProfile(reload?: boolean) {
    if (!this.isLoaded || reload) {
      this.isFetchingEsa = true;
      const response = (
        await axios.get(
          `${esaApiEndpoint}/Client/getprofile?token=${esaTokenService.get()}`
        )
      ).data["profile"] as Profile;
      this.profile = response;
      this.animals = response.animals;
      this.client = response.client;
      this.esaNotifications = response.notifications;
      this.unreadNotifications = response.notifications.filter(
        (notification) => notification.MessageStatus !== Status.READ
      );
      this.orders = response.orders;
      this.pendingTests = response.pendingtests;
      this.isLoaded = true;
      this.isFetchingEsa = false;
      return response;
    }
    return this.profile;
  }

  async updateAnimals(
    animal: Animal,
    create: boolean = false,
    onSuccess?: () => void
  ) {
    const profileCopy = this.setupProfile();
    if (create) {
      animal.NickName = animal.AnimalName;
      if (!animal.UpdatePhoto) {
        animal.Photo = "";
        animal.PhotoName = "";
      }
      profileCopy?.animals.push(animal);
    } else {
      if (profileCopy.animals) {
        const toUpdate = profileCopy.animals.find(
          (obj) => obj.ClientAnimalID === animal.ClientAnimalID
        );
        if (toUpdate) {
          toUpdate.AnimalName = animal.AnimalName;
          toUpdate.AnimalTypeId = animal.AnimalTypeId;
          toUpdate.AnimalWeight = animal.AnimalWeight;
          toUpdate.AnimalBreedId = animal.AnimalBreedId;
          toUpdate.Photo = animal.Photo;
          toUpdate.PhotoName = animal.PhotoName;
          toUpdate.UpdatePhoto = animal.UpdatePhoto;
        }
      }
    }
    this.updateProfile(profileCopy);
    onSuccess && onSuccess();
  }

  async markNotificationAsRead(notification_id: number) {
    const profileCopy = this.setupProfile();
    profileCopy.notifications.forEach((val) => {
      if (val.ClientNotificationId === notification_id) {
        val.MessageStatus = Status.READ;
        val.DateRead = new Date().toISOString();
      }
    });
    this.updateProfile(profileCopy);
  }

  async updateProfile(profileCopy: Profile) {
    const payload = JSON.stringify({
      token: esaTokenService.get(),
      profile: { ...profileCopy },
    });
    const meta = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios
      .post(`${esaApiEndpoint}/Client/updateprofile`, payload, meta)
      .catch((error) => {
        this.handleError(error);
      });
    this.isLoaded = false;
  }

  async updateClientData(data: any) {
    if (!data.user) {
      return;
    }
    const profile = (
      await axios.get(
        `${esaApiEndpoint}/Client/getprofile?token=${esaTokenService.get()}`
      )
    ).data["profile"] as Profile;

    const client = profile.client;
    client.UpdateProfilePhoto = false;
    client.profileimage ||= "";
    client.profileimagename ||= "";

    if (data.user.hasOwnProperty("prefix")) {
      client.nameprefix = data.user.prefix || "";
    } else {
      client.nameprefix ||= "";
    }

    if (data.user.hasOwnProperty("first_name")) {
      client.firstname = data.user.first_name || "";
    } else {
      client.firstname ||= "";
    }

    if (data.user.hasOwnProperty("last_name")) {
      client.lastname = data.user.last_name || "";
    } else {
      client.lastname ||= "";
    }

    if (data.user.hasOwnProperty("dob")) {
      client.birthday = data.user.dob || "";
    } else {
      client.birthday ||= "";
    }

    if (data.user.hasOwnProperty("gender")) {
      let genderVal = 0;
      switch (data.user.gender) {
        case "male":
          genderVal = 1;
          break;
        case "female":
          genderVal = 2;
          break;
        case "non_binary":
          genderVal = 3;
          break;
        default:
          break;
      }
      client.gender = genderVal;
    } else {
      client.gender ||= 0;
    }

    const payload = JSON.stringify({
      token: esaTokenService.get(),
      client: { ...client },
    });
    const meta = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios
      .post(`${esaApiEndpoint}/Client/UpdateClientData`, payload, meta)
      .catch((error) => {
        this.handleError(error);
      });
  }

  async updateClientAddress(data: any, address_type: number) {
    if (!data.user) {
      return;
    }
    const profile = (
      await axios.get(
        `${esaApiEndpoint}/Client/getprofile?token=${esaTokenService.get()}`
      )
    ).data["profile"] as Profile;

    const clientId = profile.client.clientid;
    const addrTypePrefix = address_type === 1 ? "shipping_" : "billing_";
    const address =
      address_type === 1
        ? profile.client.shippingaddress
        : profile.client.billingaddress;
    address.clientaddresstype = address_type;
    address.id ||= -1;
    if (address_type === 1) {
      if (data.user.hasOwnProperty(`${addrTypePrefix}address_1`)) {
        address.address1 = data.user.shipping_address_1 || "";
      } else {
        address.address1 ||= "";
      }

      if (data.user.hasOwnProperty(`${addrTypePrefix}address_2`)) {
        address.address2 = data.user.shipping_address_2 || "";
      } else {
        address.address2 ||= "";
      }

      if (data.user.hasOwnProperty(`${addrTypePrefix}city`)) {
        address.city = data.user.shipping_city || "";
      } else {
        address.city ||= "";
      }

      if (data.user.hasOwnProperty(`${addrTypePrefix}state`)) {
        address.state = data.user.shipping_state || "";
      } else {
        address.state ||= "";
      }

      if (data.user.hasOwnProperty(`${addrTypePrefix}zip`)) {
        address.zip = data.user.shipping_zip || "";
      } else {
        address.zip ||= "";
      }
    } else {
      if (data.user.hasOwnProperty(`${addrTypePrefix}address_1`)) {
        address.address1 = data.user.billing_address_1 || "";
      } else {
        address.address1 ||= "";
      }

      if (data.user.hasOwnProperty(`${addrTypePrefix}address_2`)) {
        address.address2 = data.user.billing_address_2 || "";
      } else {
        address.address2 ||= "";
      }

      if (data.user.hasOwnProperty(`${addrTypePrefix}city`)) {
        address.city = data.user.billing_city || "";
      } else {
        address.city ||= "";
      }

      if (data.user.hasOwnProperty(`${addrTypePrefix}state`)) {
        address.state = data.user.billing_state || "";
      } else {
        address.state ||= "";
      }

      if (data.user.hasOwnProperty(`${addrTypePrefix}zip`)) {
        address.zip = data.user.billing_zip || "";
      } else {
        address.zip ||= "";
      }
    }

    const payload = JSON.stringify({
      token: esaTokenService.get(),
      clientid: clientId,
      address: address,
    });
    const meta = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios
      .post(`${esaApiEndpoint}/Client/updatepaddress`, payload, meta)
      .catch((error) => {
        this.handleError(error);
      });
  }

  async syncProfile(patient: Patient) {
    const client = (
      await axios.get(
        `${esaApiEndpoint}/Client/getprofile?token=${esaTokenService.get()}`
      )
    ).data["profile"].client as Client;

    let payload = {
      user: { ...patient.user },
    };

    if (!patient.user.gender) {
      if (client.gender === 1) {
        payload.user.gender = "male";
      }
      if (client.gender === 2) {
        payload.user.gender = "female";
      }
      if (client.gender === 3) {
        payload.user.gender = "non_binary";
      }
    }
    if (!patient.user.billing_address_1) {
      payload.user.billing_address_1 = client.billingaddress.address1;
      payload.user.billing_address_2 = client.billingaddress.address2;
      payload.user.billing_city = client.billingaddress.city;
      payload.user.billing_state = client.billingaddress.state as State;
      payload.user.billing_zip = client.billingaddress.zip;
    }
    if (!patient.user.shipping_address_1) {
      payload.user.shipping_address_1 = client.shippingaddress.address1;
      payload.user.shipping_address_2 = client.shippingaddress.address2;
      payload.user.shipping_city = client.shippingaddress.city;
      payload.user.shipping_state = client.shippingaddress.state as State;
      payload.user.shipping_zip = client.shippingaddress.zip;
    }
    if (!patient.user.dob) {
      payload.user.dob = new Date(client.birthday);
    }

    const diff = getDeepDiff(payload, patient);
    if (diff.user) {
      if (diff.user.dob instanceof Date) {
        diff.user.dob = diff.user.dob.toISOString().split("T")[0];
      }
      const response = await authService.patch({ diff });
    }
  }

  private setupProfile(): Profile {
    const profileCopy = JSON.parse(JSON.stringify(this.profile)) as Profile;

    if (profileCopy) {
      profileCopy.client.UpdateProfilePhoto = false;
      profileCopy.client.profileimage ||= "";
      profileCopy.client.profileimagename ||= "";
    }

    profileCopy.animals.forEach((val) => {
      val.UpdatePhoto = false;
      val.Photo ||= "";
      val.PhotoName ||= "";
    });
    return profileCopy;
  }
}

export const esaProfileService = proxy(new ESAProfileService());

export type { ClientTest };

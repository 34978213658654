import Radiobutton from "common/UIKit/Radiobutton/Radiobutton";
import { stepsUi } from "../components";
import { stepsFullPath } from "../stepsPath";
import { usePatientStep10 } from "./usePatientStep10";
import { step9FullPath } from "../PatientStep9";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { useResponsive } from "hooks/useResponsive";
import { APPOINTMENT_DATE_OPTIONS } from "constants/options";
import styles from "./PatientStep10.module.scss";

export const step10Path = "/10";
export const step10FullPath = [stepsFullPath, step10Path].join("");

export function PatientStep10() {
  const { isMobile } = useResponsive();
  const { isSubmitting, selectedDate, setSelectedDate, error, handleSubmit } =
    usePatientStep10();

  return (
    <>
      <div className="text-center">
        <stepsUi.Header>
        When would you like to talk to a{" "}
        <span className="patient-step-11__title">therapist?</span>
      </stepsUi.Header>
        <br />
        <stepsUi.Hint>Select one</stepsUi.Hint>
      </div>
      <br />
      
      <stepsUi.Form
        className={styles.form}
        handleSubmit={handleSubmit}
        actions={
          <stepsUi.Actions
            buttons={[
              {
                type: "button",
                children: "Back",
                className: "btn-color-blue",
                isLink: true,
                to: step9FullPath,
              },
              {
                type: "submit",
                children: isMobile ? "Continue" : "Next",
                isLoading: isSubmitting,
              },
            ]}
          />
        }
      >
        <stepsUi.List>
          {APPOINTMENT_DATE_OPTIONS.map(({ value, label }) => (
            <Radiobutton
              key={value}
              id={`value-${value}`}
              name="appointment_date"
              value={value}
              checked={value === selectedDate}
              onChange={() => setSelectedDate(value)}
              isOutlined
            >
              {label}
            </Radiobutton>
          ))}
        </stepsUi.List>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </stepsUi.Form>
    </>
  );
}

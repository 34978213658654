import Button from "common/UIKit/Button";
import ControlledFileUpload from "common/components/ControlledFileUpload";
import useFileUpload from "hooks/useFileUpload";

import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";
import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";
import document from "assets/images/icons/document.svg";

import styles from "./LandlordForms.module.scss";
import { useSnapshot } from "valtio";
import { esaLandlordFormService } from "services/esaLandlordFormService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { esaProfileService } from "services/esaProfileService";
import { patientsDashboardFullPath } from "../PatientsDashboard";
import { myAnimalsPath } from "../MyAnimals";
import { Spinner } from "common/components/Spinner";
import { visitorIdService } from "services/VisitorIdService";

export const landlordFormsPath = "/landlord-forms";

export const LandlordForms = () => {
  const navigate = useNavigate();
  const { client } = useSnapshot(esaProfileService);
  const [tracked, setTracked] = useState(false);
  const { fileUploadProps } = useFileUpload("landlord_form", ["image", "pdf"], {
    instantUpload: true,
    instantRemove: true,
    isEsaFile: true,
    clientPetId: 0
  });

  useEffect(() => {
    esaLandlordFormService.loadAll()
    if(!tracked) {
      visitorIdService.trackEvent(
        "LandlordForms",
        "visit",
        client?.clientid.toString() || "",
        "",
        "",
      );
    setTracked(true);
    }
  }, [tracked]);


  const { data, isFetchingEsa } = useSnapshot(esaLandlordFormService);
  const { animals } = useSnapshot(esaProfileService);

  const getAnimalName = (clientAnimalId: number) => {
    const result = animals.find(({ClientAnimalID}) => {
      return ClientAnimalID === clientAnimalId;
    });
    return result?.AnimalName;
  }

  if (isFetchingEsa) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="default-typography">
      <h1 className="h3">Landlord Forms</h1>

      <div className={styles.block}>
        <div className="row">
          <div className="col-xl-6">
            <div className="mb-40 mb-xl-0">
              <h5 className="mb-20 mb-xl-40">Instructions</h5>
              <div className="text-block">
                <p>Please upload any forms provided by your landlord that need to be completed.</p>
                <p>Insure your personal information is included on the document before uploading.</p>
                <p>This service can take 1 to 3 business days.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <h5 className="mb-20 mb-xl-40">Upload New Documents</h5>
            <ControlledFileUpload
              accept="application/pdf"
              caption="Drag your documents here to start uploading"
              {...fileUploadProps}
            />
          </div>
        </div>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.table__cellWithRoundIcon}></th>
            <th className={styles.table__withArrow}>DATE <ArrowDown className={styles.table__sortArrow} /></th>
            <th>DOWNLOAD</th>
            <th>ANIMAL</th>
            <th>LETTER TYPE</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((landlordForm) => (
            <tr key={landlordForm.LetterID}>
              <td>
                <div className={styles.table__roundIcon}>
                  <img src={document} alt="Icon" />
                </div>
              </td>
              <td>{new Date(landlordForm.LetterDate).toLocaleDateString()}</td>
              <td className={styles.table__downloadCell}>
                <a href={`data:${landlordForm.LetterFile.ContentType};base64,${landlordForm.LetterFile.LetterData}`} download={landlordForm.LetterName}>
                  <Button className={styles.table__btnWithIcon} size="lg">
                    <DownloadIcon /> Download
                  </Button>
                </a>
              </td>
              <td>
                <button className={styles.table__link} onClick={() => navigate(`${patientsDashboardFullPath}${myAnimalsPath}`)}>
                  {getAnimalName(landlordForm.ClientAnimalID)}
                </button>
              </td>
              <td>{landlordForm.LetterName}</td>
              <td>{landlordForm.Status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

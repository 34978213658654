import { useNavigate, useParams } from "react-router-dom";

import { Modal } from "common/components/Modal/Modal";
import Button from "common/UIKit/Button";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { useModalDrag } from "hooks/useModalDrag";
import { useModal } from "hooks/useModal";
import { modifyPath, removePath } from "pages/RemoveTherapist/RemoveTherapist";

import styles from "./ClaimProfilePopup.module.scss";

export const ClaimProfilePopup = () => {
  const { isOpen, close } = useModal("CLAIM_PROFILE");
  const navigate = useNavigate();
  const { id } = useParams();
  const onClose = () => {
    close();
  };

  const { bind, transformStyle } = useModalDrag({
    moveDistanceToClose: 200,
    onClose,
  });

  return (
    <Modal
      visible={isOpen}
      onClose={close}
      className={styles.modal}
      width={780}
    >
      <div className={styles.popup} style={transformStyle} {...bind()}>
        <div className={styles.popupContainer}>
          <button className={styles.popupCloseButton} onClick={onClose}>
            <CloseIcon />
          </button>
          <h3 className={styles.popupTitle}>Remove or Modify Profile</h3>
          <p className={styles.caption}>
            Would you like to REMOVE your profile or MODIFY it?
          </p>
          <div className={styles.buttonBlock}>
            <Button
              className={styles.popupButton}
              onClick={() => {
                close();
                navigate({
                  pathname: modifyPath,
                  search: `?therapistId=${id}`,
                });
              }}
              size="lg"
            >
              Modify
            </Button>
            <Button
              className={styles.popupButton}
              onClick={() => {
                close();
                navigate({
                  pathname: removePath,
                  search: `?therapistId=${id}`,
                });
              }}
              size="lg"
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

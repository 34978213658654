import { FC } from "react";
import cn from "classnames";
import { Logo } from "./Logo/Logo";
import { DashboardTab } from "common/components/DashboardLayout/useDashboardTabs";
import "./HeaderVideo.scss";
import { Notification } from "common/components/Notification/Notification";

interface Props {
  className?: string;
  tabs?: DashboardTab[];
  mainTab?: boolean;
}

export const HeaderVideo: FC<Props> = ({ className, mainTab }) => {

  return (
    <header className={cn("headerVideo", className)}>
      <div className="headerVideo__content">
        <Logo />
        {mainTab && <Notification className="mobileNotification" />}
      </div>
    </header>
  );
};

export default HeaderVideo;

import { FC } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { TestScore } from "common/components/TestScore";
import Button from "common/UIKit/Button";
import { AvailableTest } from "types/availableTests.types";
import { TestAttempt } from "types/testAttempt.types";
import { isoStringToAmerican } from "common/utils";
import { isFree } from "services/availableTestsService";
import { SortArrow } from "common/components/SortArrow";
import { ReactComponent as ClockIcon } from "assets/images/icons/transparent-clock.svg";
import { Sort } from "services/listService";
import styles from "./TestsTable.module.scss";

interface Props {
  rows: readonly AvailableTest[] | readonly TestAttempt[];
  onSortChange: (
    sortBy: "name" | "finished_at" | "time_to_complete" | "started_at"
  ) => void;
  sort: Sort;
}

export const TestsTable: FC<Props> = ({ rows, onSortChange, sort }) => {
  const navigate = useNavigate();

  const isAttemptsTable = rows[0] && "result" in rows[0];
  const isInProgressTable =
    rows[0] && "result" in rows[0] && rows[0].status === "progress";

  const openTestAttempt = async (attempt: TestAttempt) => {
    navigate(`./${attempt.id}/prepare`);
  };

  const openTest = async (test: AvailableTest) => {
    if (isFree(test)) {
      navigate(`./new/prepare?test_id=${test.id}`);
    } else {
      navigate(`./${test.id}/buy`);
    }
  };

  return (
    <div className={styles.testsTable}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableLine}>
            <th
              className={cn(
                styles.tableHead,
                styles.tableLeft,
                styles.sortable
              )}
              onClick={() => onSortChange("name")}
            >
              <span>NAME OF TEST</span>
              <SortArrow name="name" sort={sort} />
            </th>
            <th className={cn(styles.tableHead, styles.tableLeft)}>TYPE</th>
            <th
              className={cn(
                styles.tableHead,
                styles.tableLeft,
                styles.sortable
              )}
              onClick={() =>
                isAttemptsTable
                  ? onSortChange("finished_at")
                  : isInProgressTable
                  ? onSortChange("started_at")
                  : onSortChange("time_to_complete")
              }
            >
              {isInProgressTable ? (
                <span>DATE STARTED</span>
              ) : isAttemptsTable ? (
                <span>DATE TAKEN</span>
              ) : (
                <span>TIME TO COMPLETE</span>
              )}
              <SortArrow
                name={["finished_at", "time_to_complete", "started_at"]}
                sort={sort}
                inverted
              />
            </th>
            {isAttemptsTable && !isInProgressTable && (
              <th className={styles.tableHead}>MY SCORE</th>
            )}
            <th className={styles.tableHead}>COSTS</th>
            <th className={styles.tableHead} />
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {rows.map((item) => {
            const test = "test" in item ? item.test : item;
            return (
              <tr key={item.id} className={styles.tableLine}>
                <td className={cn(styles.tableLeft, styles.tableCell)}>
                  <button
                    type="button"
                    onClick={() =>
                      isAttemptsTable && !isInProgressTable
                        ? navigate(`/patients/test/${item.id}`)
                        : null
                    }
                    className={styles.tableLink}
                  >
                    {test.name}
                  </button>
                </td>
                <td className={cn(styles.tableLeft, styles.tableCell)}>
                  {test.test_type}
                </td>
                <td className={cn(styles.tableLeft, styles.tableCell)}>
                  <div className={styles.centerWrapper}>
                    <ClockIcon className={styles.clockIcon} />{" "}
                    {"finished_at" in item && !isInProgressTable
                      ? `Taken ${isoStringToAmerican(item.finished_at || "")}`
                      : isInProgressTable && "started_at" in item
                      ? `Started ${isoStringToAmerican(item.started_at || "")}`
                      : `${test.time_to_complete} minutes`}
                  </div>
                </td>
                {"result" in item && !isInProgressTable && (
                  <td className={styles.tableCell}>{item.result} scores</td>
                )}
                <td className={cn(styles.costs, styles.tableCell)}>
                  {isFree(test) ? (
                    <div className={styles.free}>Free</div>
                  ) : (
                    `$ ${test.cost}`
                  )}
                </td>
                <td
                  className={cn(
                    styles.tableCell,
                    !("my_score" in test) && styles.tableRight
                  )}
                >
                  {"result" in item ? (
                    item.status === "finished" ? (
                      <TestScore
                        points={item.result || 0}
                        score={item.result_details?.level || 1}
                        maxScore={item.result_details?.total_levels || 1}
                        className={styles.speedometer}
                      />
                    ) : (
                      <Button
                        text="Continue"
                        onClick={() => openTestAttempt(item)}
                        size="lg"
                      />
                    )
                  ) : (
                    <Button
                      text="Take the Test Now"
                      onClick={() => openTest(test)}
                      size="lg"
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import Button from "common/UIKit/Button";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import { capitalize } from "lodash";
import { FormatProfileField } from "common/components/FormatProfileField";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./PersonalInformation.module.scss";

export const pathPersonalInformation = "/personal-information";

export const PersonalInformation = () => {
  const { tabName, edit } = useDashboardNavigation("Personal Info", {
    edit: "append_edit",
    goBack: "pop_path",
  });
  const auth = useAuth();
  const user = auth.user as Therapist | null;

  return (
    <section className={styles.personalInformation}>
      <h1 className={styles.personalInformationTitle}>{tabName}</h1>
      <div className={styles.personalInformationInner}>
        <div className={styles.personalInformationInfo}>
          <div className={styles.personalInformationBlock}>
            <p className={styles.personalInformationBlockTitle}>Title</p>
            <p className={styles.personalInformationBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {capitalize(user?.title)}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.personalInformationBlock}>
            <p className={styles.personalInformationBlockTitle}>Gender</p>
            <p className={styles.personalInformationBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {capitalize(user?.gender).replace(/_/g, " ")}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.personalInformationBlock}>
            <p className={styles.personalInformationBlockTitle}>First name</p>
            <p className={styles.personalInformationBlockText}>
              <FormatProfileField maxLength={40}>
                {user?.user.first_name}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.personalInformationBlock}>
            <p className={styles.personalInformationBlockTitle}>
              Use company name also
            </p>
            <p className={styles.personalInformationBlockText}>
              {user?.business_info?.use_company_name_also ? "Yes" : "No"}
            </p>
          </div>
          <div className={styles.personalInformationBlock}>
            <p className={styles.personalInformationBlockTitle}>Middle name</p>
            <p className={styles.personalInformationBlockText}>
              <FormatProfileField maxLength={40}>
                {user?.middle_name}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.personalInformationBlock}>
            <p className={styles.personalInformationBlockTitle}>Company Name</p>
            <p className={styles.personalInformationBlockText}>
              <FormatProfileField maxLength={40}>
                {user?.company_name}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.personalInformationBlock}>
            <p className={styles.personalInformationBlockTitle}>Last name</p>
            <p className={styles.personalInformationBlockText}>
              <FormatProfileField maxLength={40}>
                {user?.user.last_name}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.personalInformationBlock}>
            <p className={styles.personalInformationBlockTitle}>
              Website address
            </p>
            <p className={styles.personalInformationBlockText}>
              <FormatProfileField maxLength={40}>
                {user?.website}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.personalInformationBlock}>
            <p className={styles.personalInformationBlockTitle}>Credentials</p>
            <p className={styles.personalInformationBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {user?.credentials_choice}
              </FormatProfileField>
            </p>
          </div>
        </div>
        <Button onClick={edit} className={styles.personalInformationButton} size="lg">
          <EditIcon />
          Edit information
        </Button>
      </div>
    </section>
  );
};

import cn from "classnames";
import { useSnapshot } from "valtio";
import Button from "common/UIKit/Button";
import { ReactComponent as MobileIcon } from "assets/images/icons/mobile-phone.svg";
import { ReactComponent as LandlineIcon } from "assets/images/icons/landline-phone.svg";
import { ReactComponent as ChatIcon } from "assets/images/icons/comment-dots.svg";
import { TherapistMapList } from "../TherapistMapList";
import { formatPhone, therapistName } from "common/utils";
import { therapistProfileService } from "services/therapistProfileService";
import { FormatProfileField } from "common/components/FormatProfileField";
import { isChatAccess } from "services/authService";
import { useModal } from "hooks/useModal";
import { useCommunication } from "hooks/useCommunication";
import styles from "./TherapistRightSide.module.scss";

interface Props {
  isTherapist: boolean | null;
}

export const TherapistRightSide = ({ isTherapist }: Props) => {
  const { currentProfile } = useSnapshot(therapistProfileService);
  // const withStatus =
  //   currentProfile &&
  //   isChatAccess(currentProfile) &&
  //   currentProfile?.online_chat_sessions_availability;
  const { open } = useModal("LOGGED_AS_THERAPIST");
  const { openChantConnect } = useCommunication();
  const openMessage = () =>
    isTherapist ? open() : currentProfile && openChantConnect(currentProfile);
  const phoneToDisplay =
    currentProfile?.user.mobile_phone || currentProfile?.phone;
  const companyPhone = currentProfile?.business_info?.phone;
  const showMobilePhone =
    currentProfile && isChatAccess(currentProfile)
      ? !currentProfile?.user.hide_mobile_phone_number
      : true;
  const showCompanyPhone =
    currentProfile &&
    currentProfile?.business_info.phone &&
    !currentProfile?.business_info?.hide_company_phone_number;

  return (
    <aside className={styles.root}>
      {showMobilePhone && currentProfile?.user.mobile_phone && (
        <a
          href={phoneToDisplay ? `tel:${phoneToDisplay}` : ""}
          className={cn(styles.phone, !phoneToDisplay && "disabled")}
        >
          <span className={styles.phoneIcon}>
            <MobileIcon />
          </span>
          <span className={styles.phoneText}>
            <span>{formatPhone(phoneToDisplay)}</span>
          </span>
        </a>
      )}
      {showCompanyPhone && (
        <a
          href={companyPhone ? `tel:${companyPhone}` : ""}
          className={cn(styles.phone, !companyPhone && "disabled")}
        >
          <span className={styles.phoneIcon}>
            {currentProfile?.user.mobile_phone &&
            currentProfile.business_info.phone &&
            !currentProfile.business_info.hide_company_phone_number &&
            !currentProfile.user.hide_mobile_phone_number ? (
              <LandlineIcon />
            ) : (
              <MobileIcon />
            )}
          </span>
          <span className={styles.phoneText}>
            <span>{formatPhone(companyPhone)}</span>
          </span>
        </a>
      )}
      {currentProfile && isChatAccess(currentProfile) ? (
        <Button
          onClick={openMessage}
          className={cn(
            styles.button,
            styles.premium,
            styles[currentProfile.chat_status]
          )}
          size="lg"
        >
          <ChatIcon />
          <FormatProfileField maxLength={30}>
            {currentProfile.chat_status !== "offline"
              ? "Chat Connect"
              : `Email ${therapistName({
                  title: currentProfile?.title,
                  last_name: currentProfile?.user.last_name,
                })}`}
          </FormatProfileField>
        </Button>
      ) : (
        <></>
        // <Button
        //   onClick={openMessage}
        //   className={cn(
        //     styles.button,
        //     styles.premium,
        //   )}
        //   size="lg"
        // >
        //   <ChatIcon />
        //   <FormatProfileField maxLength={30}>
        //     Message
        //   </FormatProfileField>
        // </Button>        
      )}
      <TherapistMapList />
    </aside>
  );
};

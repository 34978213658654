import Button from "common/UIKit/Button";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import { startCase } from "lodash";
import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";
import { FormatProfileField } from "common/components/FormatProfileField";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./ClientFocus.module.scss";

export const pathClientFocus = "/client-focus";

export const ClientFocus = () => {
  const { tabName, edit } = useDashboardNavigation("Client Focus", {
    edit: "append_edit",
    goBack: "pop_path",
  });
  const auth = useAuth();
  const user = auth.user as Therapist | null;

  const languages = useDictionaryItemNames(
    "Languages",
    user?.preferred_languages
  );

  return (
    <section className={styles.clientFocus}>
      <h1 className={styles.clientFocusTitle}>{tabName}</h1>
      <div className={styles.clientFocusInner}>
        <div className={styles.clientFocusInfo}>
          <div className={styles.clientFocusBlock}>
            <p className={styles.clientFocusBlockTitle}>Client Age</p>
            <p className={styles.clientFocusBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {user?.ages.map((age) => startCase(age)).join(" / ")}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.clientFocusBlock}>
            <p className={styles.clientFocusBlockTitle}>Faith Organization</p>
            <p className={styles.clientFocusBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {startCase(user?.preferred_faith)}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.clientFocusBlock}>
            <p className={styles.clientFocusBlockTitle}>Familiar groups</p>
            <p className={styles.clientFocusBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {user?.allied_groups
                  .map((group) => startCase(group))
                  .join(", ")}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.clientFocusBlock}>
            <p className={styles.clientFocusBlockTitle}>
              Ethnicity of Your clients
            </p>
            <p className={styles.clientFocusBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {user?.preferred_ethnicities
                  .map((ethnicity) => startCase(ethnicity))
                  .join(", ")}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.clientFocusBlock}>
            <p className={styles.clientFocusBlockTitle}>
              Languages Spoken (besides English)
            </p>
            <p className={styles.clientFocusBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {languages.join(", ")}
              </FormatProfileField>
            </p>
          </div>
        </div>
        <Button className={styles.clientFocusButton} onClick={edit} size="lg">
          <EditIcon />
          Edit information
        </Button>
      </div>
    </section>
  );
};

import { Slider } from "common/components/Slider/Slider";
import Button from "common/UIKit/Button";
import { TherapistCard } from "common/components/TherapistCard";
import styles from "./FeaturedSection.module.scss";
import { useResponsive } from "hooks/useResponsive";
import { Link } from "react-router-dom";

export const FeaturedSection = ({ featuredTherapists }: any) => {
  const { isMobile } = useResponsive();

  const sliderNodes = featuredTherapists.map((item: any) => (
    <Link to={`/td/${item.therapist_key}`}>
      <TherapistCard
        className={styles.card}
        therapist={item}
        withoutSeeMore
        roundChat
        withDescription
      />
    </Link>
  ));

  return (
    <div className={styles.root}>
      <section className={styles.wrapper}>
        <h3 className={styles.featuredTitle}>Featured therapists</h3>
        <p className={styles.featuredSubtitle}>
          Licensed healthcare professionals you can trust.
        </p>
        <Slider
          className={styles.slider}
          nodes={sliderNodes}
          slidesPerView={
            sliderNodes.length === 1
              ? 1
              : sliderNodes.length === 2
              ? 2
              : sliderNodes.length > 2 && isMobile
              ? 2
              : 3
          }
          isPagination={sliderNodes.length > 3}
          isFeatured
        />
        <Button
          className={styles.viewButton}
          isLink
          to="/directory"
          state={{ withScroll: true }}
          size="lg"
        >
          View all Practitioners
        </Button>
      </section>
    </div>
  );
};

import Radiobutton from "common/UIKit/Radiobutton/Radiobutton";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { stepsUi } from "../components";
import { stepsFullPath } from "../stepsPath";
import { step8FullPath } from "../PatientStep8";
import { FAITH_OPTIONS } from "constants/options";
import { useResponsive } from "hooks/useResponsive";
import styles from "./PatientStep9.module.scss";
import { usePatientStep9 } from "./usePatientStep9";

export const step9Path = "/9";
export const step9FullPath = [stepsFullPath, step9Path].join("");

export function PatientStep9() {
  const { isMobile } = useResponsive();
  const { register, handleSubmit, error, isSubmitting } = usePatientStep9();

  return (
    <>
      <div className="text-center">
        <stepsUi.Header>Faith</stepsUi.Header>
        <br />
        <stepsUi.Hint>Select one</stepsUi.Hint>
      </div>
      <br />
      <stepsUi.Form
        handleSubmit={handleSubmit}
        className={styles.form}
        isTwoColumns={!isMobile}
        actions={
          <>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <stepsUi.Actions
              buttons={[
                {
                  type: "button",
                  children: "Back",
                  className: "btn-color-blue",
                  isLink: true,
                  to: step8FullPath,
                },
                {
                  type: "submit",
                  children: isMobile ? "Continue" : "Next",
                  isLoading: isSubmitting,
                },
              ]}
            />
          </>
        }
      >
        {FAITH_OPTIONS.map((option) => (
          <Radiobutton
            key={option.value}
            className="patient-step__radio"
            isOutlined={false}
            value={option.value}
            {...register("preferred_faith")}
          >
            {option.label}
          </Radiobutton>
        ))}
      </stepsUi.Form>
    </>
  );
}

import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { recommendationsService } from "services/recommendataionsService";
import { TherapistCard } from "common/components/TherapistCard";
import { useResponsive } from "hooks/useResponsive";
import Button from "common/UIKit/Button";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./Recommended.module.scss";
import { MessagePopup } from "common/components/MessagePopup";
import { visitorIdService } from "services/VisitorIdService";
import { esaProfileService } from "services/esaProfileService";

export const recommendedPath = "/recommended";

export const Recommended = () => {
  const { isMobile } = useResponsive();
  useDashboardNavigation(`Recommended ${isMobile ? "" : "Therapists"}`, {
    goBack: "pop_path",
  });
  const { data, isProcessing } = useSnapshot(recommendationsService);
  const { client } = useSnapshot(esaProfileService);
  const [count, setCount] = useState(3);
  useDocumentTitle("TheraPass - Recommended");

  useEffect(() => {
    recommendationsService.list(false, { count });
    visitorIdService.trackEvent(
      "Recommended",
      "visit",
      client?.clientid.toString() || "",
      "",
      "",
    );
  }, [count]);

  const viewMoreTherapists = () => setCount(6);

  return (
    <div className={styles.favorites}>
      <MessagePopup />
      {!isMobile && <h1 className={styles.header}>Recommended therapists</h1>}
      <div className={styles.cards}>
        {data.map((therapist) => (
          <TherapistCard therapist={therapist} key={therapist.id} />
        ))}
      </div>
      {data.length === 3 && (
        <Button
          onClick={viewMoreTherapists}
          className={styles.viewMore}
          isLoading={isProcessing}
          size="lg"
        >
          View more
        </Button>
      )}
    </div>
  );
};

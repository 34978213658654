import cn from "classnames";
import { AuthLayout } from "pages/Auth/components";
import { useNavigate } from "react-router-dom";
import styles from "./PhoneConfirmation.module.scss";
import VerificationInput from "react-verification-input";
import { ReactComponent as PhoneIcon } from "assets/images/icons/iphone-icon.svg";
import { ReactComponent as Pen } from "assets/images/icons/pen.svg";
import { useSnapshot } from "valtio";
import { phoneVerifyService } from "services/phoneVerifyService";
import { FC, useEffect, useState } from "react";
import { verificationPath } from "pages/VerificationSent";
import { InputPhone } from "../../UIKit/InputPhone/InputPhone";
import { useForm } from "react-hook-form";
import Button from "../../UIKit/Button";
import { authService } from "services/authService";
import { useInterval } from "hooks/useInterval";
import { useQuery } from "hooks/useQuery";
import { confirmEmailPath } from "pages/Auth/Forgot/ConfirmEmail";
import { removePath } from "pages/RemoveTherapist/RemoveTherapist";

const patientMessages = {
  postTitle: "User Registration",
  rightTitle: "Find the best match \nfor your therapy",
};
const therapistMessages = {
  postTitle: "Therapist Registration",
  rightTitle: "Expand your \nprofessional footprint",
};

const ONE_SECOND = 1000;
const ONE_MINUTE = 60;

export const PhoneConfirmation: FC = () => {
  const { temporaryData, baseUser } = useSnapshot(authService);
  const { currentPhone } = useSnapshot(phoneVerifyService);
  const navigate = useNavigate();
  const [verifyError, setVerifyError] = useState<string | null>();
  const [isInput, setIsInput] = useState<boolean>();
  const [timeLeft, setTimeLeft] = useInterval(ONE_MINUTE, ONE_SECOND);

  const q = useQuery();
  const userIdFromUrl = q.get("user_id");
  const phoneFromUrl = `+${q.get("phone")?.replace(/ /g, "")}`;
  const remove = q.get("remove");
  const modify = q.get("modify");
  const redirect_to = q.get("redirect_to");
  const email = q.get("email");
  const userType = q.get("userType");
  const messages = userType === "patient" ? patientMessages : therapistMessages;

  const { handleSubmit, setError, control } = useForm();

  const deleteUSStartNumber = (str: string) => {
    if (str.includes("+1")) {
      const cleaned = ("" + str).replace(/\D/g, "");
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return ["(", match[2], ") ", match[3], "-", match[4]].join("");
      }
    }
    return str;
  };

  useEffect(() => {
    phoneVerifyService.currentPhone =
      temporaryData?.user.mobile_phone ||
      baseUser?.mobile_phone ||
      phoneFromUrl;
  }, [temporaryData, baseUser, phoneFromUrl]);

  const onSubmit = async (values: any) => {
    if (values) {
      try {
        const userId = temporaryData?.user.id || baseUser?.id || userIdFromUrl;

        await phoneVerifyService.changePhone(
          userId,
          values.mobile_phone,
          remove || modify ? "claim" : "registration"
        );
        setTimeLeft(ONE_MINUTE);
        setIsInput(false);
      } catch (error: any) {
        if ("errors" in error.data) {
          setError(
            "mobile_phone",
            { message: error.data.errors[0] },
            { shouldFocus: true }
          );
        } else {
          setError(
            "mobile_phone",
            { message: error.data.mobile_phone[0] },
            { shouldFocus: true }
          );
        }
      }
    }
  };

  const onChange = async (code: string) => {
    if (code.length === 4) {
      try {
        await phoneVerifyService.confirmPhone(
          currentPhone,
          code,
          (remove || modify) && "claim"
        );
        if (redirect_to === "success") {
          return navigate({
            pathname: removePath,
            search: `?success=true`,
          });
        } else if (redirect_to === "resetPassword") {
          return navigate({
            pathname: confirmEmailPath,
            search: `?email=${email}`,
          });
        } else {
          // if (!baseUser?.is_email_confirmed) {
          //   console.log('-------------------');
          //   console.log(temporaryData);
          //   console.log(baseUser);
          //   console.log('-------------------');
          //   // navigate({
          //   //   pathname: verificationPath,
          //   //   search: `?email=${temporaryData?.user.email || baseUser?.email}`,
          //   // });
          // } else {
          //   await authService.getCurrentUser();
            
          // }
            try {       
              const response = await authService.UserKeyFetch(currentPhone, "phone");
              const constRes = { ...response };
            } catch (e: any) {
              console.log(e);
            }
            navigate("/");

        }
      } catch (e: any) {
        setVerifyError(e.data.errors[0]);
      }
    } else {
      setVerifyError(null);
    }
  };

  const resend = async () => {
    setTimeLeft(ONE_MINUTE);
    if (currentPhone) {
      await phoneVerifyService.resendCode(
        currentPhone,
        remove || modify ? "claim" : "registration"
      );
    }
  };

  return (
    <AuthLayout
      signTitle={
        remove
          ? `Remove Profile`
          : modify
          ? "Modify Profile"
          : "Welcome to TheraPass"
      }
      signSupTitle="TM"
      signPostTitle={messages.postTitle}
      signRightTitle={messages.rightTitle}
      wrapperClassname={styles.layout}
    >
      <section className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.iconWrapper}>
            <PhoneIcon />
          </div>
          <h3>Phone Verification</h3>
          <p className={styles.caption}>
            Enter the 4 digit number that sent to
          </p>
          {isInput ? (
            <form
              id="changePhone"
              action=""
              className={styles.form}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <InputPhone
                id="phone"
                name="mobile_phone"
                control={control}
                rules={{ required: "Required field" }}
              />
              <Button type="submit" text="Save" className={styles.formButton} size="lg" />
            </form>
          ) : (
            <div className={styles.changeNumberWrapper}>
              <span>{currentPhone && deleteUSStartNumber(currentPhone)}</span>
              <div style={{ display: "flex", marginBottom: "16px" }}>
                <Pen />
                <span onClick={() => setIsInput(true)}>Change</span>
              </div>
            </div>
          )}
          <VerificationInput
            length={4}
            placeholder=""
            classNames={{
              container: styles.container,
              character: cn(styles.character, verifyError && styles.error),
              characterInactive: styles.inactive,
              characterSelected: styles.selected,
            }}
            onChange={onChange}
          />
          {verifyError && (
            <p className={styles.errorText}>PIN you entered is not correct</p>
          )}
          {timeLeft > 0 && (
            <p className={styles.timerText}>
              You can resend PIN in {timeLeft} seconds...
            </p>
          )}
          <a
            className={cn(
              styles.resendLink,
              verifyError && styles.withError,
              timeLeft > 0 && styles.disabled
            )}
          >
            <span onClick={resend}>Resend PIN</span>
          </a>
          <div>
            <a>
              <span
                onClick={async () => {
                  await authService.logout();
                  navigate("/");
                }}
              >
                Log out
              </span>
            </a>
          </div>
        </div>
      </section>
    </AuthLayout>
  );
};

import { Route, Routes, useNavigate } from "react-router-dom";
import { AnimalsInformation } from "./AnimalsInformation";

import {
  AddAnimal,
  addAnimalPath,
} from "./AddAnimal";

import {
  EditAnimal,
  editAnimalPath,
} from "./EditAnimal";
import { useEffect, useState } from "react";
import { visitorIdService } from "services/VisitorIdService";
import { useSnapshot } from "valtio";
import { esaProfileService } from "services/esaProfileService";

export const myAnimalsPath = "/my-animals";

export const MyAnimals = () => {
  const { client } = useSnapshot(esaProfileService);
  const navigate = useNavigate();
  const [clientAnimalId, setClientAnimalId] = useState(0);
  const [tracked, setTracked] = useState(false);

  useEffect(() => {
    if(!tracked) {
      visitorIdService.trackEvent(
        "MyAnimals",
        "visit",
        client?.clientid.toString() || "",
        "",
        "",
      );
    setTracked(true);
    }
  }, [tracked]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AnimalsInformation
            onAddAnimal={() => navigate(`.${addAnimalPath}`)}
            onEditAnimal={(clientAnimalId: number) => {
              setClientAnimalId(clientAnimalId);
              navigate(`.${editAnimalPath}`);
            }}
          />
        }
      />
      <Route
        path={addAnimalPath}
        element={<AddAnimal />}
      />
      <Route
        path={editAnimalPath}
        element={<EditAnimal clientAnimalId={clientAnimalId}/>}
      />
    </Routes>
  );
};

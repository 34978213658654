import { proxy } from "valtio";

import { TherapistId, TherapistList } from "types/therapist.types";
import { ListResponse } from "types/common.types";

import { ListOption } from "constants/options";

import { HttpService } from "./httpService";
import { DictionaryItem, dictionaryService } from "./dictionaryService";
import { toQuery } from "pages/Patients/TherapistsListing/helpers/params";
import {
	availabilityOptions,
	experienceOptions,
	insuranceOptions,
	milesOptions,
	priceOptions,
} from "pages/Patients/TherapistsListing/helpers/options";
import { THERAPIES } from "pages/Patients/TherapistsListing/components/TherapyList/TherapyList";
import { getCurrentPosition } from "common/utils";
import { DirectoryDataResult } from "types/DirectoryDataResult";
import { PageMetaDataResult } from "types/PageMetaDataResult";

type Position = {
	latitude: number;
	longitude: number;
};

export const listFilters = [
	"therapyValue",
	"professionalSpecialtyValue",
	"specialtyValue",
	"typesValue",
	"availabilityValue",
] as const;

export const singleFilters = [
	"distanceValue",
	"experienceValue",
	"priceValue",
	"insuranceValue",
] as const;

export const placeFilters = ["state", "latitude", "longitude", "search"] as const;

export type ListFilter = typeof listFilters[number];

export type SingleFilter = typeof singleFilters[number];

export type Filter = ListFilter | SingleFilter;

export type PlaceFilter = typeof placeFilters[number];

type FilterItem = ListOption & { name: Filter | "search" };

export class TherapistListingService extends HttpService {
	therapists: TherapistList = [];
	featuredTherapists: TherapistList = [];
	totalCount: number | null = null;

	isInitializing = true;
	isFetching = false;
	isError = false;

	prefix = "/v1/therapists";

	specialties: ListOption[] = [];
	therapies: ListOption[] = [];
	professionalSpecialties: ListOption[] = [];

	pageSize = 10;
	currentPage = 1;
	showFilters = false;

	geo: Position | null = null;
	therapyValue: ListOption[] = [];
	distanceValue: ListOption | null = null;
	professionalSpecialtyValue: ListOption[] = [];
	experienceValue: ListOption | null = null;
	priceValue: ListOption | null = null;
	specialtyValue: ListOption[] = [];
	typesValue: ListOption[] = [];
	insuranceValue: ListOption | null = null;
	availabilityValue: ListOption[] = [];

	search: string | null = null;
	city: string | null = null;
	state: string | null = null;
	zip: string | null = null;
	latitude: string | null = null;
	longitude: string | null = null;

	appliedFilters: FilterItem[] = [];

	timestamp: number = 0;
	directorykey: string | null = this.getDirectoryKey();

	constructor(args?: any) {
		super({
			...args,
		});
	}

	async init(params?: URLSearchParams) {
		this.isInitializing = true;
		const isDirectoryKey = window.location.href.includes("directory/");

		const [specialties, therapies, professionalSpecialties] = await Promise.all(
			[
				dictionaryService.getSpecialties(),
				dictionaryService.getTherapies(),
				dictionaryService.getProfessionalSpecialties(),
			]
		);
		const mapper = (item: DictionaryItem) => ({
			value: item.code,
			label: item.name,
		});

		this.specialties = specialties
			.map(mapper)
			.filter(({ value }) => value !== "no-preference");
		this.therapies = therapies.map(mapper);
		this.professionalSpecialties = professionalSpecialties.map(mapper);

		if (params) {
			this.setFromSearchParams(params);
			this.getAppliedFilters();
		}

		if (this.search === "" && !this.zip && !this.city && !this.state) {
			this.setCurrentPosition();
		}

		this.isInitializing = false;

		if (isDirectoryKey) {
			const query = window.location.href.split("/").slice(-1);
			const directoryKey = query[0].split("?")[0];
			const results = await therapistListingService.FetchDirectoryFilterData(directoryKey);
			specialties.forEach((item: any) => {
				if (item.code === results.condition) {
					const code = {
						'value': item.code,
						'label': item.name,
					} as ListOption;
					therapistListingService.setList("specialtyValue", [code]);
					therapistListingService.setSearch(`${results.city}, ${results.state}`);
					therapistListingService.setPlace({ city: results.city, state: results.state });
				}
			});
		}
		this.getTherapists();
	}

	async setCurrentPosition({ fetch }: { fetch?: boolean } = {}) {
		try {
			this.isFetching = true;
			this.geo = await getCurrentPosition();
			if (fetch) await this.getTherapists();
		} catch (error) {
			console.log(error);
		} finally {
			this.isFetching = false;
		}
	}

	async getTherapists(): Promise<TherapistList> {
		try {
			this.isFetching = true;
			let data;
			if (this.directorykey) {
				data = (await this.http.get(
					`${this.prefix}/?${this.getQuery()}&directorykey=${this.directorykey}`
				)) as ListResponse<TherapistList>;

			}
			else {
				data = (await this.http.get(
					`${this.prefix}/?${this.getQuery()}`
				)) as ListResponse<TherapistList>;
			}
			this.therapists = data.results as TherapistList;
			this.totalCount = data.count;
			return this.therapists;
		} catch (e: any) {
			if (e.status === 404) {
				this.isError = true;
			}
			return this.therapists;
		} finally {
			this.isFetching = false;
		}
	}

	async getFeaturedTherapists(): Promise<TherapistList> {
		try {
			this.isFetching = true;
			const data = (await this.http.get(
				`${this.prefix}/featured/`
			)) as ListResponse<TherapistList>;
			this.featuredTherapists = data as unknown as TherapistList;
			return this.featuredTherapists;
		} catch (e: any) {
			if (e.status === 404) {
				this.isError = true;
			}
			return this.featuredTherapists;
		} finally {
			this.isFetching = false;
		}
	}

	async FetchMetaData(uid: string, page_type: string) {

		try {

			return (await this.http.post(
				"/v1/pagemetadata/",
				{
					uid: uid,
					page_type: page_type
				}
			)) as PageMetaDataResult;

		} catch (e: any) {
			if (e.status === 404) {
				console.log("ERROR: ", e);
				return {
					success: false,
					message: `request could not be sent. Reason: ${e}`
				}
			}
		}
	}


	async FetchDirectoryFilterData(directoryKey: string): Promise<DirectoryDataResult> { // TODO: fix api call occuring twice
		let data;
		try {
			data = (await this.http.post(
				"/v1/directorydata/",
				{
					directory_page_key: directoryKey
				}
			)) as DirectoryDataResult;
			return data;
		} catch (e: any) {
			if (e.status === 404) {
				this.isError = true;
			}
			return data as unknown as DirectoryDataResult;
		} finally {
			this.isFetching = false;
		}
	}


	setFromSearchParams(search: URLSearchParams) {
		const options = {
			distanceValue: milesOptions,
			experienceValue: experienceOptions,
			priceValue: priceOptions,
			insuranceValue: insuranceOptions,
			therapyValue: THERAPIES,
			professionalSpecialtyValue: this.professionalSpecialties,
			typesValue: this.therapies,
			specialtyValue: this.specialties,
			availabilityValue: availabilityOptions,
		};

		this.timestamp = Number(search.get("timestamp")) || Date.now();
		this.currentPage = Number(search.get("currentPage")) || 1;

		search.forEach((value, key) => {
			if ((placeFilters as readonly string[]).includes(key)) {
				const name = key as PlaceFilter;
				this[name] = value || null;
			}

			if ((singleFilters as readonly string[]).includes(key)) {
				const name = key as SingleFilter;
				this[name] =
					options[name].find((option) => option.value === value) || null;
			}

			if ((listFilters as readonly string[]).includes(key)) {
				const name = key as ListFilter;
				this[name] = options[name].filter((option) =>
					value.split(",").includes(option.value)
				);
			}
		});
	}

	getSearchParams() {
		let params: Record<string, string> = {};
		this.isError = false;
		params.currentPage = String(this.currentPage);

		singleFilters
			.filter((filterName) => this[filterName])
			.forEach(
				(filterName) => (params[filterName] = this[filterName]?.value as string)
			);

		listFilters
			.filter((filterName) => this[filterName].length > 0)
			.forEach(
				(filterName) =>
				(params[filterName] = this[filterName]
					.map(({ value }) => value)
					.join(","))
			);

		placeFilters
			.filter((filterName) => this[filterName] !== null)
			.forEach(
				(filterName) => (params[filterName] = this[filterName] as string)
			);

		params.timestamp = String(this.timestamp);

		return new URLSearchParams(params);
	}

	getQuery() {
		const params = {
			page_size: this.pageSize,
			page: this.currentPage,
			...(this.therapyValue.length && {
				modalities: this.therapyValue.map((ter) => ter.value),
			}),
			...this.geo,
			...(this.professionalSpecialtyValue.length && {
				professional_specialties: this.professionalSpecialtyValue.map(
					(specialty) => specialty.value
				),
			}),
			...(this.experienceValue && {
				how_long_practicing: this.experienceValue.value,
			}),
			...(this.specialtyValue.length && {
				specialties: this.specialtyValue.map((specialty) => specialty.value),
			}),
			...(this.typesValue.length && {
				therapies: this.typesValue.map((type) => type.value),
			}),
			...(this.insuranceValue && {
				accepts_insurance: this.insuranceValue.value,
			}),
			...(this.availabilityValue.length && {
				availability: this.availabilityValue.map((type) => type.value),
			}),
			...(this.priceValue &&
				(this.priceValue.value.split("-").length > 1
					? {
						in_person_cost_min: this.priceValue.value.split("-")[0],
						in_person_cost_max: this.priceValue.value.split("-")[1],
						online_cost_min: this.priceValue.value.split("-")[0],
						online_cost_max: this.priceValue.value.split("-")[1],
					}
					: this.priceValue.value.split("-")[0] === "0"
						? { offer_free_call: true }
						: {
							in_person_cost_min: this.priceValue.value.split("-")[0],
							online_cost_min: this.priceValue.value.split("-")[0],
						})),
			// ...(this.city && { city: this.city }),
			...(this.latitude && { latitude: this.latitude }),
			...(this.longitude && { longitude: this.longitude }),
			...(this.state && { state: this.state }),
			// ...(this.zip && { zip: this.zip }),
			timestamp: this.timestamp,
		};

		return new URLSearchParams(toQuery(params)).toString();
	}

	async like(id: TherapistId) {
		const therapist = this.therapists.find((t) => t.id === id);
		this.therapists = this.therapists.map((therapist) =>
			therapist.id === id
				? { ...therapist, is_my_favourite: !therapist.is_my_favourite }
				: therapist
		);

		await this.http.post(`/v1/therapists/${id}/favourite/`, {
			is_my_favourite: !therapist?.is_my_favourite,
		});
	}

	addListFilter(name: ListFilter, value: ListOption) {
		if (this[name].some((option) => option.value === value.value)) return;

		this[name] = [...this[name], value];
		this.currentPage = 1;
		this.getAppliedFilters();
		this.getTherapists();
	}

	setListFilter(name: ListFilter, value: ListOption[]) {
		this[name] = [...value];

		this.currentPage = 1;
		this.getAppliedFilters();
		this.getTherapists();
	}

	setFilter(name: ListFilter, value: ListOption[]) {
		
		this[name] = value;

		this.currentPage = 1;
		this.getAppliedFilters();
		this.getTherapists();
	}

	setList(name: ListFilter, value: ListOption[]) {
		this[name] = [...value];

		this.currentPage = 1;
		this.getAppliedFilters();
	}

	getDirectoryKey() {
		const isDirectory = window.location.href.includes("directory/");
		if (isDirectory) {
			const query = window.location.href.split("/").slice(-1);
			const directoryKey = query[0].split("?")[0];
			return directoryKey;
		}
		return null;
	}

	setPlace(args: {
		search?: string;
		city?: string;
		state?: string;
		zip?: string;
		latitude?: number;
		longitude?: number;
		withoutRequest?: boolean;
	}) {
		placeFilters.forEach(
			(name) =>
			(this[name] =
				typeof args[name] === "string" ? (args[name] as string) : typeof args[name] === "number" ? (args[name] as string) : null)
		);
		this.currentPage = 1;
		this.latitude = args.latitude ? String(args.latitude) : null;
		this.longitude = args.longitude ? String(args.longitude) : null;
		this.timestamp = Date.now();
		this.getAppliedFilters();
	}


	setSingleFilter(name: SingleFilter, value: ListOption | null) {
		this[name] = value;

		this.currentPage = 1;
		this.getAppliedFilters();
		this.getTherapists();
	}

	setGeo(value: Position | null) {
		this.geo = value;
		this.currentPage = 1;
		this.getAppliedFilters();
		this.getTherapists();
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
		this.getTherapists();
	}

	setPlaceFilter(args: {
		search?: string;
		city?: string;
		state?: string;
		zip?: string;
		latitude?: number;
		longitude?: number;
		withoutRequest?: boolean;
	}) {
		placeFilters.forEach(
			(name) =>
			(this[name] =
				typeof args[name] === "string" ? (args[name] as string) : typeof args[name] === "number" ? (args[name] as string) : null)
		);
		this.currentPage = 1;
		this.latitude = args.latitude ? String(args.latitude) : null;
		this.longitude = args.longitude ? String(args.longitude) : null;
		this.timestamp = Date.now();
		this.getAppliedFilters();

		if (!args.withoutRequest) {
			this.getTherapists();
		}
	}

	setSearch = (value: string) => {
		this.search = value;
	}

	removeFilter(
		name: ListFilter | SingleFilter | "insuranceValue" | "search",
		value?: string
	) {
		if (
			name === "therapyValue" ||
			name === "professionalSpecialtyValue" ||
			name === "specialtyValue" ||
			name === "typesValue" ||
			name === "availabilityValue"
		) {
			this[name] = this[name].filter((item) => item.value !== value);
		}

		if (
			name === "insuranceValue" ||
			name === "distanceValue" ||
			name === "experienceValue" ||
			name === "priceValue"
		) {
			this[name] = null;
		}

		if (name === "search") {
			this.search = null;
			this.city = null;
			this.state = null;
			this.zip = null;
			this.latitude = null;
			this.longitude = null;
			this.timestamp = Date.now();
		}

		this.getAppliedFilters();
		this.getTherapists();
	}

	clearFilters() {
		this.therapyValue = [];
		this.distanceValue = null;
		this.professionalSpecialtyValue = [];
		this.experienceValue = null;
		this.priceValue = null;
		this.specialtyValue = [];
		this.typesValue = [];
		this.insuranceValue = null;
		this.availabilityValue = [];
		this.search = null;
		this.city = null;
		this.state = null;
		this.zip = null;
		this.isError = false;
		this.latitude = null;
		this.longitude = null;
		this.timestamp = Date.now();

		this.getAppliedFilters();
		this.getTherapists();
	}

	private getAppliedFilters() {
		this.appliedFilters = [
			{ name: "search", label: this.search, value: this.search },
			...this.therapyValue.map((item) => ({
				...item,
				name: "therapyValue",
			})),
			{ ...this.distanceValue, name: "distanceValue" },
			...this.professionalSpecialtyValue.map((item) => ({
				...item,
				name: "professionalSpecialtyValue",
			})),
			{ ...this.experienceValue, name: "experienceValue" },
			{ ...this.priceValue, name: "priceValue" },
			...this.specialtyValue.map((item) => ({
				...item,
				name: "specialtyValue",
			})),
			...this.typesValue.map((item) => ({ ...item, name: "typesValue" })),
			{
				...this.insuranceValue,
				name: "insuranceValue",
				label: this.insuranceValue
					? this.insuranceValue.value === "true"
						? "Insurance Accepted"
						: "Insurance not Accepted"
					: null,
			},
			...this.availabilityValue.map((item) => ({
				...item,
				name: "availabilityValue",
			})),
		].filter((item) => item.label) as FilterItem[];
	}

	toggleShowFilters() {
		this.showFilters = !this.showFilters;
	}
}

export const therapistListingService = proxy(new TherapistListingService());

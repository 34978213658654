import { FC } from "react";
import cn from "classnames";
import { capitalize } from "lodash";

import Button from "../../UIKit/Button";
import Layout from "../Layout/Layout";
import "./EmailSent.scss";

interface Props {
  type: "verification" | "reset password";
  email?: string;
  onResend?: () => void;
  isResending?: boolean;
}

export const EmailSent: FC<Props> = ({
  children,
  type,
  email,
  onResend,
  isResending,
}) => (
  <Layout mainClass="verification" withoutHeader>
    <section className="verification-container">
      <div className="verification-wrapper">
        <h1 className="verification-title__caption title">
          {type?.includes("verification")
            ? "Check your inbox!"
            : `${capitalize(type)} email has been sent`}
        </h1>
        <h3 className="verification-title__posttitle">
          We’ve just sent a {type} email to{" "}
          <span>
            <span className="g-underline">{email}</span>
          </span>
        </h3>
        <h3 className="verification-title__posttitle">
          Don’t see the email? Check your spam or send it again.
        </h3>
        <Button
          type="button"
          className={cn("verification-submit", "w-100")}
          onClick={onResend}
          isLoading={isResending}
          size="lg"
        >
          Resend email
        </Button>
        {children}
        <p className="verification-help">
          Need help?{" "}
          <a href="mailto:support@therapass.com" className="g-underline">
            Contact support
          </a>
        </p>
      </div>
    </section>
  </Layout>
);

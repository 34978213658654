import { FC } from "react";
import cn from "classnames";
import { Spinner } from "common/components/Spinner";
import "./Preloader.scss";

interface Props {
  text?: string;
  className?: string;
}

export const Preloader: FC<Props> = ({ text, className }) => {
  return (
    <div className={cn("preloader default-typography", className)}>
      <Spinner />
      {text && (
        <h4 className="mb-0">{text}</h4>
      )}
    </div>
  );
};

import { useState } from "react";
import { Controller } from "react-hook-form";
import isURL from "validator/es/lib/isURL";

import Checkbox from "common/UIKit/Checkbox";
import Button from "common/UIKit/Button";
import Input from "common/UIKit/Input";
import AppSelect from "common/UIKit/Select";
import { InputSmartSearch } from "common/UIKit/InputSmartSearch";
import { InputPhone } from "common/UIKit/InputPhone";

import { EditHeader } from "common/components/EditHeader";

import { stepsUi } from "pages/Therapists/TherapistSteps/components";
import { TherapistFields } from "pages/Therapists/TherapistSteps/util";

import { STATE_OPTIONS, StateOption } from "constants/options";
import { useProfileForm } from "../../useProfileForm";
import {
  cityValidator,
  streetAddressValidator,
  zipCodeValidator,
} from "common/validators/addressValidators";

import { useGeocoder } from "hooks/useGeocoder";
import { useAuth } from "hooks/useAuth";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";

import { DeepPartial } from "common/utils";
import { BusinessInfo, Therapist } from "types/therapist.types";

import styles from "./EditAddressContact.module.scss";

interface Inputs {
  business_info: BusinessInfo;
}

const coveredFields: TherapistFields = ["business_info"];

export const EditAddressContact = () => {
  useDashboardNavigation("Edit Business Info", {
    goBack: "pop_path",
  });
  const [showWarning, setShowWarning] = useState(true);
  const user = useAuth().user as Therapist | null;
  const geocoder = useGeocoder();

  const profileLink = `therapass.com/td/${user?.id}`;
  const toApi = async (values: DeepPartial<Therapist>) => {
    try {
      const result = await geocoder(values);

      return {
        ...values,
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng(),
      };
    } catch {
      return values;
    }
  };

  const toFields = (values: any) => {
    setShowWarning(values.business_info.website === profileLink);

    if (!values.business_info.website) {
      setShowWarning(true);
      return {
        ...values,
        business_info: { ...values.business_info, website: profileLink },
      };
    }
  };

  const { control, handleSubmit, setValue, register, errors } =
    useProfileForm<Inputs>(coveredFields, null, { toApi, toFields });

  const canHidePhone = user?.subscription_plan.chat_access;

  return (
    <section className={styles.editContact}>
      <EditHeader />
      <form className={styles.editContactInner} onSubmit={handleSubmit}>
        <stepsUi.Row label="Address">
          <InputSmartSearch
            onSelectPlace={(place) => {
              setValue("business_info.street_address", place.address || "");
              setValue("business_info.city", place.city || "");
              setValue("business_info.state", place.state || "");
              setValue("business_info.zip_code", place.zip || "");
              setValue("business_info.latitude", place.latitude || 0);
              setValue("business_info.longitude", place.longitude || 0);
            }}
            types={["address"]}
            placeholder="Start typing your address"
            className={styles.search}
          />
        </stepsUi.Row>
        <stepsUi.Grid>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Company Name"
              {...register("business_info.company_name", {
                maxLength: { value: 64, message: "Maximum 64 characters" },
              })}
              error={errors.business_info?.company_name?.message}
            />
          </stepsUi.Row>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Street Address"
              {...register(
                "business_info.street_address",
                streetAddressValidator
              )}
              error={errors.business_info?.street_address?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Grid>
          <stepsUi.RowShort>
            <stepsUi.Row>
              <Input
                type="text"
                placeholder=""
                label="City"
                {...register("business_info.city", cityValidator)}
                error={errors.business_info?.city?.message}
              />
            </stepsUi.Row>
            <stepsUi.Row>
              <Input
                type="text"
                placeholder=""
                label="Zip"
                {...register("business_info.zip_code", zipCodeValidator)}
                error={errors.business_info?.zip_code?.message}
              />
            </stepsUi.Row>
          </stepsUi.RowShort>
          <stepsUi.Row label="State">
            <Controller
              control={control}
              name="business_info.state"
              render={({ field }) => (
                <AppSelect
                  placeholder="Select"
                  options={STATE_OPTIONS}
                  value={STATE_OPTIONS.find(
                    ({ value }) => value === field.value
                  )}
                  onChange={(v) => field.onChange((v as StateOption).value)}
                />
              )}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <Checkbox
          id="hide_company_address"
          className={styles.editContactCheckbox}
          {...register("business_info.hide_company_address")}
        >
          Hide company address from public view
        </Checkbox>
        <stepsUi.Grid>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Website"
              aboveDescription={showWarning}
              {...register("business_info.website", {
                validate: (value) =>
                  !value || (value && isURL(value)) || "Is not valid url",
              })}
              error={errors.business_info?.website?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Grid>
          <stepsUi.Row>
            <InputPhone
              label="Company Phone Number"
              control={control}
              name="business_info.phone"
            />
          </stepsUi.Row>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Ext #"
              {...register("business_info.phone_extension", {
                maxLength: { value: 5, message: "Maximum 5 characters" },
              })}
              error={errors.business_info?.phone_extension?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        {canHidePhone && (
          <Checkbox
            id="hide_phone_number"
            className={styles.editContactCheckbox}
            {...register("business_info.hide_company_phone_number")}
          >
            Hide company phone number in profile
          </Checkbox>
        )}
        <Button className={styles.editContactButton} type="submit" size="lg">
          Save
        </Button>
      </form>
    </section>
  );
};

import { withCookies, Cookies } from "react-cookie";
import { HttpService } from "services/httpService";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";
import { esaApiEndpoint } from "utils/env";

class VisitoridService extends HttpService {
  private isFetching = false;

  private isError = false;

  async getVisitorID(issuseNew: boolean): Promise<string> {
    try {
      const cook = new Cookies();
      let vid = cook.get("visitorid");
      if (!issuseNew) {
        if (vid != null && vid !== "") {
          vid = await this.setVisitorID("");
          return vid;
        }
      }
      this.isFetching = true;
      const url = `${esaApiEndpoint}/connect/visitorid/?reIssue=${String(issuseNew)}`;
      let data = (await this.http.get(url)) as string;
      data = await this.setVisitorID(data);
      return data;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return "";
    } finally {
      this.isFetching = false;
    }
  }

  async setVisitorID(passedID: string | null): Promise<string> {
    try {
      const cook = new Cookies();
      const vid = cook.get("visitorid") as string;
      const url = `${esaApiEndpoint}/connect/syncvisitorid/?localVisitorID=${vid}&passedVisitorID=${passedID}`;
      const data = (await this.http.get(url)) as string;
      cook.set("visitorid", data);
      return data;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return "";
    } finally {
      this.isFetching = false;
    }
    return "";
  }

  async trackEvent(
    eventName: string,
    data1: string | null,
    data2: string | null,
    data3: string | null,
    data4: string | null,
  ) {
    try {
      const cook = new Cookies();
      let vid = cook.get("visitorid");
      if (vid == null || vid === "") {
        vid = await this.getVisitorID(false);
      }
      const userAent = window.navigator.userAgent;
      const ipAddress = "";
      const eventUrl = window.location.href;
      const postData = {
        visitorID: vid,
        userAgent: userAent,
        ipAddress,
        eventUrl,
        eventName,
        data01: data1,
        data02: data2,
        data03: data3,
        data04: data4,
      };
      const data = (await this.http.post(
        `${esaApiEndpoint}/connect/logEvent/`,
        postData
      )) as string;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return "";
    } finally {
      this.isFetching = false;
    }
    return "";
  }
}

export const visitorIdService = proxy(new VisitoridService());
devtools(visitorIdService, { name: "visitorIdService" });

import useVideoContext from "hooks/useVideoContext";
import cn from "classnames";
import axios from "axios";
import { esaApiEndpoint } from "utils/env";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styles from "./EndCallButton.module.scss";
import EndCallIcon from "./end-call.svg";
import { ParticipantType, ConnectionStatuses, UpdateSessionStatusArgs} from "types/VideoChatParticipant.types";
import { videoChatParticipantStatusService } from "services/videoCallParticipantStatusService";


export default function EndCallButton(props: { className?: string }) {
  const { room } = useVideoContext();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const isTherapist = params.get("isTherapist");
  const sessionId = params.get("SessionID");

  let urlRedirect = '/patients/dashboard/my-account/personal-information';



	const SetRoomParticipantStatus = (participant: ParticipantType, status: ConnectionStatuses) => { 
		let args:UpdateSessionStatusArgs = { SessionID: sessionId!, Participant: participant, Status: status };
		console.log("SetRoomParticipantStatus");
		videoChatParticipantStatusService.setSessionStatus(args);
	}

	if (isTherapist) {
		const therapistKey = params.get("therapistKey");
		const clientId = params.get("clientId");
		urlRedirect = `/therapists/video?therapistKey=${therapistKey}&sessionId=${sessionId}&clientId=${clientId}`;
	}

	return (
		<button
        id="end-call"
			onClick={() => {
				if (isTherapist) {
					SetRoomParticipantStatus(ParticipantType.Therapist,ConnectionStatuses.Ended)
					room!.disconnect()
					navigate(urlRedirect)
				} else{
					SetRoomParticipantStatus(ParticipantType.Patient,ConnectionStatuses.Ended)
					navigate(urlRedirect)
					room!.disconnect()
				}
			}}
			className={styles.mainButton}
			data-cy-disconnect
		  >
			<img src={EndCallIcon} alt="phone-icon" />
			  END
		  </button>
	);
}
import { useEffect, useMemo } from "react";
import cn from "classnames";
import { useSnapshot } from "valtio";
import Button from "common/UIKit/Button";
import { useResponsive } from "hooks/useResponsive";
import { ReactComponent as IconVerified } from "assets/images/icons/verified.svg";
import { useNavigate } from "react-router-dom";
import { filesService } from "services/filesService";
import { authService } from "services/authService";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useAuth } from "hooks/useAuth";
import ControlledFileUploadProfile from "common/components/ControlledFileUploadProfile";
import useFileUpload from "hooks/useFileUpload";
import {
  ProgressBar,
  RemoveBtn,
} from "common/components/FileUpload/components";
import VideoSection from "./VideoSection";
import { toast } from "react-toastify";
import styles from "./PhotosAndVideos.module.scss";
import { TherapistFile } from "types/therapist.types";

export const pathPhotosAndVideo = "/photos-and-video";

export const PhotosAndVideos = () => {
  const { isMobile } = useResponsive();
  const { tabName, goBack } = useDashboardNavigation(
    isMobile ? "Photo & Videos" : "Edit Photo & Videos",
    {
      goBack: "pop_path",
    }
  );
  const navigate = useNavigate();

  const { fileUploadProps } = useFileUpload(
    "photo_and_video_intro",
    ["image"],
    { instantUpload: true, instantRemove: true }
  );

  const { user } = useAuth();
  const { data: files, uploadType } = useSnapshot(filesService);

  const { photos, videos } = useMemo(() => {
    const photos = files.filter(
      (file) =>
        file.type === "photo_and_video_intro" && file.mime.includes("image")
    );

    const videos = files.filter(
      (file) =>
        file.type === "photo_and_video_intro" && file.mime.includes("video")
    );

    return { photos, videos };
  }, [files]);

  const onClickPhoto = async (photoId: number) => {
    if (!user) {
      return;
    }
    await Promise.all([
      filesService.makeAsMainPhoto(user.id, photoId),
      authService.getCurrentUser(),
      filesService.list(user.id, undefined, false),
    ])
      .then(() =>
        toast("Avatar has been changed", {
          type: "info",
          autoClose: isMobile ? 3000 : 5000,
          position: isMobile ? "top-center" : "top-right",
        })
      )
      .catch(() =>
        toast("Something went wrong. Please try again later.", {
          type: "error",
          autoClose: isMobile ? 3000 : 5000,
          position: isMobile ? "top-center" : "top-right",
        })
      );
  };

  useEffect(() => {
    user && filesService.list(user.id);
  }, [user]);

  return (
    <div className={styles.photosAndVideos}>
      <div className={styles.desktopHeader}>
        {!isMobile && goBack && (
          <button className={styles.goBackButton} onClick={goBack}>
            <div className={styles.angle} />
          </button>
        )}
        <h1>{tabName}</h1>
      </div>
      <section className={styles.backdrop}>
        {isMobile && (
          <div className={styles.header}>
            <h2>My Photos</h2>
            <Button
              className={styles.btn}
              onClick={() =>
                navigate("/therapists/dashboard/profile/photo/edit")
              }
              size="lg"
            >
              Upload
            </Button>
          </div>
        )}
        <div className={styles.part}>
          {!isMobile && <h2>My Photos</h2>}
          <p className={styles.caption}>
            To set an avatar, click on one of the uploaded photos.
          </p>
          <div className={styles.photos}>
            {photos && photos.length > 0 ? (
              photos?.map((file) => (
                <div key={file.id} className={styles.photo}>
                  <div
                    className={cn(null, file.is_main_photo && styles.mainPhoto)}
                    onClick={() => onClickPhoto(file.id)}
                  >
                    {file.is_main_photo && (
                      <IconVerified className={styles.photoIcon} />
                    )}
                    <img src={file.file} alt="Therapist" />
                  </div>
                  <div>
                    <RemoveBtn
                      handleRemoveFile={() =>
                        fileUploadProps.onRemoveFileById(file.id)
                      }
                    />
                  </div>
                </div>
              ))
            ) : (
              <p className={styles.noVideoPhoto}>
                There are no uploaded photos yet.
              </p>
            )}
            {uploadType?.includes("image") && (
              <ProgressBar onRemove={fileUploadProps.onAbortUploading} />
            )}
          </div>
        </div>
        {!isMobile && (
          <div className={styles.part}>
            {!isMobile && <h2>Upload New Photo</h2>}
            <ControlledFileUploadProfile
              accept="image/jpg,image/gif,image/png,image/tiff,image/jpeg"
              multiple
              caption={
                isMobile ? undefined : "Drag photo here to start uploading"
              }
              className={styles.dropContainer}
              {...fileUploadProps}
            />
          </div>
        )}
      </section>
      <section className={styles.backdrop}>
        {isMobile && (
          <div className={styles.header}>
            <h2>My Video Intro</h2>
            <Button
              className={styles.btn}
              onClick={() =>
                navigate("/therapists/dashboard/profile/video/edit")
              }
              size="lg"
            >
              {!videos.length ? "Upload or record" : "Edit"}
            </Button>
          </div>
        )}
        <VideoSection />
      </section>
    </div>
  );
};

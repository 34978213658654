import React, { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { IconCheckbox } from "common/components/IconCheckbox";

import { useAuth } from "hooks/useAuth";
import { useSteps } from "hooks/useSteps";
import { useResponsive } from "hooks/useResponsive";

import { Patient } from "types/patient.types";
import { THERAPY_TYPE_OPTIONS, TherapyType } from "constants/options";

import { stepsUi } from "../components";
import { stepsFullPath } from "../stepsPath";
import { step1FullPath } from "../PatientStep1";
import { step3FullPath } from "../PatientStep3";

// import styles from "./PatientStep2.module.scss";

export const step2Path = "/2";
export const step2FullPath = `${stepsFullPath}${step2Path}`;

export function PatientStep2() {
  const navigate = useNavigate();
  const patient = useAuth().user as Patient;
  const { isMobile } = useResponsive();

  useLayoutEffect(() => {
    if (!patient) return;
    for (const type of patient.therapy_types) {
      // @ts-ignore
      const checkElem: HTMLInputElement = document.getElementById(type)!;
      checkElem.checked = true;
    }
  }, [patient.therapy_types]);

  const { submit, isSubmitting, error } = useSteps();
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    // @ts-ignore
    const formData = new FormData(e.target);
    const therapy_types: TherapyType[] = [];

    for (const { value } of THERAPY_TYPE_OPTIONS) {
      if (formData.get(value)) {
        therapy_types.push(value);
      }
    }

    await submit({
      diff: { therapy_types },
      onSuccess: () => navigate(step3FullPath),
      errorMessage: "Select at least one therapy type",
    });
  };

  return (
    <>

      <div className="text-center">
      <stepsUi.Header>I'd like to do therapy via</stepsUi.Header>
      <br />
      <stepsUi.Hint>Check all that apply</stepsUi.Hint>
      </div>
      <br />
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={
          <>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <stepsUi.Actions
              buttons={[
                {
                  className: "btn-color-blue",
                  type: "button",
                  children: "Back",
                  isLink: true,
                  to: step1FullPath,
                },
                {
                  type: "submit",
                  children: isMobile ? "Continue" : "Next",
                  isLoading: isSubmitting,
                },
              ]}
            />
          </>
        }
      >
        {THERAPY_TYPE_OPTIONS.map(({ value, label, icon }) => (
          <IconCheckbox
            key={value}
            id={value}
            icon={icon}
            name={value}
            label={label}
          />
        ))}
      </stepsUi.Form>
    </>
  );
}

import { EditHeader } from "common/components/EditHeader";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { TherapistFields } from "pages/Therapists/TherapistSteps/util";
import { useProfileForm } from "../../useProfileForm";
import { stepsUi } from "pages/Therapists/TherapistSteps/components";
import { Controller } from "react-hook-form";
import RangePicker from "common/UIKit/Range";
import Textarea from "common/UIKit/Textarea/Textarea";
import Button from "common/UIKit/Button";
import { useNavigate } from "react-router-dom";
import { fullProfilePath } from "../../Profile";
import AddButton from "common/UIKit/AddButton";
import Card from "common/UIKit/Card";
import { ReactComponent as DocumentIcon } from "assets/images/icons/document.svg";
import { ReactComponent as EditIcon } from "assets/images/icons/pencil.svg";
import { useEffect } from "react";
import { educationService } from "services/educationService";
import { useAuth } from "hooks/useAuth";
import { useSnapshot } from "valtio";
import { FormatProfileField } from "common/components/FormatProfileField";
import styles from "./EditEducation.module.scss";

export const pathEducation = "/education";

const coveredFields: TherapistFields = [
  "how_long_practicing",
  "about_qualifications",
];

type Inputs = {
  how_long_practicing: number;
  about_qualifications: string;
};

export const EditEducation = () => {
  const navigate = useNavigate();
  useDashboardNavigation("Edit Education And Experience", {
    goBack: () => navigate(fullProfilePath),
    isNavigationHidden: true,
  });
  const { user } = useAuth();
  const { data } = useSnapshot(educationService);
  const { handleSubmit, control, isSubmitting } =
    useProfileForm<Inputs>(coveredFields);

  useEffect(() => {
    user && educationService.list(user?.id);
  }, [user]);

  return (
    <section className={styles.root}>
      <EditHeader className={styles.header} />
      <h2 className={styles.title}>Education</h2>
      <div className={styles.mobileRow}>
        {data.length && user?.id
          ? data.map((education) => (
              <Card
                key={education.id}
                className={styles.item}
                values={[
                  {
                    caption: "SCHOOL GRADUATED",
                    text: (
                      <FormatProfileField>
                        {education.school_graduated}
                      </FormatProfileField>
                    ),
                  },
                  {
                    caption: "DEGREE / DIPLOMA",
                    text: (
                      <FormatProfileField>
                        {education.degree_diploma}
                      </FormatProfileField>
                    ),
                  },
                  {
                    caption: "YEAR GRADUATED",
                    text: (
                      <FormatProfileField>
                        {education.year_graduated}
                      </FormatProfileField>
                    ),
                  },
                ]}
                icon={<DocumentIcon />}
                onEdit={() => navigate(String(education.id))}
                onRemove={() => educationService.delete(user?.id, education.id)}
                editIcon={<EditIcon />}
                buttonClass={styles.cardButton}
              />
            ))
          : null}
        <AddButton className={styles.addButton} onClick={() => navigate("add")}>
          Add education
        </AddButton>
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Experience</h2>
        <stepsUi.Row
          label="How long have you been practicing?"
          className={styles.longRow}
        >
          <Controller
            name="how_long_practicing"
            control={control}
            render={({ field }) => (
              <RangePicker value={field.value} onChange={field.onChange} />
            )}
          />
        </stepsUi.Row>
        <stepsUi.Row
          label="Tell us more about your qualifications"
          className={styles.longRow}
        >
          <Controller
            control={control}
            name="about_qualifications"
            rules={{
              maxLength: { value: 200, message: "Max length is 200" },
            }}
            render={({ field, fieldState }) => (
              <Textarea
                className={styles.textarea}
                placeholder="Type something..."
                {...field}
                currentLength={field?.value?.length}
                error={fieldState.error?.message}
                maxLength={200}
              />
            )}
          />
        </stepsUi.Row>
        <Button className={styles.button} type="submit" size="lg">
          Save
        </Button>
      </form>
    </section>
  );
};

import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import AppSelect from "common/UIKit/Select";
import Textarea from "common/UIKit/Textarea/Textarea";

import styles from "./ContactUs.module.scss";

import ControlledFileUpload from "common/components/ControlledFileUpload";
import useFileUpload from "hooks/useFileUpload";
import { Controller, useForm } from "react-hook-form";
import { DashboardContact, ContactFile } from "types/contact.types";
import { useEffect, useState } from "react";
import { InputPhone } from "common/UIKit/InputPhone";
import { esaContactService } from "services/esaContactService";
import { esaProfileService } from "services/esaProfileService";
import { useSnapshot } from "valtio";
import { visitorIdService } from "services/VisitorIdService";

export const contactUsPath = "/contact-us";

export const ContactUs = () => {
  const [contactFiles, setContactFiles] = useState<ContactFile[]>([]);
  const { client } = useSnapshot(esaProfileService);
  const [tracked, setTracked] = useState(false);
  const { fileUploadProps } = useFileUpload("contact_form", ["image", "pdf"], {
    instantUpload: true,
    instantRemove: true,
    isEsaFile: true,
    clientPetId: 0,
    setContactFiles: setContactFiles,
  });

  const { register, handleSubmit, setValue, control } = useForm<DashboardContact>();
  const onSubmit = (values: DashboardContact) => {
    values["attachments"] = contactFiles;
    esaContactService.submitForm(values);
    window.location.reload();
  }

  useEffect(() => {
    if(!tracked) {
      visitorIdService.trackEvent(
        "ContactUs",
        "visit",
        client?.clientid.toString() || "",
        "",
        "",
      );
    setTracked(true);
    }
  }, [tracked]);

  
  return (
    <div className="default-typography">
      <h1 className="h3">Contact Us</h1>

      <form className={styles.form}>
        <div className="mb-35">
          <Controller
            control={control}
            rules={{
              required: "This field is required."
            }}
            name="Name"
            render={() => (
              <AppSelect
                label="Reason"
                options={[
                  {value: 'Order Status', label: 'Order Status'},
                  {value: 'Return', label: 'Return'},
                  {value: 'Exchange', label: 'Exchange'},
                  {value: 'Landlord Denied Letter', label: 'Landlord Denied Letter'},
                  {value: 'Payment', label: 'Payment'},
                  {value: 'Refund', label: 'Refund'},
                  {value: 'Other', label: 'Other'}
                ]}
                onChange={(nextValue) => {
                  const { value } = nextValue as {
                    label: string;
                    value: string;
                  };
                  setValue("Name", value);
                }}
              />
            )}
          />
        </div>
        <div className="mb-35">
          <Input
            label="Subject"
            required={true}
            {...register("Subject", { required: "This field is required." })}
          />
        </div>
        <div className="mb-35">
          <InputPhone
            id="phone"
            name="MobileNumber"
            control={control}
            label="Phone Number"
            rules={{ required: "This field is required." }}
          />
        </div>
        <div className="mb-35">
          <Textarea
            label="Message"
            required={true}
            {...register("Body", { required: "This field is required." })}
          />
        </div>
        <div className="mb-35">
          <ControlledFileUpload
            className={styles.upload}
            accept="image/jpg,image/gif,image/png,image/tiff,image/jpeg,application/pdf"
            caption="Drag your documents here to start uploading"
            {...fileUploadProps}
          />
        </div>

        <Button
          text="Send"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          size="lg"
        />
      </form>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { SubmitHandler, useFormContext } from "react-hook-form";

import { useModal } from "hooks/useModal";

import {
  communicationService,
  ContactTherapistValues,
} from "services/communicationService";
import { AxiosResponse } from "axios";
import { isChatAccess } from "services/authService";
import { systemSettingsService } from "services/systemSettingsService";

type ModalTab = "email" | "sms";

export default function useMessageTherapist() {
  const { receiver } = useSnapshot(communicationService);
  const [hasTemplate, setHasTemplate] = useState(false);
  const [template, setTemplate] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    reset,
    control,
  } = useFormContext<ContactTherapistValues>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { open: openMessageSuccess } = useModal("MESSAGE_SUCCESS");
  const { open: openEmailSuccess } = useModal("EMAIL_SUCCESS");
  const [currentTab, setCurrentTab] = useState<ModalTab>("email");
  const [canSendSms, setCanSendSms] = useState(false);

  const { isOpen, close } = useModal("MESSAGE");
  const { open: openChangePhone } = useModal("CHANGE_PHONE_NUMBER");

  const onSubmit: SubmitHandler<ContactTherapistValues> = async (values) => {
    if (receiver) {
      try {
        await setIsSubmitting(true);
        await communicationService.contactTherapist({
          type: currentTab,
          text: values.message,
          captchaToken: values.captcha,
        });
        setIsSubmitting(false);
        reset({ message: "", captcha: null });
        currentTab === "sms" ? openMessageSuccess() : openEmailSuccess();
      } catch (error) {
        const { data, status } = error as AxiosResponse;
        if (status === 400) {
          setError("message", { message: data.errors[0], type: "custom" });
        }
        setIsSubmitting(false);
      }
    }
  };


  // useEffect(() => {
  //   if (!hasTemplate) {
  //     systemSettingsService.get().then((res: any) => {
  //       if (res.patient_connect_now_text_template) {
  //         setTemplate(res.patient_connect_now_text_template);
  //       }
  //     });
  //   }
  // }, [hasTemplate, setHasTemplate]);

  useEffect(() => {

    if (receiver && !isChatAccess(receiver)) {
      setCurrentTab("sms");
      setCanSendSms(false);
    }
    else if (
      receiver &&
      isChatAccess(receiver) &&
      receiver.chat_status !== "offline"
    ) {
      setCurrentTab("sms");
      setCanSendSms(true);
    }
    else {
      setCurrentTab("email");
      setCanSendSms(false);
    }
  }, [receiver]);

  return {
    isOpen,
    close,
    openChangePhone,
    handleSubmit: handleSubmit(onSubmit),
    register,
    errors,
    getValues,
    control,
    isSubmitting,
    currentTab,
    setCurrentTab,
    canSendSms,
    template,
  };
}

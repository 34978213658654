import React from "react";
import clsx from "clsx";
import useMainParticipant from "hooks/useMainParticipant";
import useVideoContext from "hooks/useVideoContext";
import useSelectedParticipant from "providers/VideoProvider/useSelectedParticipant";
import Participant from "../Participant/Participant";
import useParticipantsContext from "hooks/useParticipantsContext";
import styles from "./ParticipantList.module.scss";

export default function ParticipantList() {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const { speakerViewParticipants } = useParticipantsContext();
  const [selectedParticipant, setSelectedParticipant] =
    useSelectedParticipant();
  const mainParticipant = useMainParticipant();

  if (speakerViewParticipants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    <>
      {speakerViewParticipants.map((participant) => {
        const isSelected = participant === selectedParticipant;
        const hideParticipant = participant === mainParticipant && !isSelected;
        return (
          <div>
            <Participant
              key={participant.sid}
              participant={participant}
              isSelected={participant === selectedParticipant}
              onClick={() => setSelectedParticipant(participant)}
              hideParticipant={hideParticipant}
            />
          </div>
        );
      })}
      <Participant participant={localParticipant} isLocalParticipant={true} />
    </>
  );
}

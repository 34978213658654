import { getAvatar, therapistName } from "common/utils";
import { FC, useState } from "react";
import { authService, isTherapist } from "services/authService";
import { FormatProfileField } from "../FormatProfileField";
import avatar from "assets/images/avatar.png";
import { useAuth } from "hooks/useAuth";
import { Patient } from "types/patient.types";
import { ReactComponent as Chevron } from "assets/images/icons/chevron-down-regular.svg";
import { ReactComponent as LogoutIcon } from "assets/images/icons/log-out.svg";
import { ReactComponent as UserIcon } from "assets/images/icons/user-Regular.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useResponsive } from "hooks/useResponsive";
import styles from "./UserProfile.module.scss";

interface Props {
  redirectAfterLogout?: string;
}

export const UserProfile: FC<Props> = ({ redirectAfterLogout = "/" }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile } = useResponsive();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { user } = useAuth();
  const name =
    user && isTherapist(user)
      ? therapistName({
          title: user?.title,
          first_name: user?.user.first_name,
          last_name: user?.user.last_name,
        })
      : `${user?.user.first_name} ${user?.user.last_name}`;
  let avatarSrc;
  if (user) {
    if (isTherapist(user)) {
      avatarSrc = getAvatar(user.photos)?.file;
      if (!avatarSrc) {
        avatarSrc = avatar;
      }
    }
    else if (!isTherapist(user)) {
      avatarSrc = (user as Patient).file?.file;
      if (!avatarSrc) {
        avatarSrc = avatar;
      }
    }
  } else {
    avatarSrc = avatar;
  }

  return (
    <div className={styles.root}>
      <div
        className={styles.inner}
        onClick={() => setIsOpenMenu((prevState) => !prevState)}
      >
        <img
          src={avatarSrc}
          alt={therapistName({
            first_name: user?.user.first_name,
            last_name: user?.user.last_name,
          })}
        />
        <div className={styles.row}>
          <h3 className={styles.header}>
            {!isMobile && (
              <FormatProfileField maxLength={isMobile ? 8 : 30}>
                {name}
              </FormatProfileField>
            )}
            <div className={styles.modalTrigger}>
              <button>
                <Chevron />
              </button>
            </div>
          </h3>
          {user && isTherapist(user) && user?.company_name && !isMobile && (
            <small>
              <FormatProfileField maxLength={30}>
                {user?.company_name}
              </FormatProfileField>
            </small>
          )}
        </div>
      </div>
      {isOpenMenu && (
        <ul className={styles.modalList}>
          {!pathname.match("dashboard") && user?.user.status === "completed" && (
            <li>
              <button
                className={styles.modalButton}
                onClick={() =>
                  navigate(
                    `/${
                      user && isTherapist(user)
                        ? "therapists/dashboard"
                        : "patients/dashboard/my-account"
                    }`
                  )
                }
              >
                <UserIcon />
                Profile
              </button>
            </li>
          )}
          <li>
            <button
              className={styles.modalButton}
              onClick={async () => {
                await authService.logout();
                navigate(redirectAfterLogout);
              }}
            >
              <LogoutIcon />
              Log out
            </button>
          </li>
        </ul>
      )}
      {isOpenMenu && (
        <div className={styles.layout} onClick={() => setIsOpenMenu(false)} />
      )}
    </div>
  );
};

import { ReactComponent as Arrow } from "assets/images/icons/arrow.svg";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { Helmet } from "react-helmet";


export const MetaDescription = ( metaDescription : string) => { 
  let description = "Therapass";
  if (metaDescription && metaDescription.length > 1) {
    description = metaDescription;
  }
  
  const el = document.querySelector("meta[name='description']");
  if (el != null) { 
    el.setAttribute('content', description);
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Therapass" />
        <meta property="description" content='${metaDescription}' />
        <meta property="og:description" content='${metaDescription}' />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Additional Open Graph and Twitter card tags */}
      </Helmet>
      {/* Rest of your component */}
    </>
  );
};
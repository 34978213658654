import Button from "common/UIKit/Button";

import { useNavigate } from "react-router-dom";
import { patientsDashboardFullPath } from "../PatientsDashboard";
import { landlordFormsPath } from "../LandlordForms";

import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";
import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";
import document from "assets/images/icons/document.svg";

import styles from "./Letters.module.scss";
import { useSnapshot } from "valtio";
import { esaLettersService } from "services/esaLettersService";
import { useEffect, useState } from "react";
import { Spinner } from "common/components/Spinner";
import { esaProfileService } from "services/esaProfileService";
import { myAnimalsPath } from "../MyAnimals";
import { visitorIdService } from "services/VisitorIdService";

export const lettersPath = "/letters";

export const Letters = () => {
  const navigate = useNavigate();

  const { data, isFetchingEsa } = useSnapshot(esaLettersService);
  const { animals, client, profile } = useSnapshot(esaProfileService);
  const [ letters, isFetchingLetters ] = useState(false);
  
  useEffect(() => {
    if (!letters) {
      esaLettersService.loadAll();
      isFetchingLetters(true);
    }
    esaProfileService.getProfile();
    
    visitorIdService.trackEvent(
      "Letters",
      "visit",
      client?.clientid.toString() || "",
      "",
      "",
    );
  });

  if (isFetchingEsa) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }
  const getAnimalName = (clientAnimalId: number) => {
    const result = animals.find(({ClientAnimalID}) => {
      return ClientAnimalID === clientAnimalId;
    });
    return result?.AnimalName;
  }


  const onClick = (url: string, letterId: number) => {
    visitorIdService.trackEvent(
      "Letters",
      "open",
      letterId.toString() || "",
      client?.clientid.toString() || "",
      "",
    );
    window.open(url, "_blank");
    return;
  }

  return (
    <div className="default-typography">
      <h1 className="h3">Letters</h1>

      {/* <div className="mb-40">
        <Button
          text="Request Landlord Provided Form"
          onClick={() => navigate(`${patientsDashboardFullPath}${landlordFormsPath}`)}
          size="lg"
        />
      </div> */}

      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.table__cellWithRoundIcon}></th>
            <th className={styles.table__withArrow}>DATE <ArrowDown className={styles.table__sortArrow} /></th>
            <th>DOWNLOAD</th>
            <th>NAME</th>
            <th>LETTER TYPE</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((letter) => (
              <tr key={letter.LetterID}>
                <td>
                  <div className={styles.table__roundIcon}>
                    <img src={document} alt="Icon" />
                  </div>
                </td>
                <td>{new Date(letter.LetterDate).toLocaleDateString()}</td>
                <td className={styles.table__downloadCell}>
                  <Button className={styles.table__btnWithIcon} size="lg" onClick={() => {
                    onClick(letter.URL, letter.LetterID);
                  }}>
                    <DownloadIcon /> Download
                  </Button>
                </td>
                <td>
                  {/* <button className={styles.table__link} onClick={() => navigate(`${patientsDashboardFullPath}${myAnimalsPath}`)}>
                    {getAnimalName(letter.ClientAnimalID)}
                  </button> */}
                  {letter.CustomerName}
                </td>
                <td>{letter.LetterName}</td>
                <td>{letter.Status}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

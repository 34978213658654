import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import Button from "common/UIKit/Button";
import { Therapist } from "types/therapist.types";
import { useAuth } from "hooks/useAuth";
import { startCase } from "lodash";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./Availability.module.scss";

export const pathAvailability = "/availability";

export const Availability = () => {
  const { tabName, edit } = useDashboardNavigation("Availability", {
    edit: "append_edit",
    goBack: "pop_path",
  });
  const auth = useAuth();
  const user = auth.user as Therapist | null;

  const isAvailable = (currentAvailability: boolean | undefined) =>
    currentAvailability ? "Yes" : "No";

  return (
    <section className={styles.availability}>
      <h1 className={styles.availabilityTitle}>{tabName}</h1>
      <div className={styles.availabilityInner}>
        <div className={styles.availabilityInfo}>
          <div className={styles.availabilityBlock}>
            <p className={styles.availabilityBlockTitle}>
              Are you accepting new clients for in-person sessions?
            </p>
            <p className={styles.availabilityBlockText}>
              {isAvailable(user?.in_person_session_availability)}
            </p>
          </div>
          <div className={styles.availabilityBlock}>
            <p className={styles.availabilityBlockTitle}>
              Are you accepting new clients for telephone sessions?
            </p>
            <p className={styles.availabilityBlockText}>
              {isAvailable(user?.telephone_session_availability)}
            </p>
          </div>
          <div className={styles.availabilityBlock}>
            <p className={styles.availabilityBlockTitle}>
              Are you accepting new clients for video sessions?
            </p>
            <p className={styles.availabilityBlockText}>
              {isAvailable(user?.video_session_availability)}
            </p>
          </div>
          <div className={styles.availabilityBlock}>
            <p className={styles.availabilityBlockTitle}>
              Are you accepting new clients for online sessions?
            </p>
            <p className={styles.availabilityBlockText}>
              {isAvailable(user?.online_chat_sessions_availability)}
            </p>
          </div>
          <div className={styles.availabilityBlock}>
            <p className={styles.availabilityBlockTitle}>Chat status</p>
            <p className={styles.availabilityBlockText}>
              {startCase(user?.chat_status)}
            </p>
          </div>
          <div className={styles.availabilityBlock}>
            <p className={styles.availabilityBlockTitle}>
              Do you offer a free 15 minute intro call to discuss clients
              presenting?
            </p>
            <p className={styles.availabilityBlockText}>
              {isAvailable(user?.offer_free_call)}
            </p>
          </div>
        </div>
        <Button className={styles.availabilityButton} onClick={edit} size="lg">
          <EditIcon />
          Edit information
        </Button>
      </div>
    </section>
  );
};

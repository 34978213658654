import { FC, useEffect } from "react";
import cn from "classnames";
import Button from "common/UIKit/Button";
import { ProgressCircle } from "common/components/ProgressCircle";
import { ProfileCard } from "./ProfileCard";
import { ReactComponent as Users } from "assets/images/icons/3-user.svg";
import { ReactComponent as Message } from "assets/images/icons/message-thinner.svg";
import { ReactComponent as Chat } from "assets/images/icons/chat.svg";
import { useSnapshot } from "valtio";
import { plansService } from "services/plansService";
import { authService } from "services/authService";
import { Therapist } from "types/therapist.types";
import { therapistService } from "services/therapistService";
import { therapistName } from "common/utils";
import { FormatProfileField } from "common/components/FormatProfileField";
import { UserTestimonial } from "common/components/UserTestimonial";
import { ErrorPage } from "pages/ErrorPage";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./Overview.module.scss";

export const overviewPath = "/";

export const Overview: FC = () => {
  useDashboardNavigation("Overview", { isMainTab: true });
  const { stats } = useSnapshot(therapistService);
  const user = useSnapshot(authService).user as Therapist;
  useDocumentTitle("TheraPass - Overview");

  useEffect(() => {
    if (user?.id) {
      plansService.list();
      therapistService.getStatsById(user.id);
    }
  }, [user?.id]);

  if (!user) return <ErrorPage />;

  return (
    <div className={styles.overview}>
      <section className={styles.greeting}>
        <h1>
          <FormatProfileField maxLength={30}>
            {`Welcome, ${therapistName({
              title: user.title,
              last_name: user.user.last_name,
            })}`}
          </FormatProfileField>
        </h1>
        <small>Check the latest updates on your account</small>
      </section>
      <section className={styles.stats}>
        <div className={styles.statItem}>
          <div className={styles.statCircle}>
            <Users />
          </div>
          <div>
            <h1>{stats.total_contacts}</h1>
            <small>Contacts</small>
          </div>
        </div>
        <div className={styles.statItem}>
          <div className={styles.statCircle}>
            <Message />
          </div>
          <div>
            <h1>{stats.email}</h1>
            <small>Emails</small>
          </div>
        </div>
        <div className={styles.statItem}>
          <div className={styles.statCircle}>
            <Chat />
          </div>
          <div>
            <h1>{stats.sms}</h1>
            <small>Chat</small>
          </div>
        </div>
      </section>

      <section className={styles.cards}>
        <ProfileCard user={user} />
        <div className={cn(styles.card, styles.completionStatusCard)}>
          <div>
            <h2>Your profile is not complete</h2>
            <small>Complete the profile to increase your range.</small>
            <Button
              className={styles.completeButton}
              text="Complete profile"
              isLink
              to="profile"
              size="lg"
            />
          </div>
          <ProgressCircle progress={stats.profile_completion} />
        </div>
      </section>
      {/*<h2>Plans</h2>*/}
      <section className={styles.planCards}>
        {/*{subscriptions.length > 0 &&
          subscriptions.map((subscription) => (
            <PlanCard
              id={subscription.id}
              isSelected={subscription.id === user.subscription_plan.id}
              name={subscription.name}
              price={subscription.cost}
              key={subscription.id}
            />
          ))}*/}
        <div className={cn(styles.card, styles.mobileCompletionCard)}>
          <div>
            <h2>Your profile is not completed</h2>
            <Button
              className={styles.completeButton}
              text="Complete profile"
              isLink
              to="profile"
              size="lg"
            />
          </div>
          <ProgressCircle
            progress={stats.profile_completion}
            width={82}
            height={82}
            fontSize={20}
            strokeWidth={6}
          />
        </div>
      </section>
      <UserTestimonial />
    </div>
  );
};

import { useModal } from "hooks/useModal";
import { Modal } from "common/components/Modal";
import { ReactComponent as IconCross } from "assets/images/icons/close-icon.svg";
import avatar from "assets/images/avatar.png";
import styles from "./ModalAvatar.module.scss";

export const ModalAvatar = () => {
  const { isOpen, close, properties, clearProperties } =
    useModal("ZOOM_AVATAR");
  const file = typeof properties?.file === "object" ? properties?.file : null;

  const onClose = () => {
    close();
    clearProperties();
  };

  return (
    <Modal visible={isOpen} onClose={onClose}>
      <button onClick={onClose} className={styles.closeButton}>
        <IconCross />
      </button>
      <div className={styles.inner}>
        <img
          className={styles.image}
          alt={file?.type}
          src={file && !file?.mime.match("video") ? file?.file : avatar}
        />
      </div>
    </Modal>
  );
};

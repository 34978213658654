import { FC } from "react";
import Button from "common/UIKit/Button";
import { Modal } from "common/components/Modal";
import useVerificationSent from "./useVerificationSent";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import Input from "common/UIKit/Input";
import styles from "./VerificationSentPopup.module.scss";

export const VerificationSentPopup: FC = () => {
  const {
    isOpen,
    onClose,
    onSubmit,
    onResend,
    resendErrors,
    register,
    errors,
    newEmail,
  } = useVerificationSent();

  return (
    <Modal visible={isOpen} width={780}>
      <form onSubmit={onSubmit} className={styles.popup}>
        <button className={styles.popupCloseButton} onClick={onClose}>
          <CloseIcon />
        </button>
        <h3 className={styles.popupTitle}>Verification code has been sent</h3>

        <div className={styles.text}>
          We’ve just sent a verification email to{" "}
          <a
            href={`mailto:${newEmail}`}
            className={styles.link}
          >{`${newEmail}`}</a>
        </div>

        <div className={styles.text}>
          <span>Please enter the code to change your email.</span>
          Check your spam in case you can’t find it in your inbox. If you didn’t
          receive it, you can{" "}
          <button onClick={onResend} className={styles.link} type="button">
            resend email
          </button>
          .
          <p className="error_msg">
            {resendErrors.length > 0 &&
              resendErrors.map((error) => <span key={error}>{error}</span>)}
          </p>
        </div>
        <div className={styles.fieldSet}>
          <sub className={styles.popupSubtitle}>Verification code</sub>
          <Input
            className={styles.popupInput}
            {...register("code", {
              required: "This field is required.",
            })}
            error={errors.code?.message}
          />
          <Button className={styles.popupButton} type="submit" size="lg">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

import { authService } from "services/authService";

export const redirectTestPath = "/redirecttest";

export const RedirectTest = () => {

  const queryParams = new URLSearchParams(window.location.search);
  const clientId = queryParams.get("clientid")!;
  const testId = queryParams.get("testid")!;
  const email = queryParams.get("email")!;
  authService.FetchPatientTestToken(clientId, testId, email);


  return <></>;
};

import { Properties } from "types/modal.types";
import { proxy } from "valtio";

export type ModalName =
  | "CHANGE_PHONE_NUMBER"
  | "EMAIL_SUCCESS"
  | "MESSAGE"
  | "MESSAGE_SUCCESS"
  | "SHARE"
  | "WRONG_ROLE"
  | "LOGGED_OUT"
  | "LOGGED_OUT_PATIENT"
  | "CHANGE_EMAIL"
  | "CHANGE_EMAIL_SUCCESS"
  | "CHANGE_EMAIL_VERIFICATION"
  | "CTA_POPUP"
  | "CHOOSE_CREDIT_CARD"
  | "ADD_CREDIT_CARD"
  | "CANCEL_SUBSCRIPTION_PLAN"
  | "CHANGE_SUBSCRIPTION_SUCCESS"
  | "CARD_CODE"
  | "FILTER_TESTS"
  | "FILTER_MESSAGES"
  | "RATE_CARD_DETAILS"
  | "RATE_CARD_TYPE"
  | "RATE_CARD_DESCRIPTION"
  | "ZOOM_AVATAR"
  | "DISCONNECT_STRIPE"
  | "LOGGED_OUT_AND_BUY_RATECARD"
  | "VERIFY_PHONE_POPUP"
  | "LOGGED_AS_THERAPIST_BUY_RATE_CARD"
  | "LOGGED_AS_THERAPIST"
  | "CHANGE_PHONE_NUMBER_IN_PROFILE"
  | "LOGGED_AS_THERAPIST_BUY_TEST"
  | "CLAIM_PROFILE"
  | "DATE_TIME_PICKER";

class ModalService {
  currentModal: ModalName | null = null;
  properties: Properties = {};

  open(name: ModalName) {
    this.currentModal = name;
  }

  close(name: ModalName) {
    if (name === this.currentModal) this.currentModal = null;
  }

  closeAll() {
    this.currentModal = null;
    this.clearProperties();
  }

  addProperties<T extends Properties>(props: T) {
    this.properties = props;

    return this.properties;
  }

  clearProperties() {
    this.properties = {};

    return this.properties;
  }
}

export const modalService = proxy(new ModalService());

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || 'https://api.therapass.com/api';
export const esaApiEndpoint = process.env.ESA_API_ENDPOINT || 'https://esaapi.therapass.com';

export const isDev = process.env.NODE_ENV === "development";
export const isProd = process.env.NODE_ENV === "production";

export const googleOauthClientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || "39018734169-un6kucvmetn1u407nv0jplnv272aokbp.apps.googleusercontent.com";
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyBWTtzXMxoDfNeXMyFo9OWkN9w3ZLxu9wo";
export const googleReCaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || "6LdGsKYfAAAAAEqykY6e7hTK4k_9RY1-v3nObKyA";

export const sentryEnvironment = process.env.REACT_APP_SENTRY_ENV || "";
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN || "";

export const authorizeNetSandbox = process.env.REACT_APP_AUTHORIZENET_SANDBOX || "";
export const authorizeNetApiLogin = process.env.REACT_APP_AUTHORIZENET_API_LOGIN || "";
export const authorizeNetPublicKey = process.env.REACT_APP_AUTHORIZENET_PUBLIC_KEY || "";

export const datadogApplicationId  = process.env.REACT_APP_RUM_APP_ID || ""
export const datadogClientToken  = process.env.REACT_APP_RUM_CLIENT_TOKEN || ""
export const datadogService  = process.env.REACT_APP_RUM_SERVICE || ""
export const datadogEnv  = process.env.REACT_APP_RUM_ENV || ""
export const uptrendsEnv = process.env.REACT_APP_UPTRENDS_SID || ""

export const hitstepsSrc = isProd ? process.env.REACT_APP_HITSTEPS_CODE : '';
import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";

import { testResultLevelToColor } from "common/utils";

import { testAttemptService } from "services/testAttemptService";
import { testProcessService } from "services/testProcessService";

import { useResponsive } from "hooks/useResponsive";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";

import Button from "common/UIKit/Button";
import { EditHeader } from "common/components/EditHeader";
import { TestScore } from "common/components/TestScore";

import testImage from "assets/images/test.svg";
import { ReactComponent as LightBulb } from "assets/images/icons/lightbulb.svg";

import { testsPreparePath } from "../TestPrepare";

import styles from "./TestResult.module.scss";
import classNames from "classnames";
import { esaStep1FullPath } from "pages/Patients/PatientSteps/EsaStep1";

export const testsResultPath = "/result";

interface Props {
  isIntake: boolean;
}

export const TestResult = ({ isIntake } : Props) => {
  const { test } = useSnapshot(testProcessService);
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  useDashboardNavigation(test?.name || "Unknown Test", {
    isNavigationHidden: true,
    goBack: () => navigate("./../.."),
  });
  const { entity: currentAttempt } = useSnapshot(testAttemptService);

  const takeTestAgain = async () => {
    if (!currentAttempt) return;

    navigate(`../../new${testsPreparePath}?test_id=${currentAttempt.test.id}`);
  };

  if (!currentAttempt) return null;

  const isRawText = currentAttempt.result_details?.result_type === "raw_text";

  return (
    <div>
      <div className="default-typography">
        {/* <EditHeader withoutBack /> */}

        <h2 className="text-center mb-35">Results</h2>

        <div className={styles.resultBlock}>
          <div className={styles.resultBlock__scoreBlock}>
            {!isRawText && (
              <TestScore
                score={currentAttempt.result_details?.level || 1}
                maxScore={currentAttempt.result_details?.total_levels || 1}
                radius={180}
                segmentWidth={16}
                segmentSeparation={0.5}
                backdropRadius={isMobile ? 75 : 120}
                className={styles.speedometer}
              />
            )}

            <div className={styles.resultBlock__scoreBlockValues}>
              {!isRawText && (
                <p style={{
                    color: testResultLevelToColor(
                      currentAttempt.result_details?.level || 1,
                      currentAttempt.result_details?.total_levels || 1
                    ),
                  }}
                className={classNames("h1", "mb-5", styles.resultBlock__scoreBlockNumber)}>{currentAttempt.result}</p>
              )}

              <h4 className={classNames("mb-0", styles.resultBlock__scoreBlockName)}>{currentAttempt.result_details?.name}</h4>
            </div>
          </div>

          {currentAttempt.result_details?.note && (
            <div className="text-block mb-20">
              <p>{currentAttempt.result_details?.note}</p>
            </div>
          )}

          <div className={styles.resultBlock__noteBlock}>
            <div className={styles.resultBlock__noteBlockIcon}>
              <span className="icomoon-lamp"></span>
            </div>
            <div className="row gutter-18">
              <div className="col mb-30 mb-sm-0">
                <h6>Note</h6>
                <div className="text-block text-sm">
                  <p>To get a description of this result, contact your doctor, who will tell you in detail what you need to do.</p>
                </div>
              </div>

              <div className="col-sm-auto d-flex align-items-center">
                <img src={testImage} alt="Test" className={styles.resultBlock__noteBlockImg} />
              </div>
            </div>
          </div>

          <div className="pt-40 text-center">
            { Number(currentAttempt?.test.cost) === 0 && !isIntake && (
              <Button
                className="mb-20"
                text="Take the test again"
                color="green-light"
                onClick={takeTestAgain}
                size="lg"
              />
            )}
            <Button text={isIntake ? "Continue" : "Back to tests"} size="lg" onClick={() => {
              if (isIntake){
                navigate(esaStep1FullPath);
              } else {
                navigate("./../..");
              }
            }} />
          </div>
        </div>
      </div>
    </div>
  );
};

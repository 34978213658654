import Radiobutton from "common/UIKit/Radiobutton/Radiobutton";
import { stepsUi } from "../components";
import { stepsFullPath } from "../stepsPath";
import { step5FullPath } from "../PatientStep5";
import { usePatientStep6 } from "./usePatientStep6";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { ETHNICITY_OPTIONS } from "constants/options";
import { useResponsive } from "hooks/useResponsive";
import "./PatientStep6.scss";

export const step6Path = "/6";
export const step6FullPath = [stepsFullPath, step6Path].join("");

export function PatientStep6() {
  const { isMobile } = useResponsive();
  const { isSubmitting, ethnicity, error, handleChange, handleSubmit } =
    usePatientStep6();
  return (
    <>
      <div className="text-center">
      <stepsUi.Header>
        Do you prefer a therapist who works with a specific ethnicity?
      </stepsUi.Header>
        <br />
        <stepsUi.Hint>Select one</stepsUi.Hint>
      </div>
      <br />
      <stepsUi.Form
        handleSubmit={handleSubmit}
        maxWidth="304px"
        className="patient-step-4"
        actions={
          <stepsUi.Actions
            buttons={[
              {
                type: "button",
                children: "Back",
                className: "btn-color-blue",
                isLink: true,
                to: step5FullPath,
              },
              {
                type: "submit",
                children: isMobile ? "Continue" : "Next",
                isLoading: isSubmitting,
              },
            ]}
          />
        }
      >
        {ETHNICITY_OPTIONS.map((item) => (
          <Radiobutton
            key={item.value}
            className="patient-step__radio"
            name="ethnicity"
            id={item.value}
            checked={ethnicity === item.value}
            isOutlined={false}
            onChange={handleChange}
          >
            {item.label}
          </Radiobutton>
        ))}
      </stepsUi.Form>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}

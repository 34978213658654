import cn from "classnames";
import Button from "../../../../UIKit/Button";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import styles from "./ChatStatusButton.module.scss";

const ChatStatusButton = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth.user as Therapist | null;
  return (
    <Button
      onClick={() => navigate("settings/availability")}
      className={styles.chatStatus}
      size="lg"
    >
      Chat Status:{" "}
      <div
        className={cn(
          styles.circle,
          user?.chat_status === "wait" && styles.yellow,
          user?.chat_status === "available" && styles.green,
          user?.chat_status === "offline" && styles.red,
          !user?.online_chat_sessions_availability && styles.grey
        )}
      ></div>
      <p>
        {user?.online_chat_sessions_availability
          ? user?.chat_status
          : "Disabled"}
      </p>
    </Button>
  );
};

export default ChatStatusButton;

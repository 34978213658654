import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  CreateLocalTrackOptions,
  ConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
} from "twilio-video";
import { SelectedParticipantProvider } from "./useSelectedParticipant";

import AttachVisibilityHandler from "./AttachVisibilityHandler";
import useHandleRoomDisconnection from "./useHandleRoomDisconnection";
import useHandleTrackPublicationFailed from "./useHandleTrackPublicationFailed";
import useLocalTracks from "./useLocalTracks";
import useRestartAudioTrackOnDeviceChange from "./useRestartAudioTrackOnDeviceChange";
import useRoom from "./useRoom";
import useScreenShareToggle from "./useScreenShareToggle";
import { SessionMeta } from "types/clientVideo.types";
import axios from "axios";
import { esaApiEndpoint } from "utils/env";

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room | null;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string, roomName: string) => Promise<void>;
  onError: ErrorCallback;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions
  ) => Promise<LocalVideoTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
  isBackgroundSelectionOpen: boolean;
  setIsBackgroundSelectionOpen: (value: boolean) => void;
  sessionMeta?: SessionMeta;
  isTherapist?: boolean;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  children: ReactNode;
  sessionId: string;
  isTherapist: boolean;
}

export function VideoProvider({
  options,
  children,
  onError = () => {},
  sessionId,
  isTherapist,
}: VideoProviderProps) {
  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      console.log(`ERROR: ${error.message}`, error);
      onError(error);
    },
    [onError]
  );

  const [sessionMeta, setSessionMeta] = useState<SessionMeta>();
  useEffect(() => {
    axios
      .get(`${esaApiEndpoint}/videoSession/GetSession/${sessionId}`)
      .then((response) => {
        const data = response.data;
        const address = data.Client.Addresses[0];
        const formatAddr = `${address.Address1}${
          address.Address2 ? ` ${address.Address2}` : ""
        }, ${address.City} ${address.State}, ${address.Zip}`;
        const meta = {
          therapistName: data.Therapist.Name,
          clientName: data.Client.FullName,
          clientEmail: data.Client.Email,
          clientPhoneNumber: data.Client.PhoneNumber,
          clientAddress: formatAddr,
          animals: data.Client.Animals.map((animal: any) => ({
            name: animal.AnimalName,
            animalType: animal.AnimalTypeName,
            breedName: animal.BreedName,
            gender: animal.Gender,
          })),
        };
        setSessionMeta(meta);
      });
  }, [sessionId]);

  const {
    localTracks,
    getLocalVideoTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(
    localTracks,
    onErrorCallback,
    options
  );

  const unmountingVideoProvider = useRef(false);
  useEffect(() => {
    return () => {
      unmountingVideoProvider.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (unmountingVideoProvider.current) {
        const tracks = localTracks;
        tracks.forEach((track) => {
          track.stop();
        });
      }
    };
  }, [localTracks]);

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    room,
    onError
  );

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare
  );
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] =
    useState(false);
  const videoTrack = localTracks.find(
    (track) => !track.name.includes("screen") && track.kind === "video"
  ) as LocalVideoTrack | undefined;

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        isSharingScreen,
        toggleScreenShare,
        getAudioAndVideoTracks,
        isBackgroundSelectionOpen,
        setIsBackgroundSelectionOpen,
        sessionMeta,
        isTherapist,
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}

import { FC } from "react";

import { FormatProfileField } from "common/components/FormatProfileField";
import { useAuth } from "hooks/useAuth";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { Patient } from "types/patient.types";

import styles from "./PersonalInformationMobile.module.scss";
import Button from "common/UIKit/Button";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { GENDER_OPTIONS, PREFIX_OPTIONS } from "constants/options";

interface Props {
  onEdit: () => void;
  onEditBilling: () => void;
  onEditShipping: () => void;
}

export const PersonalInformationMobile: FC<Props> = ({
  onEdit,
  onEditBilling,
  onEditShipping,
}) => {
  useDashboardNavigation("Personal Information", {
    goBack: "pop_path",
    edit: "append_edit",
  });
  const user = useAuth().user as Patient;

  return (
    <div className={styles.personalInformationMobile}>
      <div className="mb-50">
        <div className="row remove-last-child-margin">
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Prefix</h3>
              <p>
                <FormatProfileField>
                  {PREFIX_OPTIONS.find((o) => o.value === user?.user.prefix)?.label || ""}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>First Name</h3>
              <p>
                <FormatProfileField maxLength={40}>
                  {`${user?.user.first_name}`}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Last Name</h3>
              <p>
                <FormatProfileField maxLength={40}>
                  {`${user?.user.last_name}`}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Email Address</h3>
              <p>
                <FormatProfileField shouldLimitLength={false}>
                  {user?.user.email}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Mobile Phone</h3>
              <p>
                <FormatProfileField>{user?.user.mobile_phone}</FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Mental Health Role</h3>
              <p>
                <FormatProfileField>Patient</FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Gender</h3>
              <p>
                <FormatProfileField>
                  {GENDER_OPTIONS.find((o) => o.value === user?.user.gender)?.label}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Date of Birth</h3>
              <p>
                <FormatProfileField>
                  {user?.user.dob}
                </FormatProfileField>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-50">
        <h2 className={styles.personalInformationMobile__blockTitle}>Billing Address</h2>

        <div className="mb-20">
          <Button className={styles.editButton} onClick={onEditBilling} size="lg">
            <EditIcon /> Edit
          </Button>
        </div>

        <div className="row remove-last-child-margin">
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Street Address 1</h3>
              <p>
                <FormatProfileField maxLength={40}>
                  {user?.user.billing_address_1}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Street Address 2</h3>
              <p>
                <FormatProfileField>
                  {user?.user.billing_address_2}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>City</h3>
              <p>
                <FormatProfileField>
                  {user?.user.billing_city}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>State</h3>
              <p>
                <FormatProfileField>
                  {user?.user.billing_state}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Zip</h3>
              <p>
                <FormatProfileField>
                  {user?.user.billing_zip}
                </FormatProfileField>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-50">
        <h2 className={styles.personalInformationMobile__blockTitle}>Shipping Address</h2>

        <div className="mb-20">
          <Button className={styles.editButton} onClick={onEditShipping} size="lg">
            <EditIcon /> Edit
          </Button>
        </div>

        <div className="row remove-last-child-margin">
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Street Address 1</h3>
              <p>
                <FormatProfileField>
                  {user?.user.shipping_address_1}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Street Address 2</h3>
              <p>
                <FormatProfileField>
                  {user?.user.shipping_address_2}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>City</h3>
              <p>
                <FormatProfileField>
                  {user?.user.shipping_city}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>State</h3>
              <p>
                <FormatProfileField>
                  {user?.user.shipping_state}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>Zip</h3>
              <p>
                <FormatProfileField>
                  {user?.user.shipping_zip}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-35">
              <h3>The same as Billing Address</h3>
              <p>
                <FormatProfileField>
                  {user?.user.is_billing_same_as_shipping ? "Yes" : "No"}
                </FormatProfileField>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from 'react';

export default function VideoAvatarIcon() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="27.9993" cy="13.9998" rx="9.33333" ry="9.33333" fill="#1F342F"/>
      <path d="M49 45.7603C49 53.191 7 53.191 7 45.7603C7 35.9516 16.402 28 28 28C39.598 28 49 35.9516 49 45.7603Z" fill="#1F342F"/>
    </svg>
  );
}

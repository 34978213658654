import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";

import Button from "common/UIKit/Button";
import { Block } from "../components/Block";
import { testProcessService } from "services/testProcessService";
import { useTest } from "hooks/useTest";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { testsResultPath } from "../TestResult";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import styles from "./TestStart.module.scss";
import { useState } from "react";
import { TestBlock } from "types/testProcess.types";

export const testsStartPath = "/start";

export const TestStart = () => {
  const { test } = useSnapshot(testProcessService);
  const navigate = useNavigate();
  useDashboardNavigation(test?.name || "Unknown Test", {
    isNavigationHidden: true,
    goBack: () => navigate("./../.."),
  });
  const { isSubmitting, getSubmitFunction, errors } = useTest();

  const nonIntroBlocks = test?.blocks.filter((block) => !block.is_intro);

  const handleSubmit = getSubmitFunction("finished", () => {
    navigate(`..${testsResultPath}`);
  });

  const [blockNum, setBlockNum] = useState(0);
  const [questionNum, setQuestionNum] = useState(0);
  const [questionsAnswered, setquestionsAnswered] = useState<
    Map<string, boolean>
  >(new Map<string, boolean>());
  const currentBlock = nonIntroBlocks ? nonIntroBlocks[blockNum] : null;

  const onNext = (block: TestBlock) => {
    if (nonIntroBlocks?.length) {
      if (questionNum < block.questions.length - 1) {
        setQuestionNum(questionNum + 1);
      } else {
        if (blockNum < nonIntroBlocks?.length - 1) {
          setBlockNum(blockNum + 1);
          setQuestionNum(0);
        }
      }
    }
  };

  const onBack = (block: TestBlock) => {
    if (nonIntroBlocks?.length) {
      if (questionNum === 0 && blockNum > 0) {
        const prevBlock = nonIntroBlocks[blockNum - 1];
        setBlockNum(blockNum - 1);
        setQuestionNum(prevBlock.questions.length - 1);
      } else if (questionNum > 0) {
        setQuestionNum(questionNum - 1);
      }
    }
  };

  const isLastQuestion =
    currentBlock &&
    blockNum + 1 === nonIntroBlocks?.length &&
    questionNum === currentBlock.questions.length - 1
      ? true
      : false;

  return (
    <form onSubmit={handleSubmit}>
      {/* <EditHeader withoutBack /> */}

      {errors.global && <ErrorMessage>{errors.global.message}</ErrorMessage>}

      {currentBlock && (
        <div className="mb-30 remove-last-child-margin">
          <Block
            block={currentBlock}
            key={currentBlock.id}
            questionNum={questionNum}
            questionsAnswered={questionsAnswered}
            setquestionsAnswered={setquestionsAnswered}
          />
        </div>
      )}
      {currentBlock && isLastQuestion && (
        <div className={styles.rowButtons}>
          <Button
            color="green-light"
            onClick={() => {
              onBack(currentBlock);
            }}
            disabled={questionNum === 0 && blockNum === 0}
            size="lg"
          >
            Back
          </Button>
          <Button
            type="submit"
            text="Finish"
            disabled={
              !questionsAnswered.get(currentBlock.questions[questionNum].key)
            }
            isLoading={isSubmitting}
            size="lg"
          />
        </div>
      )}
      {currentBlock && !isLastQuestion && (
        <div className={styles.rowButtons}>
          <Button
            color="green-light"
            onClick={() => {
              onBack(currentBlock);
            }}
            disabled={questionNum === 0 && blockNum === 0}
            size="lg"
          >
            Back
          </Button>
          <Button
            onClick={() => {
              onNext(currentBlock);
            }}
            disabled={
              !questionsAnswered.get(currentBlock.questions[questionNum].key)
            }
            size="lg"
          >
            Next
          </Button>
        </div>
      )}
    </form>
  );
};

import { useSnapshot } from "valtio";
import { InputSmartSearch } from "common/UIKit/InputSmartSearch";
import { therapistListingService } from "services/therapistListingService";
import styles from "./Search.module.scss";
//import { useResponsive } from "hooks/useResponsive";
//import { SortByDistance } from "../SortByDistance";

export const Search = () => {
	const { search } = useSnapshot(therapistListingService);
	//const { isMobile } = useResponsive();

	const handleSelectPlace = (args: {
		search?: string;
		city?: string;
		state?: string;
		zip?: string;
		latitude?: number;
		longitude?: number;
	}) => {
		therapistListingService.setPlaceFilter(args);
	};

	return (
		<div className={styles.root}>
			<InputSmartSearch
				placeholder="Zip / City"
				value={search || ""}
				className={styles.container}
				optionsClassName={styles.options}
				onSelectPlace={handleSelectPlace}
			/>
			{/*{!isMobile && <SortByDistance />}*/}
		</div>
	);
};

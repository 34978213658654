import { AccessController } from "common/components/Layout/AccessController";
import { useAuth } from "hooks/useAuth";
import { CTA } from "./Auth/CTA";
import { useDocumentTitle } from "hooks/useDocumentTitle"; 
import { MetaDescription } from "common/components/MetaDescription";

export const homePath = "/";

export const HomePage = () => {
  const { user } = useAuth();
  useDocumentTitle("Therapass");
  MetaDescription("TheraPass is an on demand mental health and wellness platform connecting people from all walks of life with the licensed professional therapist of their choice.");
  if (!user) {
    return <CTA />;
  }

  return (
    <AccessController therapist="allowed" patient="allowed">
      <CTA />
    </AccessController>
  );
};

import { proxy } from "valtio";
import { LetterMeta } from "types/letters.types";
import { esaTokenService } from "./esaTokenService";
import axios, { AxiosError } from "axios";
import { esaApiEndpoint } from "../utils/env";
import { generalLogicService } from "./generalLogicService";
import { LETTER_TYPES } from "constants/options";

class EsaLettersService {
  isLoaded: boolean = false;
  isFetchingEsa: boolean = false;
  data: LetterMeta[] = [];

  handleError = (error: AxiosError) => {
    if (error.response) {
      const { status, data } = error.response;
      const errorMessage =  error.message;

      const serverError = status >= 500;
      if (serverError) {
        generalLogicService.setError({
          status,
          message: errorMessage,
          type: "critical",
        });
      }
    }
  }

  async loadAll(){
    if (!this.isLoaded) {
      this.isFetchingEsa = true;
      const letters = (await axios.get(
        `${esaApiEndpoint}/Client/getletters?token=${esaTokenService.get()}`
      )).data["letters"] as LetterMeta[];
      this.data = letters;
      this.isLoaded = true;
      this.isFetchingEsa = false;
    }
  }
}

export const esaLettersService = proxy(new EsaLettersService());

import useDevices from "hooks/useDevices";
import useLocalVideoToggle from "hooks/useLocalVideoToggle";
import { useCallback, useRef } from "react";
import  styles  from "./ToggleVideoButton.module.scss";
import cameraOffIcon from "./camera.svg";
import cameraOnIcon from "./video-solid.svg";

export default function ToggleVideoButton(props: {
  disabled?: boolean;
  className?: string;
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <button className={styles.bottomIconDiv}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || props.disabled}
    >
      {isVideoEnabled
        ? <>
            <img className={styles.bottomIcon} src={cameraOffIcon} alt="" />
            <span className={styles.bottomText}>Stop</span>
          </>
        : <>
            <img className={styles.bottomIcon} src={cameraOnIcon} alt="" />
            <span className={styles.bottomText}>Start</span>
          </>
      }
    </button>
  );
}

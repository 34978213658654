import { Link } from "react-router-dom";
import { Modal } from "common/components/Modal";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { capitalize } from "lodash";
import { useModal } from "hooks/useModal";
import { UserType } from "types/auth.types";

import styles from "./WrongRoleModal.module.scss";

type Props = {
  userType: UserType;
};

export const WrongRoleModal = ({ userType }: Props) => {
  const { isOpen, close } = useModal("WRONG_ROLE");
  const oppositeUserType = userType === "patient" ? "therapist" : "patient";

  return (
    <Modal visible={isOpen} onClose={close} width={485} >
      <div className={styles.modal}>
        <button className={styles.modal_close_button} onClick={close}>
          <CloseIcon />
        </button>
        <h2>Oops!</h2>
        <p>
          You are trying to login as {capitalize(userType)}, please use{" "}
          <Link to={`/${oppositeUserType}/login`}>
            {capitalize(oppositeUserType)} login page
          </Link>
        </p>
      </div>
    </Modal>
  );
};

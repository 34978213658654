import React from "react";
import cn from "classnames";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import PinIcon from "./PinIcon/PinIcon";
import styles from "./ParticipantInfo.module.scss";
import VideoAvatarIcon from "icons/VideoAvatarIcon";
import usePublications from "hooks/usePublications";
import useIsTrackSwitchedOff from "hooks/useIsTrackSwitchedOff";
import useTrack from "hooks/useTrack";
import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
  isDominantSpeaker,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const videoPublication = publications.find(
    (p) => !p.trackName.includes("screen") && p.kind === "video"
  );

  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  return (
    <div
      className={cn(styles.participantInfo, {
        [styles.hideParticipant]: hideParticipant,
        [styles.cursorPointer]: Boolean(onClick),
        [styles.dominantSpeaker]: isDominantSpeaker,
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
    >
      {/* <AudioLevelIndicator audioTrack={audioTrack} className={styles.participantInfo__audioLevelIndicator} />
      <p className={styles.participantInfo__name}>You</p> */}

      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <VideoAvatarIcon />
      )}

      {!isVideoEnabled && (
        <div className={styles.participantInfo__videoIndicator}>
          <span className="icomoon-camera-off"></span>
        </div>
      )}

      {/* <NetworkQualityLevel participant={participant} /> */}
      {/* <div>{isSelected && <PinIcon />}</div> */}

      {children}
    </div>
  );
}

import { FC, useEffect } from "react";
import Button from "common/UIKit/Button";
import { IconCheckbox } from "common/components/IconCheckbox";
import { useAuth } from "hooks/useAuth";
import { Patient } from "types/patient.types";
import { usePatientsInformation } from "./usePatientsInformation";
import { MyAccountLayout } from "../MyAccountLayout";
import { FormatProfileField } from "common/components/FormatProfileField";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { patientService } from "services/patientService";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./PatientsInformation.module.scss";

export const patientInformationPath = `/patient-information`;

export const PatientsInformation: FC = () => {
  const { edit } = useDashboardNavigation("Patient's Information", {
    goBack: "pop_path",
    edit: "append_edit",
  });
  const auth = useAuth();
  const user = auth.user as Patient;
  const {
    therapyType,
    experience,
    preference,
    age,
    price,
    ethnicity,
    sexuality,
    language,
    faith,
    appointmentDate,
  } = usePatientsInformation(user);

  useEffect(() => {
    patientService.getById(user.id);
  }, []);

  return (
    <MyAccountLayout>
      <div className={styles.patientsInformation}>
        <div className={styles.card}>
          <div className={styles.fields}>
            <div className={styles.field}>
              <h3 className={styles.title}>Zip code</h3>
              <p className={styles.value}>
                <FormatProfileField>{user.zip_code}</FormatProfileField>
              </p>
            </div>
            <div className={styles.field}>
              <h3 className={styles.title}>I’d like to do therapy via:</h3>
              <FormatProfileField objectToCheck={therapyType}>
                <div className={styles.value}>
                  {therapyType.map((option) => (
                    <IconCheckbox
                      key={option?.value}
                      name={option?.value || ""}
                      label={option?.label || ""}
                    />
                  ))}
                </div>
              </FormatProfileField>
            </div>
            <div className={styles.field}>
              <h3 className={styles.title}>
                I’d prefer a therapist with experience in:
              </h3>
              <p className={styles.value}>
                <FormatProfileField shouldLimitLength={false}>
                  {experience.join(", ")}
                </FormatProfileField>
              </p>
            </div>
            {/*<div className={styles.field}>*/}
            {/*  <h3 className={styles.title}>Therapist’s preference</h3>*/}
            {/*  <FormatProfileField shouldLimitLength={false}>*/}
            {/*    {preference?.map((pref) => (*/}
            {/*      <p className={styles.value} key={pref?.value}>*/}
            {/*        {pref?.label}*/}
            {/*      </p>*/}
            {/*    ))}*/}
            {/*  </FormatProfileField>*/}
            {/*</div>*/}
            <div className={styles.field}>
              <h3 className={styles.title}>Patient’s age</h3>
              <div className={styles.ageCard}>
                {age?.icon}
                <h3>{age?.label}</h3>
                <p>{age?.subLabel}</p>
              </div>
            </div>
            <div className={styles.field}>
              <h3 className={styles.title}>My budget per session</h3>
              <p className={styles.value}>{price?.label}</p>
            </div>
            <div className={styles.field}>
              <h3 className={styles.title}>
                Do you prefer a therapist who works with a specific ethnicity?
              </h3>
              <p className={styles.value}>{ethnicity?.label}</p>
            </div>
            <div className={styles.field}>
              <h3 className={styles.title}>
                Do you prefer a therapist who works with patients of a certain
                sexuality?
              </h3>
              <p className={styles.value}>
                <FormatProfileField shouldLimitLength={false}>
                  {sexuality.map((option) => option?.label).join(", ")}
                </FormatProfileField>
              </p>
            </div>
            <div className={styles.field}>
              <h3 className={styles.title}>
                Do you prefer a therapist who speaks a language other than
                English?
              </h3>
              <p className={styles.value}>
                <FormatProfileField shouldLimitLength={false}>
                  {language.join(", ")}
                </FormatProfileField>
              </p>
            </div>
            <div className={styles.field}>
              <h3 className={styles.title}>Faith</h3>
              <p className={styles.value}>{faith?.label}</p>
            </div>
            <div className={styles.field}>
              <h3 className={styles.title}>
                When would you like to talk to a therapist?
              </h3>
              <p className={styles.value}>{appointmentDate?.label}</p>
            </div>
          </div>
          <Button className="mt-20" onClick={edit} size="lg">
            <EditIcon /> Edit information
          </Button>
        </div>
      </div>
    </MyAccountLayout>
  );
};

import cn from "classnames";
import { DashboardLayoutDesktopHeader } from "common/components/DashboardLayout/DesktopHeader";
import { DashboardLayoutMobileHeader } from "common/components/DashboardLayout/MobileHeader";
import Button from "common/UIKit/Button";
import { useWindowResize } from "hooks/useWindowResize";
import { Block } from "pages/Patients/PatientsDashboard/Tests/TakeTest/components/Block";
import { TestProvider } from "providers/TestProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { testProcessService } from "services/testProcessService";
import { patientProfileService } from "services/patientProfileService";
import { useEffect } from "react";
import { therapistName } from "common/utils";
import { useAuth } from "hooks/useAuth";
import { isPatient } from "services/authService";
import { testAttemptService } from "services/testAttemptService";
import { format } from "date-fns";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./TherapistPatientTest.module.scss";
import { TestQuestion, TestRadio } from "types/testProcess.types";

export const TherapistPatientTest = () => {
  const navigate = useNavigate();
  const { test } = useSnapshot(testProcessService);
  const { entity } = useSnapshot(testAttemptService);
  const { userId } = useParams();
  const { user } = useAuth();
  const { currentProfile } = useSnapshot(patientProfileService);
  const { width } = useWindowResize();
  const isSmallWindow = width < 1200;
  const nonIntroBlocks = test?.blocks;
  const id = user && isPatient(user) ? user.id : userId;
  useDocumentTitle("TheraPass - Test");

  useEffect(() => {
    if (id) {
      patientProfileService.getById(id);
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  const findAnswer = (question: TestQuestion, type: string) => {
    const answer = entity?.test_answers?.find(
      (answer) => answer.question === question.id
    );
    if (type === "text") {
      if (question.type === "radio") {
        const option = (question as TestRadio).options.find(
          (option) => option.value === answer?.value
        );
        return option?.label;
      }
    } else {
      return answer?.value;
    }
    return "";
  };

  return (
    <TestProvider>
      <div className={styles.root}>
        {!isSmallWindow ? (
          <DashboardLayoutDesktopHeader withLogo className={styles.logo} />
        ) : (
          <DashboardLayoutMobileHeader tabs={[]} />
        )}
        <section className="default-typography">
          <div className={styles.testDetailBlock}>
            <div className="row align-items-center mb-25">
              <div className="col">
                <h2 className="mb-25 mb-xs-0">Somatic Symptom</h2>
              </div>
              <div className="col-xs-auto">
                <p
                  className={cn(
                    styles.testDetailBlock__totalBlock,
                    "text-xl text-gray mb-0"
                  )}
                >
                  <span className="d-inline-block pe-lg-20">Result</span>
                  <span className="d-block d-lg-inline"></span>
                  <span className="h2 text-teal-dark mb-0 pe-5">57.6</span>
                  <span className="text-teal-dark">Midl</span>
                </p>
              </div>
            </div>
            {entity?.finished_at && (
              <time className={styles.date}>
                {`Completed: ${format(
                  new Date(entity.finished_at),
                  "MMMM d, yyyy h:mma"
                )}`}
              </time>
            )}
            {user && !isPatient(user) && (
              <p className={styles.text}>
                Answers of{" "}
                {therapistName({
                  first_name: currentProfile?.user.first_name,
                  last_name: currentProfile?.user.last_name,
                })}
                :
              </p>
            )}

            <ul className={styles.testDetailBlock__list}>
              {nonIntroBlocks?.map((block) =>
                block.questions.map((question) => (
                  <li
                    className={styles.testDetailBlock__listItem}
                    key={question.id}
                  >
                    <h6 className="mb-10 fw-semibold pe-xs-85">
                      {question.question}
                    </h6>
                    <div className="row">
                      <div className="col">
                        <div className="text-block text-sm text-gray pe-xs-35">
                          <p>{findAnswer(question, "text")}</p>
                        </div>
                      </div>
                      <div className="col-auto">
                        <p
                          className={cn(
                            styles.testDetailBlock__listItemResult,
                            "mb-0 text-sm text-gray"
                          )}
                        >
                          {findAnswer(question, "value")}
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
          <br />
          <Button
            className={styles.btn}
            type="button"
            text={
              user && isPatient(user)
                ? "Return to Tests"
                : "Return to Patient Details"
            }
            onClick={() => navigate(-1)}
            size="lg"
          />
        </section>
      </div>
    </TestProvider>
  );
};

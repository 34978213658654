import axios, { AxiosError } from "axios";
import { esaApiEndpoint } from "utils/env";
import { UpdateSessionStatusArgs, get_participant_status_result } from "types/VideoChatParticipant.types";
import { generalLogicService } from "./generalLogicService";


class VideoChatParticipantStatusService { 

	handleError = (error: AxiosError) => {
		if (error.response) {
		  const { status, data } = error.response;
		  const errorMessage = error.message;
	
		  const serverError = status >= 500;
		  if (serverError) {
			generalLogicService.setError({
			  status,
			  message: errorMessage,
			  type: "critical",
			});
		  }
		}
	  }
async getSessionStatus(sessionId: string): Promise<get_participant_status_result> {
	const response = await axios.get<get_participant_status_result>(`${esaApiEndpoint}/videoSession/GetParticipantStatus/${sessionId}`);
	return response.data;
} 
async setSessionStatus(args: UpdateSessionStatusArgs){
	console.log("setSessionStatus");
	const payload = JSON.stringify(args);
    const meta = {
		headers: {
		  'Content-Type': 'application/json'
		}
	  };
 
	const response = await axios.post(
		`${esaApiEndpoint}/videoSession/SetParticipantStatus`,
		payload,
		meta
	  ).catch((error)=>{
		this.handleError(error);
	  });
	}
 
} 
export const videoChatParticipantStatusService = new VideoChatParticipantStatusService();



import { useEffect, useState, useRef } from "react";
import { useSnapshot } from "valtio";
import { useLocation, useSearchParams } from "react-router-dom";
import { therapistListingService } from "services/therapistListingService";
import { scrollToTop } from "common/utils/async";

export const useTherapistListing = () => {
  const {
    therapists,
    totalCount,
    pageSize,
    currentPage,
    isInitializing,
    isFetching,
    appliedFilters,
    search,
    isError,
  } = useSnapshot(therapistListingService);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageCount = Math.ceil(Number(totalCount) / pageSize);
  const state = useLocation().state as null | { [x: string]: any };
  const initialized = useRef(false);
  const [isFetchingList, setIsFetchingList] = useState(false);
  useEffect(() => {
    if (state?.withScroll) {
      scrollToTop();
    }
  }, [state]);


  useEffect(() => {
    if (!isInitializing) {
      setSearchParams(
        new URLSearchParams(therapistListingService.getSearchParams())
      );
    }
  }, [isInitializing, currentPage, appliedFilters, setSearchParams]);


  useEffect(() => {
      if (!initialized.current) {
        therapistListingService.init(searchParams);
      }
  }, [isFetchingList, setIsFetchingList]);

  return {
    therapists,
    totalCount,
    isFetching: isInitializing,
    isFetchingList: isFetching,
    pageCount,
    currentPage,
    isError,
    clearFilters: () => therapistListingService.clearFilters(),
  };
};

import { ReactNode } from "react";
import cn from "classnames";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { Modal } from "common/components/Modal";
import Button from "common/UIKit/Button";
import styles from "./LoggedOutContainer.module.scss";

interface Props {
  isOpen: boolean;
  close: () => void;
  onButtonClick: () => void;
  title?: string;
  subTitle?: ReactNode;
  className?: string;
}

export const LoggedOutContainer = ({
  onButtonClick,
  close,
  isOpen,
  className,
  subTitle = "You are logged out. Please login to Continue.",
  title = "Oops!",
}: Props) => {
  return (
    <>
      <Modal visible={isOpen} onClose={close} width={485}>
        <div className={cn(styles.modal, className)}>
          <button className={styles.modal_close_button} onClick={close}>
            <CloseIcon />
          </button>
          <h2>{title}</h2>
          <p>{subTitle}</p>
          <Button
            className={cn(styles.button, "w-100", "max-width-150")}
            onClick={onButtonClick}
            size="lg"
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};

export enum ParticipantType
{
	Therapist = 1,
	Patient = 2
}
export enum ConnectionStatuses
{
	NotSet = 0,
	Connected = 1,
	Started = 2,
	Ended = 3,
	Disconnected = 4
} 

export type  get_participant_status_result=
{
	ProviderStatus: ConnectionStatuses;
	ClientStatus: ConnectionStatuses ;
	ProviderStatusDate: string;
	ClientStatusDate:string;
}
  

export type UpdateSessionStatusArgs = {
	SessionID: string;
	Participant: ParticipantType;
	Status: ConnectionStatuses;	
  }
 
import { useState } from "react";
import { Link, useParams, Navigate } from "react-router-dom";
import cn from "classnames";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import Input from "common/UIKit/Input/Input";
import InputPassword from "common/UIKit/InputPassword/InputPassword";
import Button from "common/UIKit/Button/Button";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { AuthLayout } from "pages/Auth/components/AuthLayout";
import { isUserType } from "services/authService";
import { useLogin } from "./useLogin";
import { forgotPath, signupPath } from "../../commonLinks";
import { UserType } from "types/auth.types";
import { AccessController } from "common/components/Layout/AccessController";
import { WrongRoleModal } from "../components/WrongRoleModal";
import { authService } from "services/authService";

import styles from "../AuthInput.module.scss";

type Props = {
  userType: UserType;
};

const patientMessages = {
  postTitle: "Patient’s Login",
  rightTitle: "Find the best match \nfor your therapy",
};

const therapistMessages = {
  postTitle: "Provider Login",
  rightTitle: "Expand your \nprofessional footprint",
};

const LoginPage = ({ userType }: Props) => {
  const { register, handleSubmit, errors, setGlobalError, isLoading, finished, message } =
    useLogin(userType);

  const messages = userType === "patient" ? patientMessages : therapistMessages;  
    return (
      <AuthLayout
        signTitle="Welcome to TheraPass"
        signSupTitle="TM"
        signPostTitle={messages.postTitle}
        signRightTitle={messages.rightTitle}
      >
      {!finished && (
        <form
          id="signin"
          className="sign-form"
          onSubmit={handleSubmit}
          noValidate
        >
          {errors.global && <ErrorMessage>{errors.global}</ErrorMessage>}
          <Input
            id="contact"
            type="text"
            className={styles.signInput}
            placeholder=""
            label="Email or Phone Number"
            {...register("contact", {
              required: "Required field",
              validate: {
                isLogin: (value) => 
                isEmail(value) || isMobilePhone(value) || "Invalid login"
              },

            })}
            error={errors.contact?.message}
          />
        <Button
          type="submit"
          className="mb-15 w-100"
          isLoading={isLoading}
          size="lg"
        >
          Log In
        </Button>
        {/*<GoogleOathButton userType={userType} setGlobalError={setGlobalError}>*/}
        {/*  Login with Google*/}
        {/*</GoogleOathButton>*/}
        <p className="sign-help">
          Not yet a member?{" "}
          <Link to={`/${userType}${signupPath}`} className="g-underline">
            Register
          </Link>
        </p>
      </form>
      )
      || finished && (
        <p>{message}</p>
      )}
      <WrongRoleModal userType={userType} />
    </AuthLayout>
  );
};

export const Login = () => {
  const { userType } = useParams();

  if (isUserType(userType)) {
    return (
      <AccessController unauthorized="allowed">
        <LoginPage userType={userType} />
      </AccessController>
    );
  }

  return <Navigate to="/" />;
};

import { useNavigate } from "react-router-dom";
import { Payments as CommonPaymentsPage } from "common/pages/Payments";
import { useEffect, useState } from "react";
import { visitorIdService } from "services/VisitorIdService";
import { esaProfileService } from "services/esaProfileService";
import { useSnapshot } from "valtio";

export const paymentsPath = "/payments";

export const Payments = () => {
  const navigate = useNavigate();
  const { client } = useSnapshot(esaProfileService);
  const [tracked, setTracked] = useState(false);


  useEffect(() => {
    if(!tracked) {
      visitorIdService.trackEvent(
        "SettingsPayments",
        "visit",
        client?.clientid.toString() || "",
        "",
        "",
      );
    setTracked(true);
    }
  }, [tracked]);

  return <CommonPaymentsPage onAddCard={() => navigate("../add-new-card")} />;
};

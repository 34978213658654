import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { Modal } from "common/components/Modal/Modal";
import Button from "common/UIKit/Button";
import { useModal } from "hooks/useModal";
import { useNavigate } from "react-router-dom";
import styles from "./CTAPopup.module.scss";

export const CTAPopup = () => {
  const navigate = useNavigate();
  const { isOpen, close } = useModal("CTA_POPUP");

  const onClickHandler = (path: string) => {
    close();
    return navigate(path);
  };

  return (
    <Modal visible={isOpen} onClose={close} className={styles.modal} width={325}>
      <div className={styles.popup}>
        <button className={styles.popupCloseButton} onClick={close}>
          <CloseIcon />
        </button>
        <h3 className={styles.popupTitle}>Choose your way</h3>
        <Button
          color="green-light"
          className={styles.btn}
          onClick={() => onClickHandler("/therapist/login")}
          size="lg"
        >
          I’m a therapist
        </Button>
        <Button
          className={styles.btn}
          onClick={() => onClickHandler("/patient/login")}
          size="lg"
        >
          I'm a client
        </Button>
      </div>
    </Modal>
  );
};

import { useNavigate } from "react-router-dom";

import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useAuth } from "hooks/useAuth";

import { EditHeader } from "common/components/EditHeader";
import { OrdersTable } from "common/components/OrdersTable";

import { Therapist } from "types/therapist.types";

import { ErrorPage } from "pages/ErrorPage";

import styles from "./OrderHistory.module.scss";

import { patientsDashboardMainPage } from "../index";
import { useSnapshot } from "valtio";
import { esaProfileService } from "services/esaProfileService";
import { useEffect, useState } from "react";
import { visitorIdService } from "services/VisitorIdService";

export const orderHistoryPath = "/order-history";

export const OrderHistory = () => {
  const navigate = useNavigate();
  const { client } = useSnapshot(esaProfileService);
  const [tracked, setTracked] = useState(false);

  useDashboardNavigation("Order History", {
    goBack: () => navigate(patientsDashboardMainPage),
  });
  const user = useAuth().user as Therapist;

  useEffect(() => {
    if(!tracked) {
      visitorIdService.trackEvent(
        "OrderHistory",
        "visit",
        client?.clientid.toString() || "",
        "",
        "",
      );
    setTracked(true);
    }
  }, [tracked]);

  if (!user?.id) return <ErrorPage />;

  return (
    <section className={styles.root}>
      <EditHeader className={styles.header} />
      <OrdersTable userRole="patient" emptyMessage="Order history is empty" />
    </section>
  );
};

import { Controller } from "react-hook-form";
import AppSelect from "common/UIKit/Select/Select";
import Input from "common/UIKit/Input";
import { InputSmartSearch } from "common/UIKit/InputSmartSearch";
import {
  zipCodeValidator,
  streetAddressValidator,
  cityValidator,
} from "common/validators/addressValidators";
import { DeepPartial } from "common/utils";
import { STATE_OPTIONS, StateOption } from "constants/options";
import { Therapist, BusinessInfo } from "types/therapist.types";
import { stepsUi } from "../components";
import { step3_1FullPath } from "../TherapistStep3_1";
import { coveredFields } from "./step2Config";
import useAnyTherapistStep from "../useAnyTherapistStep";
import { step1FullPath } from "../TherapistStep1";
import { useGeocoder } from "hooks/useGeocoder";
import { InputPhone } from "common/UIKit/InputPhone/InputPhone";
import isURL from "validator/lib/isURL";
import styles from "./TherapistStep2.module.scss";

type Inputs = {
  business_info: BusinessInfo;
};

export function TherapistStep2() {
  const geocoder = useGeocoder();
  const toApi = async (values: DeepPartial<Therapist>) => {
    try {
      const result = await geocoder(values);

      return {
        ...values,
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng(),
      };
    } catch {
      return values;
    }
  };
  const { actionsProps, setValue, handleSubmit, register, errors, control } =
    useAnyTherapistStep<Inputs>(coveredFields, step3_1FullPath, {
      prevStep: step1FullPath,
      toApi,
    });

  return (
    <>
      <stepsUi.Header>Business Info</stepsUi.Header>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={<stepsUi.Actions {...actionsProps} />}
      >
        <stepsUi.Row label="Address">
          <InputSmartSearch
            onSelectPlace={(place) => {
              setValue("business_info.street_address", place.address || "");
              setValue("business_info.city", place.city || "");
              setValue("business_info.state", place.state || "");
              setValue("business_info.zip_code", place.zip || "");
              setValue("business_info.latitude", place.latitude || 0);
              setValue("business_info.longitude", place.longitude || 0);
            }}
            types={["address"]}
            placeholder="Start typing your address"
            className={styles.search}
          />
        </stepsUi.Row>
        <stepsUi.Grid>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Company Name"
              {...register("business_info.company_name", {
                maxLength: { value: 64, message: "Maximum 64 characters" },
              })}
              error={errors.business_info?.company_name?.message}
            />
          </stepsUi.Row>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Street Address"
              {...register(
                "business_info.street_address",
                streetAddressValidator
              )}
              error={errors.business_info?.street_address?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Grid>
          <stepsUi.RowShort>
            <stepsUi.Row>
              <Input
                type="text"
                placeholder=""
                label="City"
                {...register("business_info.city", cityValidator)}
                error={errors.business_info?.city?.message}
              />
            </stepsUi.Row>
            <stepsUi.Row>
              <Input
                type="text"
                placeholder=""
                label="Zip"
                {...register("business_info.zip_code", zipCodeValidator)}
                error={errors.business_info?.zip_code?.message}
              />
            </stepsUi.Row>
          </stepsUi.RowShort>
          <stepsUi.Row label="State">
            <Controller
              control={control}
              name="business_info.state"
              render={({ field }) => (
                <AppSelect
                  placeholder="Select"
                  options={STATE_OPTIONS}
                  value={STATE_OPTIONS.find(
                    ({ value }) => value === field.value
                  )}
                  onChange={(v) => field.onChange((v as StateOption).value)}
                />
              )}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Grid>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Website"
              {...register("business_info.website", {
                validate: (value) =>
                  !value || (value && isURL(value)) || "Is not valid url",
              })}
              error={errors.business_info?.website?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Grid>
          <stepsUi.Row>
            <InputPhone
              label="Company Phone Number"
              control={control}
              name="business_info.phone"
            />
          </stepsUi.Row>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Ext #"
              {...register("business_info.phone_extension", {
                maxLength: { value: 5, message: "Maximum 5 characters" },
              })}
              error={errors.business_info?.phone_extension?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
      </stepsUi.Form>
    </>
  );
}

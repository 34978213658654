import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import Checkbox from "common/UIKit/Checkbox";
import AppSelect from "common/UIKit/Select";

import { personalInformationPath } from "../PersonalInformation";
import { MessagePopup } from "common/components/MessagePopup";

import styles from "./EditPersonalShipping.module.scss";
import { STATE_OPTIONS, StateOption } from "constants/options";
import { useAuth } from "hooks/useAuth";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { PatientFields } from "pages/Patients/PatientSteps/util";
import { FC, useState } from "react";
import { Controller } from "react-hook-form";
import { Patient } from "types/patient.types";
import { useProfileForm } from "../useProfileForm";

export const editPersonalShippingPath = `${personalInformationPath}/edit-shipping`;

const coveredFields: PatientFields = [
  "user.shipping_address_1",
  "user.shipping_address_2",
  "user.shipping_city",
  "user.shipping_state",
  "user.shipping_zip",
  "user.is_billing_same_as_shipping",
];
type Inputs = {
  shipping_address_1: string;
  shipping_address_2: string;
  shipping_city: string;
  shipping_state: string;
  shipping_zip: string;
  is_billing_same_as_shipping: boolean;
};

export const EditPersonalShipping: FC = () => {
  const { goBack } = useDashboardNavigation("Edit Shipping Information", {
    goBack: "pop_path",
  });
  const { register, control, handleSubmit, errors, isSubmitting, setValue } =
    useProfileForm<Inputs>(coveredFields, goBack, "shipping address");
  const { baseUser } = useAuth();
  const user = useAuth().user as Patient | null;
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(user?.user.is_billing_same_as_shipping);

  return (
    <div className={styles.editPersonalShipping}>
      <MessagePopup />
      <form className={styles.mainForm} onSubmit={handleSubmit}>
        <Input
          label="Street Address 1"
          {...register("shipping_address_1")}
          error={errors.shipping_address_1?.message}
          disabled={isBillingSameAsShipping}
        />
        <Input
          label="Street Address 2"
          {...register("shipping_address_2")}
          error={errors.shipping_address_2?.message}
          disabled={isBillingSameAsShipping}
        />
        <Input
          label="City"
          {...register("shipping_city")}
          error={errors.shipping_city?.message}
          disabled={isBillingSameAsShipping}
        />
        <Controller
          control={control}
          name="shipping_state"
          render={({ field, fieldState }) => (
            <AppSelect
              label="State"
              placeholder="Select"
              {...field}
              options={STATE_OPTIONS}
              value={STATE_OPTIONS.find((o) => o.value === field.value)}
              onChange={(newValue) => {
                const { value } = newValue as StateOption
                field.onChange(value);
              }}
              error={fieldState.error?.message}
              isDisabled={isBillingSameAsShipping}
            />
          )}
        />
        <Input
          label="Zip"
          {...register("shipping_zip")}
          error={errors.shipping_zip?.message}
          className={styles.editPersonalShipping__fullWidth}
          disabled={isBillingSameAsShipping}
        />
        <Checkbox
          id="is_billing_same_as_shipping"
          {...register("is_billing_same_as_shipping")}
          onChange={(e) => {
            if(e.currentTarget.checked){
              setValue("shipping_address_1", user?.user.billing_address_1 ? user?.user.billing_address_1 : "");
              setValue("shipping_address_2", user?.user.billing_address_2 ? user?.user.billing_address_2 : "");
              setValue("shipping_city", user?.user.billing_city ? user?.user.billing_city : "");
              setValue("shipping_state", user?.user.billing_state ? user?.user.billing_state : "");
              setValue("shipping_zip", user?.user.billing_zip ? user?.user.billing_zip : "");
              setValue("is_billing_same_as_shipping", true);
              setIsBillingSameAsShipping(true);
            }else{
              setValue("is_billing_same_as_shipping", false);
              setIsBillingSameAsShipping(false);
            }
          }}
        >
          The same as billing Address
        </Checkbox>
        <Button
          className="w-100"
          type="submit"
          text="Save"
          isLoading={isSubmitting}
          size="lg"
        />
      </form>
    </div>
  );
};

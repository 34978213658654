import Select from "common/UIKit/Select";

import { Modal } from "common/components/Modal/Modal";
import Button from "common/UIKit/Button";

import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";

import styles from "./ChangePhonePopup.module.scss";

import useChangePhone from "./useChangePhone";
import { useModalDrag } from "hooks/useModalDrag";

export const ChangePhonePopup = () => {
  const {
    isOpen,
    options,
    input,
    selectedOption,
    error,
    onChange,
    onClose,
    onInputChange,
    onSave,
  } = useChangePhone();

  const { bind, transformStyle } = useModalDrag({
    moveDistanceToClose: 200,
    onClose,
  });

  return (
    <Modal
      visible={isOpen}
      onClose={onClose}
      width={820}
    >
      <div className={styles.popup} style={transformStyle} {...bind()}>
        <div className={styles.popupContainer}>
          <button className={styles.popupCloseButton} onClick={onClose}>
            <CloseIcon />
          </button>
          <h3 className={styles.popupTitle}>Change phone number</h3>
          <Select
            className={styles.popupInput}
            isSearchable
            onInputChange={onInputChange}
            options={options}
            inputValue={input}
            onChange={onChange}
            value={selectedOption}
            error={error}
          />
          <Button
            className={styles.popupButton}
            onClick={onSave}
            size="lg"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import useLocalAudioToggle from "hooks/useLocalAudioToggle";
import useVideoContext from "hooks/useVideoContext";
import styles from "./ToggleAudioButton.module.scss";
import microphoneMuteIcon from "./microphone.svg";
import microphoneUnmuteIcon from "./microphone-solid.svg";

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  className?: string;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some((track) => track.kind === "audio");

  return (
    <button className={styles.bottomIconDiv}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      data-cy-audio-toggle
    >
      {isAudioEnabled
        ? <>
            <img className={styles.bottomIcon} src={microphoneMuteIcon} alt="" />
            <span className={styles.bottomText}>Stop</span>
          </>
        : <>
            <img className={styles.bottomIcon} src={microphoneUnmuteIcon} alt="" />
            <span className={styles.bottomText}>Start</span>
          </>
      }
      </button>
  );
}

import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import AppSelect from "common/UIKit/Select";

import { MessagePopup } from "common/components/MessagePopup";
import { ReactComponent as Edit } from "assets/images/icons/edit.svg";
import avatar from "assets/images/avatar-animal.svg";

import styles from "./EditAnimal.module.scss";
import { blockEvent } from "common/utils";
import { animalTypes, catBreeds, dogBreeds } from "constants/options";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import {
  useState,
  useRef,
  MouseEventHandler,
  ChangeEvent,
  useEffect,
  FC,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { esaProfileService } from "services/esaProfileService";
import { Animal } from "types/profile.types";
import { useSnapshot } from "valtio";
import { patientsDashboardFullPath } from "../../PatientsDashboard";
import { myAnimalsPath } from "../MyAnimals";

export const editAnimalPath = `/edit-animal`;

interface Props {
  clientAnimalId: number;
}

export const EditAnimal: FC<Props> = ({ clientAnimalId }) => {
  const { register, control, handleSubmit, setValue } = useForm<Animal>();
  useEffect(() => {
    esaProfileService.getProfile();
  });
  const { animals } = useSnapshot(esaProfileService);
  const animal = animals.find((val) => val.ClientAnimalID === clientAnimalId);
  const [breeds, setBreeds] = useState(
    animal?.AnimalTypeId === 1 ? dogBreeds : catBreeds
  );
  const [photoPreview, setPhotoPreview] = useState(animal?.Photo);

  const navigate = useNavigate();
  const { goBack } = useDashboardNavigation("Edit Animal", {
    goBack: () => navigate(`${patientsDashboardFullPath}${myAnimalsPath}`),
    isNavigationHidden: true,
  });

  const onSubmit = (values: Animal) => {
    values.ClientAnimalID = clientAnimalId;
    esaProfileService.updateAnimals(values, true, goBack);
  };

  const ref = useRef<HTMLInputElement>(null);
  const browseFile: MouseEventHandler<HTMLButtonElement> = (e) => {
    blockEvent(e);
    ref.current?.click();
  };
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files?.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          setValue("PhotoName", file.name);
          const photoBase64 = reader.result.toString();
          setValue("Photo", photoBase64);
          setValue("UpdatePhoto", true);
          setPhotoPreview(photoBase64);
        }
      };
    }
  };

  const onRemovePhoto = () => {
    setValue("PhotoName", "");
    setValue("Photo", "");
    setValue("UpdatePhoto", true);
    setPhotoPreview("");
  };

  return (
    <div>
      <MessagePopup />

      <div className="default-typography">
        <h1 className="h3 mb-40">Edit animal</h1>

        <form className={styles.form}>
          <div className="row">
            <div className="col-12">
              <div className="mb-50">
                <div className={styles.animalAvatar}>
                  <img
                    src={photoPreview || avatar}
                    alt="avatar"
                    className={styles.animalAvatar__img}
                  />
                  <button
                    className={styles.animalAvatar__removeAvatar}
                    type="button"
                    onClick={onRemovePhoto}
                  >
                    Remove photo
                  </button>
                  <Button
                    className={styles.animalAvatar__editIcon}
                    onClick={browseFile}
                    size="lg"
                  >
                    <Edit />
                  </Button>
                  <input
                    className={styles.animalAvatar__hiddenInput}
                    type="file"
                    ref={ref}
                    accept="image/jpg,image/gif,image/png,image/tiff,image/jpeg"
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-35">
                <Input
                  label="Pet Name"
                  {...register("AnimalName")}
                  defaultValue={animal?.AnimalName}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-35">
                <Controller
                  control={control}
                  name="AnimalTypeId"
                  render={({ field, fieldState: { error } }) => (
                    <AppSelect
                      label="Type"
                      error={error?.message}
                      placeholder="Select"
                      options={animalTypes}
                      defaultValue={animalTypes.find(
                        (o) => o.value === animal?.AnimalTypeId
                      )}
                      value={animalTypes.find((o) => o.value === field.value)}
                      onChange={(nextValue) => {
                        const { value } = nextValue as {
                          label: string;
                          value: number;
                        };
                        setValue("AnimalTypeId", value);
                        setValue("AnimalBreedId", 0);
                        if (value === 1) {
                          setBreeds(dogBreeds);
                        } else if (value === 2) {
                          setBreeds(catBreeds);
                        }
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-35">
                <Controller
                  control={control}
                  name="AnimalBreedId"
                  render={({ field, fieldState: { error } }) => (
                    <AppSelect
                      label="Breed"
                      error={error?.message}
                      placeholder="Select"
                      options={breeds}
                      defaultValue={breeds.find(
                        (o) => o.value === animal?.AnimalBreedId
                      )}
                      value={breeds.find((o) => o.value === field.value)}
                      onChange={(nextValue) => {
                        const { value } = nextValue as {
                          label: string;
                          value: number;
                        };
                        setValue("AnimalBreedId", value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-35">
                <Input
                  label="Weight (lbs)"
                  defaultValue={animal?.AnimalWeight}
                  {...register("AnimalWeight")}
                />
              </div>
            </div>
            <div className="w-100"></div>
            <div className="col-sm-6">
              <Button
                className="w-100"
                text="Save"
                onClick={handleSubmit(onSubmit)}
                size="lg"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

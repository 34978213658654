import React, { HTMLProps } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import "./Button.scss";

export interface ButtonProps
  extends Omit<HTMLProps<HTMLButtonElement>, "size"> {
  color?:
    | "primary"
    | "outline-primary"
    | "green-light"
    | "outline-green-light"
    | "outline-gray"
    | "red"
    | "outline-red-light"
    | "outline-red"
    | "outline-red-muted"
    | "yellow"
    | "turquoise"
    | "gray"
    | "white"
    | "link-gray"
    | "link-secondary"
    | "link";
  size?: "sm" | "lg";
  text?: string;
  type?: "button" | "submit" | "reset";
  isLink?: boolean;
  to?: string | void;
  href?: string;
  target?: "_blank";
  disabled?: boolean;
  isLoading?: boolean;
  rel?: string;
  state?: any;
}

function Button({
  color = "primary",
  onClick,
  size,
  text,
  children,
  type = "button",
  className,
  isLink,
  to = "/",
  href,
  target,
  disabled,
  isLoading,
  rel,
  state,
  ...rest
}: ButtonProps) {
  const classNames = cn(
    "btn",
    `btn-${color}`,
    { [`btn-${size}`]: size },
    className
  );

  if (isLink) {
    return href ? (
      <a href={href} target={target} className={classNames} rel={rel}>
        {text || children}
      </a>
    ) : (
      <Link
        to={to}
        state={state}
        className={classNames}
        target={target}
        rel={rel}
      >
        {text || children}
      </Link>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames}
      disabled={disabled || isLoading}
      {...rest}
    >
      {text || children}
      {isLoading && <div className="btn__spinner" />}
    </button>
  );
}

export default Button;

import { Link, useLocation, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import isEmail from "validator/es/lib/isEmail";
import { FieldName, useForm } from "react-hook-form";

import Button from "common/UIKit/Button/Button";
import Input from "common/UIKit/Input/Input";
import { AuthLayout } from "pages/Auth/components/AuthLayout";
import { InputPhone } from "common/UIKit/InputPhone/InputPhone";
import styles from "./RemoveTherapist.module.scss";
import ControlledFileUpload from "common/components/ControlledFileUpload";
import useFileUpload from "hooks/useFileUpload";
import { confirmPhonePath } from "../ConfirmPhone/ConfirmPhone";
import { npiNumberValidatorForRemove } from "common/validators/insuranceValidators";
import { removeModifyService } from "services/removeModifyService";
import { useQuery } from "hooks/useQuery";

export type RemoveArgs = {
  firstName: "";
  lastName: "";
  email: "";
  mobile_phone: "";
  password: "";
  npi: "";
};

export type ModifyArgs = Omit<RemoveArgs, "npi">;

export const removePath = "/remove-profile";
export const modifyPath = "/modify-profile";

export function RemoveTherapist() {
  const { pathname } = useLocation();
  const isRemove = pathname.includes("remove");
  const q = useQuery();
  const therapistId = q.get("therapistId");
  const navigate = useNavigate();
  const isSuccess = q.get("success");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<any>();

  const { fileUploadProps } = useFileUpload("claim_profile", ["image", "pdf"], {
    instantUpload: true,
    instantRemove: true,
  });

  const onSubmit = async (values: any) => {
    try {
      await removeModifyService.removeModify({
        ...values,
        type: isRemove ? "remove" : "modify",
        therapist_id: therapistId,
      });
      navigate({
        pathname: confirmPhonePath,
        search: `?phone=${values.mobile_phone}&userType=therapist&redirect_to=${
          isRemove ? "success" : "resetPassword"
        }&${isRemove ? "remove" : "modify"}=true&email=${values.email}`,
      });
    } catch (error) {
      const { status, data } = error as AxiosResponse<
        Record<keyof RemoveArgs, string[]>
      >;
      if (status >= 400 && status < 500) {
        Object.entries(data).forEach(([key, value]) => {
          setError(key, { type: "custom", message: value.join(". ") });
        });
      }
    }
  };

  return (
    <AuthLayout
      signTitle={isRemove ? "Remove Profile" : "Modify Profile"}
      signPostTitle={
        isSuccess
          ? "Success!"
          : `In order to claim this profile for ${
              isRemove ? "removal" : "modification"
            }, please provide the following information in order to verify your identity:`
      }
      className={`title-therapist`}
      wrapperClassname={styles.wrapper}
      logoClassName={styles.logo}
    >
      {isSuccess ? (
        <div className={styles.success}>
          <p>
            {"Your documents have been received and are being reviewed \n" +
              "by our team. You will be notified when removal is complete."}
          </p>
          <Link to="/">Go to Dashboard</Link>
        </div>
      ) : (
        <form
          id="claim"
          action=""
          className="claim-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          {/*{errors.global && <ErrorMessage>{errors.global}</ErrorMessage>}*/}
          {/*<input type="hidden" {...register("type")} />*/}
          <Input
            id="first_name"
            type="text"
            className={styles.signInput}
            placeholder=""
            label="First Name"
            {...register("firstName", { required: "Required field" })}
            error={errors.firstName?.message}
          />
          <Input
            id="last_name"
            type="text"
            className={styles.signInput}
            placeholder=""
            label="Last Name"
            {...register("lastName", { required: "Required field" })}
            error={errors.lastName?.message}
          />
          <Input
            id="email"
            type="email"
            className={styles.signInput}
            placeholder=""
            label="Email"
            {...register("email", {
              required: "Required field",
              validate: (value) => isEmail(value) || "Invalid email",
            })}
            error={errors.email?.message}
          />
          <InputPhone
            id="phone"
            name="mobile_phone"
            control={control}
            label="Mobile phone"
            rules={{ required: "Required field" }}
          />
          {isRemove && (
            <Input
              id="npi"
              type="npi"
              className={styles.signInput}
              placeholder=""
              label="NPI"
              maxLength={10}
              {...register("npi", npiNumberValidatorForRemove)}
              error={errors.npi?.message}
            />
          )}
          <label>Upload Documents</label>
          <input
            type="hidden"
            {...register("files", {
              validate: {
                positive: () =>
                  fileUploadProps.value.length > 0 ||
                  "Please, upload required documents",
              },
            })}
          />
          {errors.files?.message && (
            <p style={{ color: "red" }}>{errors.files?.message}</p>
          )}
          <ControlledFileUpload
            className={styles.upload}
            accept="image/jpg,image/gif,image/png,image/tiff,image/jpeg,application/pdf"
            multiple
            caption="Drag your documents here to start uploading"
            {...fileUploadProps}
          />
          <Button type="submit" className="sign-submit w-100" size="lg">
            Submit
          </Button>
        </form>
      )}
    </AuthLayout>
  );
}

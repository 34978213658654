import React, { useState, useEffect, FormEvent } from "react";
import DeviceSelectionScreen from "./DeviceSelectionScreen/DeviceSelectionScreen";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import useVideoContext from "hooks/useVideoContext";
import { useSearchParams } from "react-router-dom";

interface PreJoinScreenProps {
  ptName?: string;
  therapistName?: string;
}

export default function PreJoinScreens({
  ptName,
  therapistName,
}: PreJoinScreenProps) {
  const { getAudioAndVideoTracks } = useVideoContext();

  const [mediaError, setMediaError] = useState<Error>();
  const [params] = useSearchParams();
  useEffect(() => {
    getAudioAndVideoTracks().catch((error) => {
      console.log("Error acquiring local media:");
      console.dir(error);
      setMediaError(error);
    });
  }, [getAudioAndVideoTracks, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // @ts-ignore
    if (!window.location.origin.includes("twil.io") && !window.STORYBOOK_ENV) {
      window.history.replaceState(
        null,
        "",
        window.encodeURI(`/room/Test${window.location.search || ""}`)
      );
    }
  };

  return (
    <>
      <MediaErrorSnackbar error={mediaError} />
      <DeviceSelectionScreen
        name="Therapist Chat"
        patientName={`${ptName}`}
        therapistName={`${therapistName}`}
      />
    </>
  );
}

import useHeight from "hooks/useHeight";
import useRoomState from "hooks/useRoomState";
import useRoomParticipantStatus from "hooks/useRoomParticipantStatus";
import PreJoinScreens from "./PreJoinScreens/PreJoinScreens";
import ReconnectingNotification from "./ReconnectingNotification/ReconnectingNotification";
import Room from "./Room/Room";
import { useState } from "react";
import { TwilioError } from "twilio-video";
import useVideoContext from "hooks/useVideoContext";
import EndCallButton from "common/components/Buttons/EndCallButton/EndCallButton";
import styles from "./VideoWrapper.module.scss";
import ToggleAudioButton from "common/components/Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "common/components/Buttons/ToggleVideoButton/ToggleVideoButton";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const VideoWrapper = () => {

  const [error, setError] = useState<TwilioError | null>(null);
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const sessionId = params.get("SessionID");
  const isTherapist = params.get("isTherapist") ? true : false;

  const roomState = useRoomState(setError, sessionId, isTherapist);
  const isReconnecting = roomState === "reconnecting";
  const participantStatus = useRoomParticipantStatus(setError, sessionId!, isTherapist);



  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();
  const { sessionMeta } = useVideoContext();


  return roomState === "disconnected" ? (
    <PreJoinScreens
      ptName={sessionMeta?.clientName}
      therapistName={sessionMeta?.therapistName}
    />
  ) : (
    <>
    
    <ReconnectingNotification />
    <section className={styles.main}>
      <EndCallButton />
      <div className={styles.mainVideo}>
        <Room />
      </div>
    </section>

    <div className={styles.bottomBar}>
      <ToggleAudioButton disabled={isReconnecting} />
      <ToggleVideoButton disabled={isReconnecting} />
    </div>
  </>
  );
};
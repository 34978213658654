import { useEffect, useState } from "react";
import { EditHeader } from "common/components/EditHeader";
import Button from "common/UIKit/Button";
import { stepsUi } from "pages/Therapists/TherapistSteps/components";
import ControlledMultiSelect from "common/components/ControlledMultiSelect";
import Input from "common/UIKit/Input";
import { npiNumberValidator } from "common/validators/insuranceValidators";
import { ListOption } from "constants/options";
import { dictionaryService } from "services/dictionaryService";
import { DeepPartial, splitInsuranceCompanies, toOptions } from "common/utils";
import { TherapistFields } from "pages/Therapists/TherapistSteps/util";
import useFetch from "hooks/useFetch";
import { useProfileForm } from "../../useProfileForm";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { Therapist } from "types/therapist.types";
import styles from "./EditInsurance.module.scss";

const coveredFields: TherapistFields = [
  "insurance_companies",
  "npi_number",
  "malpractice_carrier",
  "allied_groups",
  "membership_id",
];

type Inputs = {
  general_insurance_companies: string[];
  npi_number: string | null;
  malpractice_carrier: string;
  membership_id: string;
  additional_insurance_companies: string[];
};

export const EditInsurance = () => {
  useDashboardNavigation("Edit Insurance", {
    goBack: "pop_path",
  });

  const [generalInsuranceCompanies, setGeneralInsuranceCompanies] = useState<
    ListOption[]
  >([]);
  const additionalInsuranceCompanies = useFetch<ListOption[]>(
    () => dictionaryService.getInsuranceCompanies(),
    {
      transformer: toOptions,
    }
  );

  const {
    register,
    control,
    watch,
    handleSubmit,
    errors,
    isSubmitting,
    setError,
  } = useProfileForm<Inputs>(coveredFields, undefined, {
    useFormProps: {
      reValidateMode: "onSubmit",
    },
    toFields: ({ insurance_companies, ...rest }: DeepPartial<Therapist>) => {
      const [general_insurance_companies, additional_insurance_companies] =
        splitInsuranceCompanies(
          insurance_companies,
          generalInsuranceCompanies,
          additionalInsuranceCompanies.data
        );
      return {
        ...rest,
        general_insurance_companies,
        additional_insurance_companies,
      };
    },
    toApi: ({
      general_insurance_companies,
      additional_insurance_companies,
      ...rest
    }) => ({
      ...rest,
      insurance_companies: general_insurance_companies?.concat(
        additional_insurance_companies
      ),
    }),
    dependencies: [
      generalInsuranceCompanies.length,
      additionalInsuranceCompanies.data.length,
    ],
  });

  const [isProcessing, setIsProcessing] = useState(true);
  const npiNumber = watch("npi_number");

  useEffect(() => {
    (async () => {
      setIsProcessing(true);
      const companies = await dictionaryService.getHealthInsuranceCompanies();
      setGeneralInsuranceCompanies(toOptions(companies));
      setIsProcessing(false);
    })();
  }, []);

  useEffect(() => {
    setError("npi_number", {});
  }, [npiNumber]);

  return (
    <section className={styles.editInsurance}>
      <EditHeader />
      <form className={styles.editInsuranceForm} onSubmit={handleSubmit}>
        <stepsUi.Grid>
          <stepsUi.Row
            label="Health Insurance"
            subLabel="If you're in-network with an insurance company, select it here"
          >
            <ControlledMultiSelect
              name="general_insurance_companies"
              control={control}
              options={generalInsuranceCompanies}
              isDisabled={isProcessing}
              placeholder="Select"
            />
          </stepsUi.Row>
          <stepsUi.Row
            label="Other In-Network Insurance Companies"
            subLabel="Add insurance company"
          >
            <ControlledMultiSelect
              name="additional_insurance_companies"
              control={control}
              options={additionalInsuranceCompanies.data}
              isDisabled={isProcessing}
              placeholder="Select"
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <h2 className={styles.editInsuranceTitle}>Professional Insurance</h2>
        <stepsUi.Grid>
          <stepsUi.Row
            label="NPI Number"
            subLabel="Your 10 digit National Provider Identifier"
          >
            <Input
              type="text"
              placeholder=""
              maxLength={10}
              {...register("npi_number", npiNumberValidator)}
              error={errors.npi_number?.message}
            />
          </stepsUi.Row>
          <stepsUi.Row
            label="Malpractice Carrier"
            className={styles.editInsuranceRowInput}
          >
            <Input
              className={styles.inputCarrier}
              type="text"
              placeholder=""
              {...register("malpractice_carrier")}
              error={errors.malpractice_carrier?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Grid>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Membership ID/Number"
              {...register("membership_id")}
              error={errors.membership_id?.message}
              optional="OPTIONAL"
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <Button type="submit" className={styles.editInsuranceButton} size="lg">
          Save
        </Button>
      </form>
    </section>
  );
};

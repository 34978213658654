import { FC, useState } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import Button from "common/UIKit/Button";
import { TestScore } from "common/components/TestScore";
import { AvailableTest } from "types/availableTests.types";
import { isFree } from "services/availableTestsService";
import { TestAttempt } from "types/testAttempt.types";
import { isoStringToAmerican } from "common/utils";
import { ReactComponent as ChevronBottomIcon } from "assets/images/icons/chevron-bottom.svg";
import { ReactComponent as ClockIcon } from "assets/images/icons/transparent-clock.svg";
import styles from "./TestInfoMobile.module.scss";
import { testAttemptService } from "services/testAttemptService";

interface Props {
  data: AvailableTest | TestAttempt;
}

export const TestInfoMobile: FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const test = "test" in data ? data.test : data;
  const toggleOpen = () => setIsOpen((prev) => !prev);

  const openTestAttempt = () => {
    navigate(`./${data.id}/prepare`);
  };

  const openTest = async () => {
    if (isFree(test)) {
      navigate(`./new/prepare?test_id=${test.id}`);
    } else {
      navigate(`./${test.id}/buy`);
    }
  };

  return (
    <div className={styles.testInfoMobile} onClick={toggleOpen}>
      <Button className={styles.toggleOpenButton} size="lg">
        <ChevronBottomIcon className={cn(!isOpen && styles.flipped)} />
      </Button>
      <div className={styles.fields}>
        <div className={cn(styles.field, !isOpen && styles.closed)}>
          <h3 className={styles.name}>Name</h3>
          <p className={styles.value}>{test.name}</p>
        </div>
        {isOpen && (
          <>
            <div className={styles.field}>
              <h3 className={styles.name}>Type</h3>
              <p className={styles.value}>{test.test_type}</p>
            </div>

            <div className={styles.field}>
              <h3 className={styles.name}>Time</h3>
              <p className={styles.value}>
                <ClockIcon className={styles.clockIcon} />
                {"finished_at" in data
                  ? data.status === "progress"
                    ? `Started ${isoStringToAmerican(data.started_at || "")}`
                    : `Taken ${isoStringToAmerican(data.finished_at || "")}`
                  : `${test.time_to_complete} minutes`}
              </p>
            </div>

            {"result" in data && data.status !== "progress" && (
              <div className={styles.field}>
                <h3 className={styles.name}>My score</h3>
                <p className={styles.value}>{data.result} scores</p>
              </div>
            )}

            <div className={styles.field}>
              <h3 className={styles.name}>Costs</h3>
              <p className={styles.value}>
                {isFree(test) ? (
                  <div className={styles.free}>Free</div>
                ) : (
                  `$ ${test.cost}`
                )}
              </p>
            </div>
            <div className={styles.field}>
              {"result" in data && data.status !== "progress" ? (
                <TestScore
                  points={data.result || 0}
                  score={data.result_details?.level || 1}
                  maxScore={data.result_details?.total_levels || 1}
                />
              ) : "status" in data && data.status === "progress" ? (
                <Button
                  text="Continue"
                  onClick={openTestAttempt}
                  size="lg"
                />
              ) : (
                <Button
                  text="Take the Test Now"
                  onClick={openTest}
                  size="lg"
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

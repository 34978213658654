import { Controller } from "react-hook-form";
import Input from "common/UIKit/Input";
import AppSelect from "common/UIKit/Select/Select";
import Radiobutton from "common/UIKit/Radiobutton";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import {
  TITLE_OPTIONS,
  ListOption,
  STATE_OPTIONS,
  StateOption,
} from "constants/options";
import { stepsUi } from "../components";
import useAnyTherapistStep from "../useAnyTherapistStep";
import { coveredFields } from "./step1Config";
import { step2FullPath } from "../TherapistStep2";
import { Gender, Title } from "types/therapist.types";
import {
  cityValidator,
  streetAddressValidator,
  zipCodeValidator,
} from "common/validators/addressValidators";
import { InputSmartSearch } from "common/UIKit/InputSmartSearch";
import { InputPhone } from "common/UIKit/InputPhone/InputPhone";
import styles from "./TherapistStep1.module.scss";

type Inputs = {
  title: Title;
  first_name: string;
  middle_name: string;
  mobile_phone: string;
  last_name: string;
  credentials_choice: string;
  gender: Gender;
  practice_name: string;
  state?: string;
  zip_code: string;
  street_address: string;
  city: string;
  latitude: number;
  longitude: number;
  user: {
    mobile_phone: string;
  };
};

export function TherapistStep1() {
  const { register, handleSubmit, control, errors, actionsProps, setValue } =
    useAnyTherapistStep<Inputs>(coveredFields, step2FullPath);

  return (
    <>
      <stepsUi.Header>Personal Info</stepsUi.Header>
      <stepsUi.Form
        className={styles.form}
        handleSubmit={handleSubmit}
        actions={<stepsUi.Actions {...actionsProps} />}
      >
        <stepsUi.Grid>
          <stepsUi.Row label="Title">
            <Controller
              control={control}
              name="title"
              render={({ field, fieldState }) => (
                <AppSelect
                  placeholder="Select"
                  {...field}
                  options={TITLE_OPTIONS}
                  value={TITLE_OPTIONS.find((o) => o.value === field.value)}
                  onChange={(newValue) => {
                    const { value } = newValue as ListOption<Title>;
                    field.onChange(value);
                  }}
                  error={fieldState.error?.message}
                />
              )}
            />
          </stepsUi.Row>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="First name"
              {...register("first_name", { required: "Required field" })}
              error={errors.first_name?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Grid>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Middle name"
              {...register("middle_name")}
              error={errors.middle_name?.message}
            />
          </stepsUi.Row>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Last name"
              {...register("last_name", { required: "Required field" })}
              error={errors.last_name?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Grid>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Credentials"
              maxLength={24}
              {...register("credentials_choice")}
              error={errors.credentials_choice?.message}
              description="Specify the initials to be included after your name (PhD, MA, LCSW,
                etc.)"
              descriptionAlignment="left"
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Row label="Gender">
          <stepsUi.Container>
            <Radiobutton
              className={styles.radiobutton}
              {...register("gender")}
              value="female"
              withBorder
            >
              Female
            </Radiobutton>
            <Radiobutton
              className={styles.radiobutton}
              {...register("gender")}
              value="male"
              withBorder
            >
              Male
            </Radiobutton>
            <Radiobutton
              className={styles.radiobutton}
              {...register("gender")}
              value="non_binary"
              withBorder
            >
              Non Binary
            </Radiobutton>
          </stepsUi.Container>
          {errors.gender?.message && (
            <ErrorMessage>{errors.gender?.message}</ErrorMessage>
          )}
        </stepsUi.Row>
        <stepsUi.Grid>
          <stepsUi.Row>
            <InputPhone
              label="Mobile Phone"
              control={control}
              name="mobile_phone"
              rules={{ required: "This field is required." }}
              errorMessage={errors?.user?.mobile_phone?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Row label="Address">
          <InputSmartSearch
            onSelectPlace={(place) => {
              setValue("street_address", place.address || "");
              setValue("city", place.city || "");
              setValue("state", place.state);
              setValue("zip_code", place.zip || "");
              setValue("latitude", place.latitude || 0);
              setValue("longitude", place.longitude || 0);
            }}
            types={["address"]}
            placeholder="Start typing your address"
            className={styles.search}
          />
        </stepsUi.Row>
        <stepsUi.Grid>
          <stepsUi.Row>
            <Input
              type="text"
              placeholder=""
              label="Street Address"
              {...register("street_address", streetAddressValidator)}
              error={errors.street_address?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Grid>
          <stepsUi.RowShort>
            <stepsUi.Row>
              <Input
                type="text"
                placeholder=""
                label="City"
                {...register("city", cityValidator)}
                error={errors.city?.message}
              />
            </stepsUi.Row>
            <stepsUi.Row>
              <Input
                type="text"
                placeholder=""
                label="Zip"
                {...register("zip_code", zipCodeValidator)}
                error={errors.zip_code?.message}
              />
            </stepsUi.Row>
          </stepsUi.RowShort>
          <stepsUi.Row label="State">
            <Controller
              control={control}
              name="state"
              render={({ field }) => (
                <AppSelect
                  placeholder="Select"
                  options={STATE_OPTIONS}
                  value={STATE_OPTIONS.find(
                    ({ value }) => value === field.value
                  )}
                  onChange={(v) => field.onChange((v as StateOption).value)}
                />
              )}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
      </stepsUi.Form>
    </>
  );
}

import { ToggleCheckbox } from "common/UIKit/ToggleCheckbox";
import { getShallowDiff } from "common/utils";
import { SubmitHandler, useForm } from "react-hook-form";
import { authService } from "services/authService";
import { Patient } from "types/patient.types";
import { useSnapshot } from "valtio";
import { isEmpty } from "common/utils/index";
import { useEffect } from "react";
import styles from "./Notifications.module.scss";
import { visitorIdService } from "services/VisitorIdService";
import { esaProfileService } from "services/esaProfileService";

export const notificationsPath = "/notifications";

type Inputs = {
  site_notifications: boolean;
  email_notifications: boolean;
};

export const Notifications = () => {
  const patient = useSnapshot(authService).user as Patient;
  const defaultValues = {
    site_notifications: patient.user?.site_notifications,
    email_notifications: patient.user?.email_notifications,
  };
  const { register, watch, handleSubmit } = useForm<Inputs>({
    defaultValues,
  });
  const { client } = useSnapshot(esaProfileService);

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    try {
      const changedValues = getShallowDiff(values, defaultValues);

      if (!isEmpty(changedValues)) {
        await authService.patch({
          diff: {
            user: changedValues,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    visitorIdService.trackEvent(
      "SettingsNotifications",
      "visit",
      client?.clientid.toString() || "",
      "",
      "",
    );
    return () => subscription.unsubscribe();
  });

  return (
    <div className={styles.notifications}>
      <div className={styles.backdrop}>
        <div className={styles.field}>
          <div className={styles.label}>
            <p>Site notifications</p>
          </div>
          <ToggleCheckbox {...register("site_notifications")} />
        </div>
        <div className={styles.field}>
          <div className={styles.label}>
            <p>Email notifications</p>
          </div>
          <ToggleCheckbox {...register("email_notifications")} />
        </div>
      </div>
    </div>
  );
};

import { FC, useEffect, useState } from "react";
import Button from "common/UIKit/Button";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { DictionaryItem, dictionaryService } from "services/dictionaryService";
import { filterDictionaryItems } from "utils/utils";
import { FormatProfileField } from "common/components/FormatProfileField";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./Insurance.module.scss";

export const pathInsurance = "/insurance";

export const Insurance: FC = () => {
  const { tabName, edit } = useDashboardNavigation("Insurance", {
    edit: "append_edit",
    goBack: "pop_path",
  });
  const auth = useAuth();
  const user = auth.user as Therapist | null;
  const [insuranceCompanies, setInsuranceCompanies] = useState<DictionaryItem[]>([]);

  useEffect(() => {
    (async () => {
      const healthInsurance = await dictionaryService.getInsuranceCompanies();

      setInsuranceCompanies(
        filterDictionaryItems(healthInsurance, user?.insurance_companies)
      );
    })();
  }, [user]);

  return (
    <section className={styles.insurance}>
      <h1 className={styles.insuranceTitle}>{tabName}</h1>
      <div className={styles.insuranceInner}>
        <div className={styles.insuranceInfo}>
          <div className={styles.insuranceBlock}>
            <p className={styles.insuranceBlockTitle}>Accepted Insurance Companies</p>
            <p className={styles.insuranceBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {insuranceCompanies.map((item) => item.name).join(", ")}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.insuranceBlock}>
            <p className={styles.insuranceBlockTitle}>NPI Number</p>
            <p className={styles.insuranceBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {user?.npi_number}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.insuranceBlock}>
            <p className={styles.insuranceBlockTitle}>Malpractice Carrier</p>
            <p className={styles.insuranceBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {user?.malpractice_carrier}
              </FormatProfileField>
            </p>
          </div>
          <div className={styles.insuranceBlock}>
            <p className={styles.insuranceBlockTitle}>Membership ID/Number</p>
            <p className={styles.insuranceBlockText}>
              <FormatProfileField shouldLimitLength={false}>
                {user?.membership_id}
              </FormatProfileField>
            </p>
          </div>
        </div>
        <Button className={styles.insuranceButton} onClick={edit} size="lg">
          <EditIcon />
          Edit information
        </Button>
      </div>
    </section>
  );
};

import { useLayoutEffect, useState, useEffect } from "react";
import { Router, HistoryRouterProps } from "react-router-dom";
import { scrollToTop } from "../utils/async";

// in v6 of react-router we can't pass custom history object
// so this wrapper is workaround to pass history as in v5
// as result we can use `history.push()` outside components

export const CustomRouter = ({ history, ...props }: HistoryRouterProps) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  const withoutScroll = state.location.pathname.includes("directory");

  useEffect(() => {
    if (!withoutScroll) {
      scrollToTop();
    }
  }, [state, withoutScroll]);

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

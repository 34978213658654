import { authService } from "services/authService";
import { Navigate, useNavigate } from "react-router-dom";

export const redirectESAPath = "/redirectesa";

export const RedirectESA = () => {

  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("orderid")!;
  const visitorId = queryParams.get("visitorid")!;
  const clientId = queryParams.get("clientid")!;
  const visitorEmail = queryParams.get("email")!;
  authService.FetchPatientToken(visitorEmail, clientId, orderId);


  return <div></div>;
};

import { ErrorPage } from "pages/ErrorPage";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { TherapistPatient, therapistPatientPath } from "../TherapistPatient";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiEndpoint } from "utils/env";

export const therapistVideoLandingPath = "/video";

export const TherapistVideoLanding = () => {
  const [params] = useSearchParams();
  const therapistKey = params.get("therapistKey");
  const sessionId = params.get("sessionId");
  const clientId = params.get("clientId");
  const [patientId, setPatientId] = useState("");

  useEffect(() => {
    if (clientId) {
      axios
        .get(`${apiEndpoint}v1/esauserid?esaClientId=${clientId}`)
        .then((response) => {
          setPatientId(response.data.id);
        });
    }
  });

  if (!therapistKey || !sessionId || !clientId) {
    return <ErrorPage />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <TherapistPatient
            showJoinVideoButton={true}
            chatSessionId={sessionId}
            therapistKey={therapistKey}
            patientId={patientId}
            clientId={clientId}
          />
        }
      />
    </Routes>
  );
};

import React, { useEffect } from "react";
import { getAvatar, therapistName } from "common/utils";
import cn from "classnames";
import { useSnapshot } from "valtio";
import { capitalize } from "lodash";
import { messagesService } from "services/messagesService";
import { FormatProfileField } from "common/components/FormatProfileField";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { Accordion } from "common/components/Accordion";
import { Pagination } from "common/UIKit/Pagination";
import { EditHeader } from "common/components/EditHeader";
import { SortArrow } from "common/components/SortArrow";
import avatar from "assets/images/avatar.png";
import { ReactComponent as TimeCircleGreenIcon } from "assets/images/icons/time-circle-green.svg";
import { ReactComponent as MobileGreenIcon } from "assets/images/icons/mobile-green.svg";
import { FilterMessagesPopup } from "common/components/FilterPopup/FilterMessagesPopup";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import styles from "./Messages.module.scss";
import { patientsTherapistPremiumFullPath } from "../../PatientsTherapist";
import { Link } from "react-router-dom";
import { esaProfileService } from "services/esaProfileService";
import { visitorIdService } from "services/VisitorIdService";

export const messagesPath = "/messages";

export const Messages = () => {
  useDashboardNavigation("Messages", { goBack: "pop_path" });
  const { data, page, pageCount, sort } = useSnapshot(messagesService);
  const { client } = useSnapshot(esaProfileService);
  useDocumentTitle("TheraPass - Messages");

  useEffect(() => {
    messagesService.fetch();
    visitorIdService.trackEvent(
      "Messages",
      "visit",
      client?.clientid.toString() || "",
      "",
      "",
    );
  }, []);

  const onSortChange = (sortBy: "receiver" | "created_at") => {
    if (sortBy === "receiver") {
      messagesService.clearOrder("created_at", false);
      messagesService.setOrder(sortBy, { priority: 1 });
    } else {
      messagesService.clearOrder("receiver", false);
      messagesService.setOrder(sortBy, {
        priority: 2,
        order: sort[sortBy] ? undefined : "asc",
      });
    }
  };

  return (
    <>
      <div className={styles.messages}>
        <EditHeader withoutBack />
        <div className={styles.messageList}>
          <FormatProfileField
            className={styles.noMessages}
            fallbackText="You don't have any messages yet."
            objectToCheck={data}
          >
            <div className={styles.header}>
              <div
                className={cn(styles.headerItem, styles.clickable)}
                onClick={() => onSortChange("receiver")}
              >
                Therapist name <SortArrow name="receiver" sort={sort} />
              </div>
              <div className={styles.headerItem}>Proxy Phone</div>
              <div
                className={cn(styles.headerItem, styles.clickable)}
                onClick={() => onSortChange("created_at")}
              >
                Time sent <SortArrow name="created_at" sort={sort} inverted />
              </div>
              <div className={cn(styles.headerItem, styles.status)}>Status</div>
            </div>
            {data.map((message) => (
              <Accordion
                key={message.id}
                className={styles.accordion}
                tabClassName={styles.tab}
                panelClassName={styles.panel}
                iconClassName={styles.arrowIcon}
                tab={
                  <>
                    <div className={styles.time}>
                      <TimeCircleGreenIcon className={styles.icon} />
                      <p>
                        {new Date(message.created_at).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </p>
                    </div>
                    <div className={styles.receiver}>
                      <img
                        src={
                          (message.receiver.files &&
                            getAvatar(message.receiver.files)?.file) ||
                          avatar
                        }
                        alt="avatar"
                        className={styles.icon}
                      />
                      <Link
                          to={patientsTherapistPremiumFullPath.replace(":id", String(message.receiver?.therapist_id))}
                          target="_blank"
                      >
                        <p>{therapistName(message.receiver)}</p>
                      </Link>
                    </div>
                    <div className={styles.phoneNumber}>
                      {message?.sms_session && (
                        <>
                          <MobileGreenIcon />
                          <p>{message.sms_session.proxy_number}</p>
                        </>
                      )}
                    </div>
                    <div className={cn(styles.status, styles[message.status])}>
                      {capitalize(message.status)}
                    </div>
                  </>
                }
                panel={
                  <>
                    <p className={styles.accordionMessage}>{message.text}</p>
                    <p className={styles.accordionError}>{message.error}</p>
                  </>
                }
              />
            ))}
          </FormatProfileField>
        </div>
        <Pagination
          currentPage={page}
          goTo={(a) => messagesService.goTo(a)}
          pageCount={pageCount}
        />
      </div>
      <FilterMessagesPopup />
    </>
  );
};

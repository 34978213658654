import React from "react";
import useVideoContext from "hooks/useVideoContext";
import VideoAvatarIcon from "icons/VideoAvatarIcon";
import { LocalVideoTrack } from "twilio-video";
import VideoTrack from "pages/ClientVideo/VideoTrack/VideoTrack";

export default function LocalVideoPreview({ identity }: { identity: string }) {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(
    (track) => !track.name.includes("screen") && track.kind === "video"
  ) as LocalVideoTrack;

  return (
    <>
      {videoTrack ? (
        <VideoTrack track={videoTrack} isLocal />
      ) : (
        <VideoAvatarIcon />
      )}
    </>
  );
}

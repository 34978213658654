import { FC } from "react";

import { CreditCard, CreditCardId } from "types/creditCards.types";
import { useModal } from "hooks/useModal";
import { Modal } from "common/components/Modal";
import { CreditCardCompanyIcon } from "common/components/CreditCardCompanyIcon";
import { formatCardExpirationDate } from "common/utils";
import Button from "common/UIKit/Button";
import { useResponsive } from "hooks/useResponsive";
import Input from "common/UIKit/Input";
import { usePaymentWithExistingCard } from "./usePaymentWithExistingCard";
import { useModalDrag } from "hooks/useModalDrag";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import styles from "./CardCodeModal.module.scss";

import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";

export type BuyHandler = (args: {
  bank_card: CreditCardId;
  card_code: string;
}) => Promise<any>;

interface Props {
  selectedCard: CreditCard;
  onBuy: BuyHandler;
}

export const CardCodeModal: FC<Props> = ({ selectedCard, onBuy }) => {
  const { isOpen, close } = useModal("CARD_CODE");
  const { open } = useModal("CHOOSE_CREDIT_CARD");
  const { isMobile } = useResponsive();
  const { bind, transformStyle } = useModalDrag({
    onClose: close,
  });
  const { register, handleSubmit, isSubmitting, errors } =
    usePaymentWithExistingCard(selectedCard.id, onBuy);

  return (
    <Modal visible={isOpen} onClose={close} width={485} >
      <div className={styles.cardCodeModal} style={transformStyle} {...bind()}>
        <button onClick={close} className={styles.closeButton}>
          <CloseIcon />
        </button>
        <h1>Enter the CVV {!isMobile && "code"}</h1>
        {errors.global && <ErrorMessage>{errors.global}</ErrorMessage>}
        <div className={styles.cardInfo}>
          <div className={styles.top}>
            <CreditCardCompanyIcon company={selectedCard.card_type} />
            <div className={styles.mainInfo}>
              <h2>
                {`${selectedCard.card_type} ending in ${selectedCard.last_four}`}
              </h2>
              <p>
                {`Expires on ${formatCardExpirationDate(
                  selectedCard.expires_at,
                  true
                )}`}
              </p>
            </div>
          </div>
          <Button
            className={styles.changeButton}
            onClick={open}
            text="CHANGE"
            color="link"
            size="lg"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <Input
            className={styles.input}
            label="CVV"
            type="password"
            description="3 or 4 digits"
            maxLength={4}
            {...register("CVV", {
              required: "This field is required.",
              validate: (value) =>
                /^(\d{3,4})$/.test(value) || "Incorrect CVV.",
            })}
            error={errors.CVV?.message}
          />
          <Button
            className="w-100"
            type="submit"
            text="Submit"
            isLoading={isSubmitting}
            size="lg"
          />
        </form>
      </div>
    </Modal>
  );
};

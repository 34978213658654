import Button from "common/UIKit/Button";
import { EditHeader } from "common/components/EditHeader";
import { stepsUi } from "pages/Therapists/TherapistSteps/components";
import ControlledMultiSelect from "common/components/ControlledMultiSelect";
import { ModalityCardCheckbox } from "common/UIKit/ModalityCardCheckbox/ModalityCardCheckbox";
import useFetch from "hooks/useFetch";
import { dictionaryService } from "services/dictionaryService";
import { ListOption } from "constants/options";
import { toOptions } from "common/utils";
import { TherapistFields } from "pages/Therapists/TherapistSteps/util";
import { Modality } from "types/therapist.types";
import { useProfileForm } from "../../useProfileForm";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useResponsive } from "hooks/useResponsive";
import styles from "./EditTreatmentAndSpecialties.module.scss";

type Inputs = {
  therapies: string[];
  modalities: Modality[];
  specialities: string[];
};

const coveredFields: TherapistFields = [
  "modalities",
  "therapies",
  "specialties",
];

export const EditTreatmentAndSpecialties = () => {
  const { isMobile } = useResponsive();
  useDashboardNavigation("Edit Treatment Preferences & Specialties", {
    goBack: "pop_path",
  });
  const therapyTypes = useFetch<ListOption[]>(
    () => dictionaryService.getTherapies(),
    {
      transformer: toOptions,
    }
  );
  const { control, handleSubmit } = useProfileForm<Inputs>(coveredFields);
  const issues = useFetch<ListOption[]>(() => dictionaryService.getIssues(), {
    transformer: toOptions,
  });

  return (
    <section className={styles.treatmentPreferencesEdit}>
      <EditHeader />
      <form
        className={styles.treatmentPreferencesEditForm}
        onSubmit={handleSubmit}
      >
        <stepsUi.Row
          label="Types of therapy"
          subLabel="You can choose a few types of therapy"
          className={styles.treatmentPreferencesEditRow}
        >
          <ControlledMultiSelect
            name="therapies"
            control={control}
            options={therapyTypes?.data}
            placeholder="Select"
            className={styles.therapiesSelect}
          />
        </stepsUi.Row>
        <ModalityCardCheckbox
          control={control}
          className={styles.treatmentPreferencesEditSelect}
        />
        <stepsUi.Row
          label="Specialties"
          subLabel="You can choose a few specialties"
          className={styles.editSpecialtiesRow}
        >
          <ControlledMultiSelect
            name="specialties"
            control={control}
            options={issues.data}
            placeholder="Select"
            className={styles.multiSelect}
            withColumns={!isMobile}
          />
        </stepsUi.Row>
        <Button className={styles.treatmentPreferencesEditButton} type="submit" size="lg">
          Save
        </Button>
      </form>
    </section>
  );
};

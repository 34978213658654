import Button from "common/UIKit/Button/Button";
import { stepsFullPath } from "../stepsPath";
import { useNavigate } from "react-router-dom";
import { esaProfileService, ClientTest } from "services/esaProfileService";
import { useSnapshot } from "valtio";
import { useWindowResize } from "hooks/useWindowResize";
import { useEffect, useState } from "react";
import { testPsychologyListService } from "services/testPsychologyListService";
import { AvailableTest } from "types/availableTests.types";
import { EsaTestTable } from "../components/EsaTestTable";
import { EsaTestTableMobile } from "../components/EsaTestTableMobile";
import { informedConsentFullPath } from "../InformedConsent";

export const esaStep1Path = "/esa_1";
export const esaStep1FullPath = `${stepsFullPath}${esaStep1Path}`;

export function EsaStep1() {
  const navigate = useNavigate();
  const { width } = useWindowResize();
  const [ userProfile, setUserProfile ] = useState(false);
  const [pendingTests, setPendingTests] = useState<ClientTest[]>([]);

    useEffect(() => {
      if( !userProfile) {
        esaProfileService.getProfile(true).then((data) => {
          if (!data?.pendingtests.length) {
            navigate(informedConsentFullPath);
          }
          if (
            data?.pendingtests.length === 1 &&
            data.pendingtests[0].key === "sNtve1"
          ) {
            navigate(informedConsentFullPath);
          }
          if (data?.pendingtests.length) {
            setPendingTests(data?.pendingtests);
          }
          setUserProfile(true);
        });
      }
    });

  const pendingTestKeys = pendingTests.map((pendingTest) => pendingTest.key);
  const { data } = useSnapshot(testPsychologyListService);
  useEffect(() => {
    testPsychologyListService.fetch();
  }, []);
  const tests: AvailableTest[] = JSON.parse(
    JSON.stringify(data)
  ) as AvailableTest[];
  const requiredTests = tests.filter((test) =>
    pendingTestKeys.includes(test.key)
  );

  return (
    <div className="default-typography">
      <div className="text-center mb-35">
        <h1 className="h2">Intake</h1>
        <div className="text-block text-sm">
          <p>
            Before you can download your ESA letter, your therapist needs you to
            complete the intake form.
          </p>
        </div>
      </div>
      {width <= 1300 ? (
        <EsaTestTableMobile rows={requiredTests} />
      ) : (
        <EsaTestTable rows={requiredTests} />
        )}
    </div>
  );
}

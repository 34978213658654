import Button from "common/UIKit/Button";
import { ModalityList } from "common/UIKit/ModalityList/ModalityList";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { useAuth } from "hooks/useAuth";
import { Therapist } from "types/therapist.types";
import { startCase } from "lodash";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { useResponsive } from "hooks/useResponsive";
import { FormatProfileField } from "common/components/FormatProfileField";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./TreatmentPreferences.module.scss";

export const pathTreatmentPreferences = "/treatment-preferences";

export const TreatmentPreferences = () => {
  const { tabName, edit } = useDashboardNavigation(
    "Treatment Preferences & Specialties",
    {
      edit: "append_edit",
      goBack: "pop_path",
    }
  );
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth.user as Therapist | null;

  return (
    <>
      <section className={styles.treatmentPreferences}>
        <h1 className={styles.treatmentPreferencesTitle}>{tabName}</h1>
        <div className={styles.treatmentPreferencesInner}>
          <div className={styles.treatmentPreferencesInfo}>
            <div className={styles.treatmentPreferencesBlock}>
              <p className={styles.treatmentPreferencesBlockTitle}>
                Types of Therapy
              </p>
              <p className={styles.treatmentPreferencesBlockText}>
                <FormatProfileField shouldLimitLength={false}>
                  {user?.therapies
                    .map((therapy) => startCase(therapy))
                    .join(", ")}
                </FormatProfileField>
              </p>
            </div>
            <div className={styles.treatmentPreferencesBlock}>
              <p className={styles.treatmentPreferencesBlockTitle}>Modality</p>
              <FormatProfileField
                shouldLimitLength={false}
                objectToCheck={user?.modalities}
              >
                {user?.modalities && (
                  <ModalityList
                    modalities={user.modalities}
                    className={styles.treatmentPreferencesList}
                    itemClassName={styles.treatmentPreferencesItem}
                  />
                )}
              </FormatProfileField>
            </div>
          </div>
          <Button className={styles.treatmentPreferencesButton} onClick={edit} size="lg">
            <EditIcon /> Edit information
          </Button>
        </div>
      </section>
      <section className={styles.treatmentPreferences}>
        <div
          className={cn(
            styles.treatmentPreferencesInner,
            styles.specialtyInner
          )}
        >
          <div className={styles.treatmentPreferencesInfo}>
            <div
              className={cn(
                styles.treatmentPreferencesBlock,
                styles.specialtyBlock
              )}
            >
              <p className={styles.treatmentPreferencesBlockTitle}>
                Specialties
              </p>
              <p className={styles.treatmentPreferencesBlockText}>
                <FormatProfileField shouldLimitLength={false}>
                  {user?.specialties
                    .map((specialty) => startCase(specialty))
                    .join(", ")}
                </FormatProfileField>
              </p>
            </div>
          </div>
          <Button
            className={cn(
              styles.treatmentPreferencesButton,
              styles.specialtyButton
            )}
            onClick={() =>
              navigate("/therapists/dashboard/profile/specialties/edit")
            }
            size="lg"
          >
            <EditIcon />
            {`Edit ${isMobile ? "" : "information"}`}
          </Button>
        </div>
      </section>
    </>
  );
};

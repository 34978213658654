import { FC } from "react";
import Button from "common/UIKit/Button";
import cn from "classnames";
import {
  DashboardTab,
  useDashboardTabs,
} from "common/components/DashboardLayout/useDashboardTabs";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { Link } from "react-router-dom";
import styles from "./DashboardLayoutDesktopAside.module.scss";

interface Props {
  tabs: DashboardTab[];
}

export const DashboardLayoutDesktopAside: FC<Props> = ({ tabs }) => {
  const { currentTab, changeRoute } = useDashboardTabs(tabs);

  return (
    <aside className={styles.desktopAside}>
      <Link to="/">
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.tabs}>
        {tabs.map(({ icon: Icon, label, value }) => (
          <Button
            className={cn("w-100", "mb-15", "justify-content-start", styles.btn)}
            color={value === currentTab?.value ? "primary" : "link-secondary"}
            onClick={() => changeRoute(value)}
            key={value}
            size="lg"
          >
            <Icon />
            {label}
          </Button>
        ))}
      </div>
    </aside>
  );
};

import { proxy } from "valtio";
import { Id } from "types";
import { RatePurchase } from "types/ratePurchase.types";
import { ListService } from "services/listService";
import { esaApiEndpoint } from "utils/env";
import { InvoiceResult } from "types/invoiceResult";

class RatePurchaseService extends ListService<Id, RatePurchase> {
  isInvoiceFetched = true;

  constructor(props?: any) {
    super({ ...props, prefix: "/v1/rate-cards-purchases" });
  }

  async getInvoice(id: Id) {
    this.isInvoiceFetched = false;
    try {
      const data = (await this.http.get(
        `${this.prefix}/${id}/invoice/`
      )) as RatePurchase;
      this.isInvoiceFetched = true;
      return data;
    } catch (error) {
      this.isInvoiceFetched = true;
      return error;
    }
  }

  async getInvoiceTest(token: string, orderId: string): Promise<String>{
    this.isInvoiceFetched = false;
    let html = "";

    try {
      const response = (await this.http.get(
        `${esaApiEndpoint}/Client/getinvoice?token=${token}&orderid=${orderId}`
      )) as InvoiceResult;
      if (response.success) {
        this.isInvoiceFetched = true;
        html = response.InvoiceHtml ? response.InvoiceHtml : `There was no invoice associated with this order: ${orderId}`;
        return html.toString();
      } else {
        this.isInvoiceFetched = true;
        return `There was an error processing the response: Message ${response.Message}`;
      }
    } catch (error) {
      this.isInvoiceFetched = true;
      return JSON.stringify(error);
    }
  }
}

export const ratePurchaseService = proxy(new RatePurchaseService());

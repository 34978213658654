import { useEffect, useLayoutEffect, useRef } from "react";
import {
  components,
  ControlProps,
  DropdownIndicatorProps,
  InputProps,
  MenuListProps,
  MenuProps,
  OptionProps,
} from "react-select";
import { ListOption } from "constants/options";
import cn from "classnames";
import FilterTag from "../../UIKit/FilterTag";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-icon.svg";
import Button from "../../UIKit/Button";
import Checkbox from "../../UIKit/Checkbox";
import styles from "./ControlledMultiSelect.module.scss";

function CustomControl({ children, className, ...props }: ControlProps) {
  const {
    getValue,
    setValue,
    selectProps: { getOptionLabel, getOptionValue },
  } = props;

  const value = getValue() as ListOption[];

  return (
    <div>
      <components.Control className={cn(className, styles.control)} {...props}>
        {children}
      </components.Control>
      {!!value.length && (
        <div className={styles.valueDisplay}>
          {value.map((selectedOption) => (
            <FilterTag
              key={getOptionValue(selectedOption)}
              onButtonClick={() =>
                setValue(
                  value.filter(
                    (v) => getOptionValue(v) !== getOptionValue(selectedOption)
                  ),
                  "deselect-option"
                )
              }
              buttonIcon={<CloseIcon />}
            >
              {getOptionLabel(selectedOption)}
            </FilterTag>
          ))}
        </div>
      )}
    </div>
  );
}

const SPACING = 48;

function setH(val: string) {
  document.body.style.setProperty("height", val);
}

function getH(): string {
  return document.body.style.getPropertyValue("height");
}

function CustomMenu({ children, ...props }: MenuProps) {
  const menuRef = useRef<HTMLDivElement>(null);
  const initialHeightRef = useRef(getH());

  const {
    options,
    getValue,
    selectProps: { menuIsOpen, onMenuClose },
  } = props;
  const currentValue = getValue();

  useLayoutEffect(() => {
    initialHeightRef.current = getH();

    return () => setH(initialHeightRef.current);
  }, []);

  const deps = JSON.stringify({ options, currentValue });
  useLayoutEffect(() => {
    if (!menuRef.current) {
      return;
    }
    const bodyRect = document.body.getBoundingClientRect();
    const menuRect = menuRef.current.getBoundingClientRect();
    const requiredHeight = menuRect.bottom - bodyRect.top + SPACING;
    if (requiredHeight > bodyRect.height) {
      setH(`${requiredHeight}px`);
    }
  }, [deps]);

  useEffect(() => {
    const outsideClickHandler = (event: MouseEvent) => {
      const target = event.target as HTMLElement | null;

      if (
        menuIsOpen &&
        menuRef.current &&
        target &&
        !menuRef.current.contains(target)
      ) {
        onMenuClose();
      }
    };
    document.addEventListener("mousedown", outsideClickHandler, {
      capture: true,
    });

    return () =>
      document.removeEventListener("mousedown", outsideClickHandler, {
        capture: true,
      });
  }, []);

  return (
    <components.Menu className={styles.menuHolder} {...props}>
      <div ref={menuRef}>{children}</div>
    </components.Menu>
  );
}

function CustomDropdownIndicator(props: DropdownIndicatorProps) {
  return (
    <components.DropdownIndicator
      className={styles.dropdownIndicator}
      {...props}
    />
  );
}

function CustomMenuList({
  clearValue,
  selectProps: { onMenuClose },
  children,
  innerRef,
  innerProps,
}: MenuListProps) {
  return (
    <div className={styles.menuList} {...innerProps}>
      <div className={styles.menuListContent} ref={innerRef}>
        {children}
      </div>
      <div className={styles.menuListActions}>
        <Button onClick={clearValue} color="white" size="lg">
          Clear all
        </Button>
        <Button onClick={onMenuClose} size="lg">
          Done
        </Button>
      </div>
    </div>
  );
}

function CustomInput(props: InputProps) {
  const {
    selectProps: { placeholder },
  } = props;
  return (
    <components.Input
      placeholder={placeholder?.toString()}
      className={styles.customInput}
      {...props}
    />
  );
}

function CustomOption({
  label,
  isSelected,
  data,
  selectOption,
  isFocused,
  innerRef,
  innerProps,
}: OptionProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      onClick={() => inputRef.current?.click()}
      className={cn(
        styles.optionContainer,
        isFocused && styles.optionContainer_focused
      )}
      ref={innerRef}
      {...innerProps}
    >
      <Checkbox
        checked={isSelected}
        ref={inputRef}
        onChange={() => selectOption(data)}
      >
        {label}
      </Checkbox>
    </div>
  );
}

export {
  CustomControl,
  CustomMenuList,
  CustomOption,
  CustomMenu,
  CustomDropdownIndicator,
  CustomInput,
};

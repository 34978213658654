import { useSnapshot } from "valtio";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Layout from "common/components/Layout/Layout";
import { ListingHeader } from "common/components/ListingHeader";
import { MessagePopup } from "common/components/MessagePopup";
import { Preloader } from "common/UIKit/Preloader";
import { useResponsive } from "hooks/useResponsive";
import { PatientsTherapistHeader } from "./components/PatientsTherapistHeader";
import { PatientsTherapistHeaderMobile } from "./components/PatientsTherapistHeaderMobile";
import { PatientsTherapistTabs } from "./components/PatientsTherapistTabs";
import { TherapistLeftSide } from "./components/TherapistLeftSide";
import { TherapistRightSide } from "./components/TherapistRightSide";
import { ShareThisProfilePopup } from "./components/ShareThisProfilePopup/ShareThisProfilePopup";
import { therapistProfileService } from "services/therapistProfileService";
import { AccessController } from "common/components/Layout/AccessController";
import { AxiosResponse } from "axios";
import { ErrorPage } from "pages/ErrorPage";
import { Back } from "common/components/Back";
import { isTherapist } from "services/authService";
import { useAuth } from "hooks/useAuth";
import { communicationService } from "services/communicationService";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { therapistName, therapistPageMetaDescription, therapistPageTitle } from "common/utils";
import { Footer } from "pages/Auth/components/Footer/Footer";
import { ModalAvatar } from "./components/ModalAvatar/ModalAvatar";
import styles from "./PatientsTherapist.module.scss";
import { openMessageModalAfterLogin } from "utils/utils";
import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";
import { useDocumentTherapistTitle } from "hooks/useDocumentTherapistTitle";
import { MetaDescription as useMetaDescription } from "common/components/MetaDescription";

export const patientsTherapistPremiumPath = "/therapist/:id";
export const patientsTherapistPremiumFullPath = [
  patientsTherapistPremiumPath,
].join("");


interface LocationState {
  state?: { prevPage: string } | null;
}

export const PatientsTherapist = () => {
  const [withError, setWithError] = useState(false);
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { currentProfile } = useSnapshot(therapistProfileService);
  const { user, authStatus } = useAuth();
  const { isFetching } = useSnapshot(therapistProfileService);
  const { id } = useParams<{ id: string }>();
  const location = useLocation() as LocationState;
  const cameFromListing = location.state?.prevPage.includes("listing");
  const receiver = localStorage.getItem("receiver");
  const openMessage = () => {
    currentProfile && communicationService.openPopup(currentProfile);
  };
 const specialtyName =  useDictionaryItemNames(
    "ProfessionalSpecialties",
    currentProfile?.professional_specialties
  );
  useDocumentTitle(
    `${therapistPageTitle({
      first_name: currentProfile?.user.first_name,
      last_name: currentProfile?.user.last_name,
      professional_specialties: specialtyName,
      city:currentProfile?.city,
      state:currentProfile?.state,
      zip:currentProfile?.zip_code
    })}`
  ); 
  useMetaDescription(
    `${therapistPageMetaDescription({
      first_name: currentProfile?.user.first_name,
      last_name: currentProfile?.user.last_name,
      professional_specialties: specialtyName,
      city:currentProfile?.city,
      state:currentProfile?.state,
      zip:currentProfile?.zip_code,
      bio:currentProfile?.personal_statement_short_description
    })}`);
    
  useEffect(() => {
    if (receiver) {
      openMessage();
      localStorage.removeItem("receiver");
    }
    // eslint-disable-next-line
  }, [receiver]);

  useEffect(() => {
    if (id) {
      therapistProfileService
        .getById(id)
        .then((res) => setWithError(false))
        .catch((err: AxiosResponse) => {
          if (err.status === 401) {
            setWithError(true);
          }

          err.data.detail as string | undefined;

          err.data.detail.match("Not found") && setWithError(true);
        });
    }
  }, [id, navigate]);

  useEffect(() => {
    openMessageModalAfterLogin(currentProfile);
  }, []);

  if (withError) return <ErrorPage />;

  if (isFetching) return <Preloader />;

  return (
    <AccessController
      patient="allowed"
      therapist="allowed"
      unauthorized="allowed"
    >
      <Layout
        header={<ListingHeader />}
        footer={<Footer />}
        className={styles.wrapper}
      >
        <MessagePopup />
        <ShareThisProfilePopup />
        <div className={styles.root}>
          {(String(id) !== user?.id || cameFromListing) && (
            <Back className={styles.back} />
          )}
          <div className={styles.content}>
            {!isMobile && <TherapistLeftSide />}
            <div className={styles.container}>
              {isMobile ? (
                <PatientsTherapistHeaderMobile
                  currentUserStatus={authStatus}
                  isTherapist={user && isTherapist(user)}
                />
              ) : (
                <PatientsTherapistHeader
                  currentUserStatus={authStatus}
                  isTherapist={user && isTherapist(user)}
                />
              )}
              <PatientsTherapistTabs />
            </div>
            {!isMobile && (
              <TherapistRightSide isTherapist={user && isTherapist(user)} />
            )}
          </div>
        </div>
      </Layout>
      <ModalAvatar />
    </AccessController>
  );
};

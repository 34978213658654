import { FC, useContext } from "react";
import { DashboardTab, useDashboardTabs } from "../useDashboardTabs";
//import AppSelect from "common/UIKit/Select";
import Button from "common/UIKit/Button";
import { DashboardNavigationContext } from "contexts/DashboardNavigationContext";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { ReactComponent as IconLeft } from "assets/images/icons/chevron-left-Regular.svg";
import { ReactComponent as IconFilter } from "assets/images/icons/filter.svg";
import { Header } from "common/components/Header";
import cn from "classnames";
import { useModal } from "hooks/useModal";
import styles from "./DashboardLayoutMobileHeader.module.scss";

interface Props {
  tabs: DashboardTab[];
}

export const DashboardLayoutMobileHeader: FC<Props> = ({ tabs }) => {
  const { tabName, edit, goBack, isNavigationHidden } = useContext(
    DashboardNavigationContext
  );
  //const { currentTab, changeRoute } = useDashboardTabs(tabs);
  const mainTab = tabName === "Overview" || tabName === "My Account";
  const withFilters = tabName === "Tests" || tabName === "Messages";
  const { open: openTests } = useModal("FILTER_TESTS");
  const { open: openMessages } = useModal("FILTER_MESSAGES");
  const openFilters = tabName === "Tests" ? openTests : openMessages;

  //const onTabChange = (tab: unknown) =>
  //  changeRoute((tab as DashboardTab).value);

  return (
    <>
      <Header tabs={tabs} mainTab={mainTab} />
      <div className={styles.header}>
        {goBack && (
          <button className={styles.backButton} onClick={goBack}>
            <IconLeft />
          </button>
        )}
        <h1 className={cn(styles.tabName, mainTab && styles.mainTab)}>
          {tabName}
        </h1>
      </div>
      {!isNavigationHidden && (withFilters || edit) && (
        <div className={styles.row}>
          {/*<AppSelect
            options={tabs}
            value={currentTab}
            onChange={onTabChange}
            isSearchable={false}
            className={styles.select}
            styles={{
              control: (base) => ({
                ...base,
                position: "relative",
                height: "44px",
                padding: "0 2px !important",
                minWidth: "102px",
              }),
            }}
          />*/}
          {withFilters && <IconFilter onClick={openFilters} />}
          {edit && (
            <Button className={styles.editButton} onClick={edit} size="lg">
              <EditIcon /> Edit
            </Button>
          )}
        </div>
      )}
    </>
  );
};

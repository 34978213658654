import { useCallback, useEffect, useState } from "react";
import useLocalTracks from "providers/VideoProvider/useLocalTracks";
import useVideoContext from "./useVideoContext";
import axios from "axios";
import { esaApiEndpoint } from "utils/env";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ParticipantType, ConnectionStatuses, get_participant_status_result, UpdateSessionStatusArgs} from "types/VideoChatParticipant.types";
import { videoChatParticipantStatusService } from "services/videoCallParticipantStatusService";

type RoomStateType = "disconnected" | "connected" | "reconnecting";


export default function useRoomParticipantState(onError: any, sessionId: string, isTherapist: boolean | null = null) {
  const SessionID  = sessionId;
  if (sessionId == null){ 
	
  }
  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      console.log(`ERROR: ${error.message}`, error);
    },
    [onError]
  );
  const { localTracks } = useLocalTracks();
  const { room } = useVideoContext();
  const [state, setState] = useState<RoomStateType>("disconnected");
  const navigate = useNavigate();
  const [params] = useSearchParams();
  let urlRedirect = '/patients/dashboard/my-account/personal-information';

  const RefreshParticipantStatus = () => {
	

	if (isTherapist) {
		const therapistKey = params.get("therapistKey");
		const clientId = params.get("clientId");
		urlRedirect = `/therapists/video?therapistKey=${therapistKey}&sessionId=${sessionId}&clientId=${clientId}`;
	}
	
	
	console.log("callingRefreshParticipantStatus");
    if (SessionID) {
      console.log('calling get room status');
	  videoChatParticipantStatusService.getSessionStatus(SessionID)
        .then((response:get_participant_status_result) => 
		{ 
        
            const providerStatus = response.ProviderStatus;	
            const clientStatus = response.ClientStatus; 
			if (!isTherapist && providerStatus === ConnectionStatuses.Ended) 
			{
				let args:UpdateSessionStatusArgs = { SessionID: sessionId!, Participant: ParticipantType.Patient, Status: ConnectionStatuses.Ended };
				videoChatParticipantStatusService.setSessionStatus(args);
				navigate(urlRedirect)
				room!.disconnect()
			}
            
        })
      .catch((error) => console.log(error));
    }
	else{
		console.log("SessionID BAD");		
	}
  }
   
  if (state)
  	RefreshParticipantStatus();

  useEffect(() => {
    if (room) {
      const setRoomState = () => setState(room.state as RoomStateType);
      setRoomState();
      room
        .on("participantConnected", RefreshParticipantStatus)
        .on("participantDisconnected", RefreshParticipantStatus);
      return () => {
        room
          .off("participantConnected", RefreshParticipantStatus)
          .off("participantDisconnected", RefreshParticipantStatus);
      };
    }
  }, [room]);

  return state;
}
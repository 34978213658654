import { ratePurchaseService } from "services/ratePurchaseService";
import { useState } from "react";

export const InvoicePath = "/invoice";

export const Invoice = () => {

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token")!;
  const orderId = queryParams.get("orderId")!;

  const [invoiceHTML, setInvoiceHTML] = useState("");
  if (!invoiceHTML) {
    const fetchInvoice = ratePurchaseService.getInvoiceTest(token, orderId);
    fetchInvoice.then((value) => {
      setInvoiceHTML(value.toString())
    }).catch((error) => {
      console.log(error);
    });
  }
  return <iframe srcDoc={invoiceHTML} style={{ position:"fixed", top:"0", left:"0", bottom:"0", right:"0", width:"100%", height:"100%", border:"none", margin:"0", padding:"0", overflow:"hidden", zIndex:"999999"}}>
      Your browser doesn't support iframes
  </iframe>
};